import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "calculator" }
const _hoisted_3 = { class: "btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["display", { 'small-font': _ctx.screen.length > 11 }]),
        ref: "screenRef"
      }, _toDisplayString(_ctx.screen), 3),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "btn numbers",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.push && _ctx.push(...args)))
        }, "1"),
        _createElementVNode("div", {
          class: "btn numbers",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.push && _ctx.push(...args)))
        }, "2"),
        _createElementVNode("div", {
          class: "btn numbers",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.push && _ctx.push(...args)))
        }, "3"),
        _createElementVNode("div", {
          class: "btn numbers",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.push && _ctx.push(...args)))
        }, "4"),
        _createElementVNode("div", {
          class: "btn numbers",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.push && _ctx.push(...args)))
        }, "5"),
        _createElementVNode("div", {
          class: "btn numbers",
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.push && _ctx.push(...args)))
        }, "6"),
        _createElementVNode("div", {
          class: "btn numbers",
          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.push && _ctx.push(...args)))
        }, "7"),
        _createElementVNode("div", {
          class: "btn numbers",
          onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.push && _ctx.push(...args)))
        }, "8"),
        _createElementVNode("div", {
          class: "btn numbers",
          onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.push && _ctx.push(...args)))
        }, "9"),
        _createElementVNode("div", {
          class: "btn null numbers",
          style: {"grid-area":"null"},
          onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.push && _ctx.push(...args)))
        }, "0"),
        _createElementVNode("div", {
          class: "btn equal",
          style: {"grid-area":"equal"},
          onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.push && _ctx.push(...args)))
        }, "="),
        _createElementVNode("div", {
          class: "btn minus",
          style: {"grid-area":"minus"},
          onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.push && _ctx.push(...args)))
        }, "-"),
        _createElementVNode("div", {
          class: "btn plus",
          style: {"grid-area":"plus"},
          onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.push && _ctx.push(...args)))
        }, "+"),
        _createElementVNode("div", {
          class: "btn mult",
          style: {"grid-area":"mult"},
          onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.push && _ctx.push(...args)))
        }, "*"),
        _createElementVNode("div", {
          class: "btn div",
          style: {"grid-area":"div"},
          onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.push && _ctx.push(...args)))
        }, "/"),
        _createElementVNode("div", {
          class: "btn dot",
          style: {"grid-area":"dot"},
          onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.push && _ctx.push(...args)))
        }, "."),
        _createElementVNode("div", {
          class: "btn clean",
          style: {"grid-area":"clean"},
          onClick: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.push && _ctx.push(...args)))
        }, "AC")
      ])
    ])
  ]))
}