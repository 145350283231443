import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "extra" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "prj calculator" }
const _hoisted_4 = { class: "prj todo" }
const _hoisted_5 = { class: "prj notes" }
const _hoisted_6 = { class: "prj weather" }
const _hoisted_7 = { class: "prj exchange" }
const _hoisted_8 = { class: "prj cryptocur" }
const _hoisted_9 = { class: "prj tasks" }
const _hoisted_10 = { class: "prj graphics" }
const _hoisted_11 = { class: "prj game" }
const _hoisted_12 = { class: "prj ai" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t('extra.h2')), 1),
    _cache[0] || (_cache[0] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          class: "block",
          to: "/extra/calculator"
        }, {
          default: _withCtx(() => [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('extra.calculator.title')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_link, {
          class: "block",
          to: "/extra/todo"
        }, {
          default: _withCtx(() => [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('extra.todo.title')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_link, {
          class: "block",
          to: "/extra/notes"
        }, {
          default: _withCtx(() => [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('extra.notes.title')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_router_link, {
          class: "block",
          to: "/extra/weather"
        }, {
          default: _withCtx(() => [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('extra.weather.title1')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_router_link, {
          class: "block",
          to: "/extra/rates"
        }, {
          default: _withCtx(() => [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('extra.exchange.title')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_router_link, {
          class: "block",
          to: "/extra/cryptocur"
        }, {
          default: _withCtx(() => [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('extra.cryptocur.title')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_router_link, {
          class: "block",
          to: "/extra/tasks"
        }, {
          default: _withCtx(() => [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('extra.tasks.title')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_router_link, {
          class: "block",
          to: "/extra/graphics"
        }, {
          default: _withCtx(() => [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('extra.graphics.title')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_router_link, {
          class: "block",
          to: "/extra/games"
        }, {
          default: _withCtx(() => [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('extra.game.title')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_router_link, {
          class: "block",
          to: "/extra/ai"
        }, {
          default: _withCtx(() => [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('extra.ai.title')), 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}