import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "tasks" }
const _hoisted_2 = {
  key: 0,
  class: "table"
}
const _hoisted_3 = { class: "nomer" }
const _hoisted_4 = { class: "name" }
const _hoisted_5 = { style: {"color":"darkgoldenrod"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createVNode(_component_router_link, {
        class: "back",
        to: "/extra",
        title: "Back to Extra page"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("i", { class: "fa fa-arrow-circle-left" }, null, -1)
        ])),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('extra.h1m.tasks.title')) + " ", 1),
      _createElementVNode("i", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeView && _ctx.changeView(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(['fa', _ctx.tableView ? 'fa-list' : 'fa-th'])
        }, null, 2)
      ])
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("line", null, null, -1)),
    (_ctx.tableView)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("table", null, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _cache[2] || (_cache[2] = _createElementVNode("th", null, "№", -1)),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('extra.h1m.tasks.name')), 1)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tasksStore.state.tasksStore, (task) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: task.id
                }, [
                  _createElementVNode("td", _hoisted_3, _toDisplayString(task.id), 1),
                  _createElementVNode("td", _hoisted_4, [
                    _createVNode(_component_router_link, {
                      to: task.url,
                      title: "Task..."
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(this.$i18n.locale === "ua" ? task.title_ua : this.$i18n.locale === "es" ? task.title_es : task.title_en), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ])
                ]))
              }), 128))
            ])
          ])
        ]))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.tasksStore.state.tasksStore, (task) => {
          return (_openBlock(), _createElementBlock("div", {
            key: task.id,
            class: "diploma"
          }, [
            _createVNode(_component_router_link, {
              to: task.url,
              class: "block",
              title: "Task..."
            }, {
              default: _withCtx(() => [
                _createElementVNode("h3", null, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(task.id) + ".", 1),
                  _createTextVNode(" " + _toDisplayString(this.$i18n.locale === "ua" ? task.title_ua : this.$i18n.locale === "es" ? task.title_es : task.title_en), 1)
                ])
              ]),
              _: 2
            }, 1032, ["to"])
          ]))
        }), 128))
  ]))
}