const state = {
  htmlStore:
    [
      {
        id: 1,
        title_en: "HTML5&CSS3 Starter 2018",
        title_ua: "HTML5&CSS3 Стартовий 2018",
        title_es: "HTML5&CSS3 Inicio 2018",
        regnumber: "TP78526668",
        grade: "1000 / 1000",
        examdate: "01.02.2021",
        image: "https://testprovider.com/ru/search-certificate/tp78526668",
      },
      {
        id: 2,
        title_en: "HTML5&CSS3 Starter 2022",
        title_ua: "HTML5&CSS3 Стартовий 2022",
        title_es: "HTML5&CSS3 Inicio 2022",
        regnumber: "TP37225065",
        grade: "1000 / 1000",
        examdate: "09.08.2022",
        image: "https://testprovider.com/ru/search-certificate/tp37225065",
      },
      {
        id: 3,
        title_en: "HTML5&CSS3 Essential",
        title_ua: "HTML5&CSS3 Базовий",
        title_es: "HTML5&CSS3 Básico",
        regnumber: "TP24244286",
        grade: "1000 / 1000",
        examdate: "16.06.2021",
        image: "https://testprovider.com/ru/search-certificate/tp24244286",
      },
      {
        id: 4,
        title_en: "HTML5&CSS3 Advanced",
        title_ua: "HTML5&CSS3 Поглиблений",
        title_es: "HTML5&CSS3 Avanzado",
        regnumber: "TP92660322",
        grade: "1000 / 1000",
        examdate: "04.06.2021",
        image: "https://testprovider.com/ru/search-certificate/tp92660322",
      },
      {
        id: 5,
        title_en: "PUG template designer",
        title_ua: "Шаблонізатор PUG",
        title_es: "Motor de plantillas PUG",
        regnumber: "TP46286417",
        grade: "1000 / 1000",
        examdate: "26.10.2023",
        image: "https://testprovider.com/ru/search-certificate/tp46286417",
      },
      {
        id: 6,
        title_en: "CSS Flexbox",
        title_ua: "CSS Flexbox",
        title_es: "CSS Flexbox",
        regnumber: "TP01289491",
        grade: "1000 / 1000",
        examdate: "09.05.2023",
        image: "https://testprovider.com/ru/search-certificate/tp01289491",
      },
      {
        id: 7,
        title_en: "Layout of pages using Grid",
        title_ua: "Верстка сторінок із використанням Grid",
        title_es: "Maquetación de páginas usando Grid",
        regnumber: "TP62170279",
        grade: "1000 / 1000",
        examdate: "31.05.2021",
        image: "https://testprovider.com/ru/search-certificate/tp62170279",
      },
      {
        id: 8,
        title_en: "Bootstrap 4",
        title_ua: "Bootstrap 4",
        title_es: "Bootstrap 4",
        regnumber: "TP33638993",
        grade: "1000 / 1000",
        examdate: "27.05.2021",
        image: "https://testprovider.com/ru/search-certificate/tp33638993",
      },
      {
        id: 9,
        title_en: "Bootstrap 5",
        title_ua: "Bootstrap 5",
        title_es: "Bootstrap 5",
        regnumber: "TP14847976",
        grade: "1000 / 1000",
        examdate: "01.08.2024",
        image: "https://testprovider.com/ru/search-certificate/tp14847976",
      },
      {
        id: 10,
        title_en: "Practical Landing Course",
        title_ua: "Практичний курс з верстки лендінгу",
        title_es: "Curso práctico de maquetación de landing page",
        regnumber: "TP73961983",
        grade: "1000 / 1000",
        examdate: "09.06.2021",
        image: "https://testprovider.com/ru/search-certificate/tp73961983",
      },
      {
        id: 11,
        title_en: "WordPress Starter",
        title_ua: "WordPress Стартовий",
        title_es: "WordPress Inicio",
        regnumber: "TP68763365",
        grade: "1000 / 1000",
        examdate: "10.07.2021",
        image: "https://testprovider.com/ru/search-certificate/tp68763365",
      },
      {
        id: 12,
        title_en: "WordPress Essential",
        title_ua: "WordPress Базовий",
        title_es: "WordPress Básico",
        regnumber: "TP85465557",
        grade: "1000 / 1000",
        examdate: "13.07.2021",
        image: "https://testprovider.com/ru/search-certificate/tp85465557",
      },
    ]
};
const getters = {};
const mutations = {};
const actions = {};
export default {
  state,
  getters,
  mutations,
  actions
};