import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, vModelSelect as _vModelSelect, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pixabay" }
const _hoisted_2 = {
  key: 0,
  style: {"margin":"0"}
}
const _hoisted_3 = {
  key: 1,
  style: {"margin":"0"}
}
const _hoisted_4 = {
  key: 2,
  style: {"color":"darkred","margin":"0.5rem"}
}
const _hoisted_5 = { class: "generator" }
const _hoisted_6 = { class: "input-group" }
const _hoisted_7 = { for: "theme" }
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = { class: "input-group" }
const _hoisted_10 = { for: "count" }
const _hoisted_11 = { class: "input-group" }
const _hoisted_12 = { for: "type" }
const _hoisted_13 = { value: "photo" }
const _hoisted_14 = { value: "video" }
const _hoisted_15 = { class: "btns" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "media-container" }
const _hoisted_19 = {
  key: 0,
  class: "media-row photo"
}
const _hoisted_20 = ["href"]
const _hoisted_21 = ["src", "alt"]
const _hoisted_22 = {
  key: 1,
  class: "media-row video"
}
const _hoisted_23 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createVNode(_component_router_link, {
        class: "back",
        to: "/extra/ai",
        title: "Back to AI page"
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [
          _createElementVNode("i", { class: "fa fa-arrow-circle-left" }, null, -1)
        ])),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('extra.ai.pixabay.title')), 1)
    ]),
    _cache[11] || (_cache[11] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("h2", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMore = !_ctx.showMore)),
      class: "more"
    }, [
      _createTextVNode(_toDisplayString(_ctx.$t('extra.ai.pixabay.info01')), 1),
      _cache[10] || (_cache[10] = _createElementVNode("i", {
        style: {"color":"rebeccapurple","margin-left":"0.5rem"},
        class: "fas fa-hand-pointer"
      }, null, -1))
    ]),
    (_ctx.showMore)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('extra.ai.pixabay.info02')), 1))
      : _createCommentVNode("", true),
    (_ctx.showMore)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t('extra.ai.pixabay.info03')), 1))
      : _createCommentVNode("", true),
    (_ctx.showMore)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_4, _toDisplayString(_ctx.$t('extra.ai.pixabay.info04')), 1))
      : _createCommentVNode("", true),
    _cache[12] || (_cache[12] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('extra.ai.pixabay.theme1')), 1),
        _withDirectives(_createElementVNode("input", {
          id: "theme",
          type: "text",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.theme) = $event)),
          placeholder: _ctx.$t('extra.ai.pixabay.theme2'),
          onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.generateImage()), ["enter"]))
        }, null, 40, _hoisted_8), [
          [_vModelText, _ctx.theme]
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('extra.ai.pixabay.count')), 1),
        _withDirectives(_createElementVNode("input", {
          id: "count",
          style: {"margin-right":"0.5rem"},
          type: "number",
          min: "1",
          max: "30",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.imageCount) = $event)),
          onKeyup: _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.generateImage && _ctx.generateImage(...args)), ["enter"]))
        }, null, 544), [
          [
            _vModelText,
            _ctx.imageCount,
            void 0,
            { number: true }
          ]
        ]),
        _createElementVNode("button", {
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.setMaxMediaCount && _ctx.setMaxMediaCount(...args)))
        }, _toDisplayString(_ctx.$t('extra.ai.pixabay.max')), 1)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('extra.ai.pixabay.type')), 1),
        _withDirectives(_createElementVNode("select", {
          id: "type",
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedType) = $event))
        }, [
          _createElementVNode("option", _hoisted_13, _toDisplayString(_ctx.$t('extra.ai.pixabay.photo')), 1),
          _createElementVNode("option", _hoisted_14, _toDisplayString(_ctx.$t('extra.ai.pixabay.video')), 1)
        ], 512), [
          [_vModelSelect, _ctx.selectedType]
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("button", {
          class: "gen",
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.selectedType === 'photo' ? _ctx.generateImage() : _ctx.generateVideo()))
        }, _toDisplayString(_ctx.$t('extra.ai.pixabay.create')), 1),
        _createElementVNode("button", {
          class: "clear",
          onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.clearMedia && _ctx.clearMedia(...args)))
        }, _toDisplayString(_ctx.$t('extra.ai.pixabay.clear')), 1)
      ]),
      (_ctx.selectedType === 'photo' && _ctx.images.length > 0)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_16, _toDisplayString(_ctx.$t('extra.ai.pixabay.gen1')), 1))
        : _createCommentVNode("", true),
      (_ctx.selectedType === 'video' && _ctx.videos.length > 0)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_17, _toDisplayString(_ctx.$t('extra.ai.pixabay.gen2')), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_18, [
        (_ctx.selectedType === 'photo')
          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.id,
                  class: "media-wrapper"
                }, [
                  _createElementVNode("a", {
                    href: item.url,
                    target: "_blank"
                  }, [
                    _createElementVNode("img", {
                      src: item.url,
                      alt: item.alt
                    }, null, 8, _hoisted_21)
                  ], 8, _hoisted_20)
                ]))
              }), 128))
            ]))
          : (_ctx.selectedType === 'video')
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.videos, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.id,
                    class: "media-wrapper"
                  }, [
                    _createElementVNode("video", {
                      src: item.url,
                      controls: ""
                    }, null, 8, _hoisted_23)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
      ])
    ])
  ]))
}