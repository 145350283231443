import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "projects" }
const _hoisted_2 = {
  key: 0,
  class: "table"
}
const _hoisted_3 = { style: {"text-align":"left"} }
const _hoisted_4 = { class: "nomer" }
const _hoisted_5 = { style: {"color":"black"} }
const _hoisted_6 = { class: "name" }
const _hoisted_7 = { class: "work" }
const _hoisted_8 = { class: "type" }
const _hoisted_9 = { class: "link" }
const _hoisted_10 = {
  key: 0,
  class: "link2"
}
const _hoisted_11 = ["href"]
const _hoisted_12 = ["href"]
const _hoisted_13 = ["href"]
const _hoisted_14 = {
  key: 1,
  class: "link2"
}
const _hoisted_15 = ["href"]
const _hoisted_16 = ["href"]
const _hoisted_17 = { key: 2 }
const _hoisted_18 = ["href"]
const _hoisted_19 = { class: "description" }
const _hoisted_20 = { class: "grade" }
const _hoisted_21 = {
  key: 1,
  class: "container"
}
const _hoisted_22 = { class: "block" }
const _hoisted_23 = { style: {"color":"black"} }
const _hoisted_24 = { class: "working-date" }
const _hoisted_25 = {
  key: 0,
  class: "tire"
}
const _hoisted_26 = { class: "description" }
const _hoisted_27 = { class: "link" }
const _hoisted_28 = {
  key: 0,
  class: "link2"
}
const _hoisted_29 = ["href"]
const _hoisted_30 = ["href"]
const _hoisted_31 = ["href"]
const _hoisted_32 = {
  key: 1,
  class: "link2"
}
const _hoisted_33 = ["href"]
const _hoisted_34 = ["href"]
const _hoisted_35 = { key: 2 }
const _hoisted_36 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createTextVNode(_toDisplayString(_ctx.$t('projects.title')) + " ", 1),
      _createElementVNode("i", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeView && _ctx.changeView(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(['fa', _ctx.tableView ? 'fa-list' : 'fa-th'])
        }, null, 2)
      ]),
      _cache[2] || (_cache[2] = _createTextVNode()),
      _createElementVNode("i", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleOrder && _ctx.toggleOrder(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(['fas', _ctx.reverseOrder ? 'fa-arrow-alt-circle-up' : 'fa-arrow-alt-circle-down'])
        }, null, 2)
      ])
    ]),
    _cache[5] || (_cache[5] = _createElementVNode("line", null, null, -1)),
    (_ctx.tableView)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("table", null, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _cache[3] || (_cache[3] = _createElementVNode("th", null, "№", -1)),
                _createElementVNode("th", _hoisted_3, _toDisplayString(_ctx.$t('projects.name')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('projects.description')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('projects.technologies')), 1)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedProjects, (prj) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: prj.id
                }, [
                  _createElementVNode("td", _hoisted_4, [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(prj.id), 1)
                  ]),
                  _createElementVNode("td", _hoisted_6, [
                    _createElementVNode("div", null, _toDisplayString(this.$i18n.locale === "uk" ? prj.title_ua : this.$i18n.locale === "es" ? prj.title_es : prj.title_en), 1),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("span", null, _toDisplayString(this.$i18n.locale === "uk" ? prj.start_date_ua : this.$i18n.locale === "es" ? prj.start_date_es : prj.start_date_en), 1),
                      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "tire" }, "–", -1)),
                      _createElementVNode("span", null, _toDisplayString(this.$i18n.locale === "uk" ? prj.end_date_ua : this.$i18n.locale === "es" ? prj.end_date_es : prj.end_date_en), 1)
                    ]),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(this.$i18n.locale === "uk" ? prj.type_ua : this.$i18n.locale === "es" ? prj.type_es : prj.type_en), 1),
                    _createElementVNode("div", _hoisted_9, [
                      (prj.demolink && prj.youtubelink)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createElementVNode("a", {
                              href: prj.demolink,
                              class: "demolink",
                              title: "Link to the project",
                              target: "_blank"
                            }, [
                              _createElementVNode("b", null, _toDisplayString(_ctx.$t('projects.link')), 1)
                            ], 8, _hoisted_11),
                            _createElementVNode("a", {
                              href: prj.sourcelink,
                              class: "github",
                              title: "Project at GitHub...",
                              target: "_blank"
                            }, [
                              _createElementVNode("b", null, _toDisplayString(_ctx.$t('projects.github')), 1)
                            ], 8, _hoisted_12),
                            _createElementVNode("a", {
                              href: prj.youtubelink,
                              class: "youtube",
                              title: "Project at YouTube...",
                              target: "_blank"
                            }, [
                              _createElementVNode("b", null, _toDisplayString(_ctx.$t('projects.youtube')), 1)
                            ], 8, _hoisted_13)
                          ]))
                        : (prj.demolink)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                              _createElementVNode("a", {
                                href: prj.demolink,
                                class: "demolink",
                                title: "Link to the project",
                                target: "_blank"
                              }, [
                                _createElementVNode("b", null, _toDisplayString(_ctx.$t('projects.link')), 1)
                              ], 8, _hoisted_15),
                              _createElementVNode("a", {
                                href: prj.sourcelink,
                                class: "github",
                                title: "Project at GitHub...",
                                target: "_blank"
                              }, [
                                _createElementVNode("b", null, _toDisplayString(_ctx.$t('projects.github')), 1)
                              ], 8, _hoisted_16)
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                              _createElementVNode("a", {
                                href: prj.sourcelink,
                                class: "github",
                                title: "Project at GitHub...",
                                target: "_blank"
                              }, [
                                _createElementVNode("b", null, _toDisplayString(_ctx.$t('projects.github')), 1)
                              ], 8, _hoisted_18)
                            ]))
                    ])
                  ]),
                  _createElementVNode("td", _hoisted_19, _toDisplayString(this.$i18n.locale === "uk" ? prj.description_ua : this.$i18n.locale === "es" ? prj.description_es : prj.description_en), 1),
                  _createElementVNode("td", _hoisted_20, _toDisplayString(prj.techno), 1)
                ]))
              }), 128))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_21, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedProjects, (prj) => {
            return (_openBlock(), _createElementBlock("div", {
              key: prj.id,
              class: "prj"
            }, [
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("h3", null, [
                  _createElementVNode("span", _hoisted_23, _toDisplayString(prj.id) + ". ", 1),
                  _createTextVNode(_toDisplayString(this.$i18n.locale === "uk" ? prj.title_ua : this.$i18n.locale === "es" ? prj.title_es : prj.title_en), 1)
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("span", null, _toDisplayString(this.$i18n.locale === "uk" ? prj.start_date_ua : this.$i18n.locale === "es" ? prj.start_date_es : prj.start_date_en), 1),
                  (prj.end_date_en)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_25, "–"))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", null, _toDisplayString(this.$i18n.locale === "uk" ? prj.end_date_ua : this.$i18n.locale === "es" ? prj.end_date_es : prj.end_date_en), 1)
                ]),
                _createElementVNode("div", _hoisted_26, _toDisplayString(this.$i18n.locale === "uk" ? prj.description_ua : this.$i18n.locale === "es" ? prj.description_es : prj.description_en), 1),
                _createElementVNode("div", null, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.$t('projects.type')) + ": ", 1),
                  _createTextVNode(_toDisplayString(this.$i18n.locale === "uk" ? prj.type_ua : this.$i18n.locale === "es" ? prj.type_es : prj.type_en), 1)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.$t('projects.technologies')) + ": ", 1),
                  _createTextVNode(_toDisplayString(prj.techno), 1)
                ]),
                _createElementVNode("div", _hoisted_27, [
                  (prj.demolink && prj.youtubelink)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                        _createElementVNode("a", {
                          href: prj.demolink,
                          class: "demolink",
                          title: "Link to the project",
                          target: "_blank"
                        }, [
                          _createElementVNode("b", null, _toDisplayString(_ctx.$t('projects.link')), 1)
                        ], 8, _hoisted_29),
                        _createElementVNode("a", {
                          href: prj.sourcelink,
                          class: "github",
                          title: "Project at GitHub...",
                          target: "_blank"
                        }, [
                          _createElementVNode("b", null, _toDisplayString(_ctx.$t('projects.github')), 1)
                        ], 8, _hoisted_30),
                        _createElementVNode("a", {
                          href: prj.youtubelink,
                          class: "youtube",
                          title: "Project at YouTube...",
                          target: "_blank"
                        }, [
                          _createElementVNode("b", null, _toDisplayString(_ctx.$t('projects.youtube')), 1)
                        ], 8, _hoisted_31)
                      ]))
                    : (prj.demolink)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                          _createElementVNode("a", {
                            href: prj.demolink,
                            class: "demolink",
                            title: "Link to the project",
                            target: "_blank"
                          }, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.$t('projects.link')), 1)
                          ], 8, _hoisted_33),
                          _createElementVNode("a", {
                            href: prj.sourcelink,
                            class: "github",
                            title: "Project at GitHub...",
                            target: "_blank"
                          }, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.$t('projects.github')), 1)
                          ], 8, _hoisted_34)
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_35, [
                          _createElementVNode("a", {
                            href: prj.sourcelink,
                            class: "github",
                            title: "Project at GitHub...",
                            target: "_blank"
                          }, [
                            _createElementVNode("b", null, _toDisplayString(_ctx.$t('projects.github')), 1)
                          ], 8, _hoisted_36)
                        ]))
                ])
              ])
            ]))
          }), 128))
        ]))
  ]))
}