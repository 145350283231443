import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "search" }
const _hoisted_2 = { class: "search-bar" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = {
  key: 0,
  class: "inner"
}
const _hoisted_5 = {
  key: 0,
  class: "certificate"
}
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 0,
  class: "certificate"
}
const _hoisted_8 = ["href"]
const _hoisted_9 = {
  key: 0,
  class: "diploma"
}
const _hoisted_10 = ["href"]
const _hoisted_11 = {
  key: 0,
  class: "diploma"
}
const _hoisted_12 = ["href"]
const _hoisted_13 = {
  key: 0,
  class: "diploma"
}
const _hoisted_14 = ["href"]
const _hoisted_15 = {
  key: 0,
  class: "certificate"
}
const _hoisted_16 = ["href"]
const _hoisted_17 = {
  key: 0,
  class: "hasResults"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        placeholder: _ctx.$t('cert.search'),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event))
      }, null, 8, _hoisted_3), [
        [_vModelText, _ctx.searchValue]
      ]),
      _createElementVNode("i", {
        title: "Clear",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clearSearch && _ctx.clearSearch(...args))),
        class: "fas fa-trash-alt"
      })
    ]),
    (_ctx.searchValue.length !== 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.combinedCertificates, (sert) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: sert.id
            }, [
              (_ctx.checkDoc(sert))
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("a", {
                      class: "block",
                      href: sert.image,
                      title: "Certificate...",
                      target: "_blank"
                    }, [
                      _createElementVNode("h3", null, _toDisplayString(sert.id) + ". " + _toDisplayString(_ctx.getTitle(sert)), 1),
                      _createElementVNode("div", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('cert.number')) + ": ", 1),
                        _createElementVNode("strong", null, _toDisplayString(sert.regnumber), 1)
                      ]),
                      _createElementVNode("div", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                        _createElementVNode("strong", null, _toDisplayString(sert.grade), 1)
                      ]),
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('cert.date')) + ": " + _toDisplayString(sert.examdate), 1)
                    ], 8, _hoisted_6)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gradeCombinedCertificates, (sert) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: sert.id
            }, [
              (_ctx.checkDoc(sert))
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("a", {
                      class: "block",
                      href: sert.image,
                      title: "Certificate...",
                      target: "_blank"
                    }, [
                      _createElementVNode("h3", null, _toDisplayString(sert.id) + ". " + _toDisplayString(_ctx.getTitle(sert)), 1),
                      _createElementVNode("div", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('cert.number')) + ": ", 1),
                        _createElementVNode("strong", null, _toDisplayString(sert.regnumber), 1)
                      ]),
                      _createElementVNode("div", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                        _createElementVNode("strong", null, _toDisplayString(_ctx.getGrade(sert)), 1)
                      ]),
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('cert.date')) + ": " + _toDisplayString(sert.examdate), 1)
                    ], 8, _hoisted_8)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.knuteStore.state.knuteStore, (sert) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: sert.id
            }, [
              (_ctx.checkDoc(sert))
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("a", {
                      class: "block",
                      href: sert.image,
                      title: "Diploma...",
                      target: "_blank"
                    }, [
                      _createElementVNode("h3", null, _toDisplayString(sert.id) + ". " + _toDisplayString(_ctx.getTitle(sert)), 1),
                      _createElementVNode("div", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('cert.number')) + ": ", 1),
                        _createElementVNode("strong", null, _toDisplayString(sert.regnumber), 1)
                      ]),
                      _createElementVNode("div", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                        _createElementVNode("strong", null, _toDisplayString(_ctx.getGrade(sert)), 1)
                      ]),
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('cert.date')) + ": " + _toDisplayString(sert.examdate), 1)
                    ], 8, _hoisted_10)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itvdnStore.state.itvdnStore, (sert) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: sert.id
            }, [
              (_ctx.checkDoc(sert))
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("a", {
                      class: "block",
                      href: sert.image,
                      title: "Diploma...",
                      target: "_blank"
                    }, [
                      _createElementVNode("h3", null, _toDisplayString(sert.id) + ". " + _toDisplayString(_ctx.getTitle(sert)), 1),
                      _createElementVNode("div", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('cert.number')) + ": ", 1),
                        _createElementVNode("strong", null, _toDisplayString(sert.regnumber), 1)
                      ]),
                      _createElementVNode("div", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                        _createElementVNode("strong", null, _toDisplayString(sert.grade), 1)
                      ]),
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('cert.date')) + ": " + _toDisplayString(sert.examdate), 1)
                    ], 8, _hoisted_12)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.progStore.state.progStore, (sert) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: sert.id
            }, [
              (_ctx.checkDocProg(sert))
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("a", {
                      class: "block",
                      href: sert.image,
                      title: "Certificate...",
                      target: "_blank"
                    }, [
                      _createElementVNode("h3", null, _toDisplayString(sert.id) + ". " + _toDisplayString(_ctx.getTitle(sert)), 1),
                      _createElementVNode("div", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('cert.number')) + ": ", 1),
                        _createElementVNode("strong", null, _toDisplayString(_ctx.getRegNumber(sert)), 1)
                      ]),
                      _createElementVNode("div", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                        _createElementVNode("strong", null, _toDisplayString(_ctx.getGrade(sert)), 1)
                      ]),
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('cert.date')) + ": " + _toDisplayString(sert.examdate), 1)
                    ], 8, _hoisted_14)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.englishStore.state.englishStore, (sert) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: sert.id
            }, [
              (_ctx.checkDoc(sert))
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("a", {
                      class: "block",
                      href: sert.image,
                      title: "Certificate...",
                      target: "_blank"
                    }, [
                      _createElementVNode("h3", null, _toDisplayString(sert.id) + ". " + _toDisplayString(_ctx.getTitle(sert)), 1),
                      _createElementVNode("div", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('cert.level')) + ": ", 1),
                        _createElementVNode("strong", null, _toDisplayString(sert.regnumber), 1)
                      ]),
                      _createElementVNode("div", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                        _createElementVNode("strong", null, _toDisplayString(_ctx.getGrade(sert)), 1)
                      ]),
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('cert.date')) + ": " + _toDisplayString(sert.examdate), 1)
                    ], 8, _hoisted_16)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128)),
          (_ctx.searchValue.length !== 0 && !_ctx.hasResults)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.$t('cert.hasNoResults')) + "️ ", 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}