import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "experience" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "work" }
const _hoisted_5 = { style: {"color":"black"} }
const _hoisted_6 = { style: {"color":"black"} }
const _hoisted_7 = { style: {"font-weight":"bold","color":"rebeccapurple"} }
const _hoisted_8 = { style: {"font-weight":"bold","color":"rebeccapurple"} }
const _hoisted_9 = { style: {"font-weight":"bold","color":"rebeccapurple"} }
const _hoisted_10 = { class: "skills" }
const _hoisted_11 = { style: {"color":"black","font-style":"normal","text-decoration":"underline"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_codersrank_summary = _resolveComponent("codersrank-summary")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createTextVNode(_toDisplayString(_ctx.$t('experience.title')) + " ", 1),
      _createElementVNode("i", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeSummary && _ctx.changeSummary(...args))),
        class: "dandruff"
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(['fa', _ctx.isCodersrankSummaryVisible ? 'fa-check-circle' : 'fa-hat-wizard'])
        }, null, 2)
      ])
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isCodersrankSummaryVisible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_codersrank_summary, {
              username: "zorger27",
              branding: "false",
              layout: "horizontal"
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.experienceStore.state.experienceStore, (exp) => {
          return (_openBlock(), _createElementBlock("div", {
            key: exp.id
          }, [
            _createElementVNode("h3", null, _toDisplayString(exp.data_start) + " - " + _toDisplayString(this.$i18n.locale === "uk" ? exp.data_finish_ua : this.$i18n.locale === "es" ? exp.data_finish_es : exp.data_finish_en), 1),
            _createElementVNode("h4", null, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('experience.company')) + ": ", 1),
              _createTextVNode(_toDisplayString(this.$i18n.locale === "uk" ? exp.company_ua : this.$i18n.locale === "es" ? exp.company_es : exp.company_en), 1)
            ]),
            _createElementVNode("h4", null, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('experience.job')) + ": ", 1),
              _createTextVNode(_toDisplayString(this.$i18n.locale === "uk" ? exp.job_ua : this.$i18n.locale === "es" ? exp.job_es : exp.job_en), 1)
            ]),
            _createElementVNode("p", null, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('experience.main-duties')) + ": ", 1),
              _createTextVNode(_toDisplayString(this.$i18n.locale === "uk" ? exp.main_duties_ua : this.$i18n.locale === "es" ? exp.main_duties_es : exp.main_duties_en), 1)
            ]),
            _createElementVNode("p", null, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('experience.related-duties')) + ": ", 1),
              _createTextVNode(_toDisplayString(this.$i18n.locale === "uk" ? exp.related_duties_ua : this.$i18n.locale === "es" ? exp.related_duties_es : exp.related_duties_en), 1)
            ]),
            _createElementVNode("p", null, [
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('experience.tasks')) + ": ", 1),
              _createTextVNode(_toDisplayString(this.$i18n.locale === "uk" ? exp.tasks_ua : this.$i18n.locale === "es" ? exp.tasks_es : exp.tasks_en), 1)
            ]),
            _createElementVNode("p", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('experience.skills')) + ":", 1),
              _createTextVNode(" " + _toDisplayString(exp.skills), 1)
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}