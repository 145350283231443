const state = {
  javaStore:
    [
      {
        id: 1,
        title_en: "Java Starter 2019",
        title_ua: "Java Стартовий 2019",
        title_es: "Java Inicio 2019",
        regnumber: "TP45754787",
        grade: "722 / 1000",
        examdate: "22.12.2020",
        image: "https://testprovider.com/ru/search-certificate/tp45754787",
      },
      {
        id: 2,
        title_en: "Java Starter 2021",
        title_ua: "Java Стартовий 2021",
        title_es: "Java Inicio 2021",
        regnumber: "TP41734788",
        grade: "1000 / 1000",
        examdate: "17.06.2021",
        image: "https://testprovider.com/ru/search-certificate/tp41734788",
      },
      {
        id: 3,
        title_en: "Java Essential",
        title_ua: "Java Базовий",
        title_es: "Java Básico",
        regnumber: "TP85302318",
        grade: "850 / 1000",
        examdate: "16.01.2021",
        image: "https://testprovider.com/ru/search-certificate/tp85302318",
      },
      {
        id: 4,
        title_en: "Java Professional Renewed",
        title_ua: "Java Професійний Оновлений",
        title_es: "Java Profesional Actualizado",
        regnumber: "TP43141181",
        grade: "859 / 1000",
        examdate: "22.01.2021",
        image: "https://testprovider.com/ru/search-certificate/tp43141181",
      },
      {
        id: 5,
        title_en: "Java Advanced 2021",
        title_ua: "Java Поглиблений 2021",
        title_es: "Java Avanzado 2021",
        regnumber: "TP73439317",
        grade: "1000 / 1000",
        examdate: "11.08.2021",
        image: "https://testprovider.com/ru/search-certificate/tp73439317",
      },
      {
        id: 6,
        title_en: "SOLID principles in Java",
        title_ua: "Принципи SOLID в Java",
        title_es: "Principios SOLID en Java",
        regnumber: "TP00933660",
        grade: "960 / 1000",
        examdate: "14.12.2020",
        image: "https://testprovider.com/ru/search-certificate/tp00933660",
      },
      {
        id: 7,
        title_en: "Unit testing with JUnit",
        title_ua: "Модульне тестування з JUnit",
        title_es: "Pruebas unitarias con JUnit",
        regnumber: "TP52431470",
        grade: "1000 / 1000",
        examdate: "23.03.2021",
        image: "https://testprovider.com/ru/search-certificate/tp52431470",
      },
      {
        id: 8,
        title_en: "Java Database Connectivity",
        title_ua: "Java Database Connectivity",
        title_es: "Java Database Connectivity",
        regnumber: "TP57486314",
        grade: "850 / 1000",
        examdate: "28.02.2021",
        image: "https://testprovider.com/ru/search-certificate/tp57486314",
      },
      {
        id: 9,
        title_en: "JDBC & Hibernate",
        title_ua: "JDBC & Hibernate",
        title_es: "JDBC & Hibernate",
        regnumber: "TP00684634",
        grade: "850 / 1000",
        examdate: "04.03.2021",
        image: "https://testprovider.com/ru/search-certificate/tp00684634",
      },
      {
        id: 10,
        title_en: "Java EE Basic",
        title_ua: "Основи Java EE",
        title_es: "Java EE Básico",
        regnumber: "TP12409765",
        grade: "983 / 1000",
        examdate: "16.03.2021",
        image: "https://testprovider.com/ru/search-certificate/tp12409765",
      },
      {
        id: 11,
        title_en: "Spring Framework",
        title_ua: "Spring Framework",
        title_es: "Spring Framework",
        regnumber: "TP05064920",
        grade: "840 / 1000",
        examdate: "08.02.2021",
        image: "https://testprovider.com/ru/search-certificate/tp05064920",
      },
      {
        id: 12,
        title_en: "Spring MVC",
        title_ua: "Spring MVC",
        title_es: "Spring MVC",
        regnumber: "TP41523672",
        grade: "1000 / 1000",
        examdate: "14.02.2021",
        image: "https://testprovider.com/ru/search-certificate/tp41523672",
      },
      {
        id: 13,
        title_en: "Spring Data",
        title_ua: "Spring Data",
        title_es: "Spring Data",
        regnumber: "TP19471428",
        grade: "1000 / 1000",
        examdate: "27.03.2021",
        image: "https://testprovider.com/ru/search-certificate/tp19471428",
      },
      {
        id: 14,
        title_en: "Spring Security",
        title_ua: "Spring Security",
        title_es: "Spring Security",
        regnumber: "TP52120949",
        grade: "1000 / 1000",
        examdate: "29.03.2021",
        image: "https://testprovider.com/ru/search-certificate/tp52120949",
      },
      {
        id: 15,
        title_en: "Java Web Services Development",
        title_ua: "Розробка Web Services на платформі Java",
        title_es: "Desarrollo de servicios Web en la plataforma Java",
        regnumber: "TP14941346",
        grade: "1000 / 1000",
        examdate: "12.04.2021",
        image: "https://testprovider.com/ru/search-certificate/tp14941346",
      },
      {
        id: 16,
        title_en: "Jenkins",
        title_ua: "Jenkins",
        title_es: "Jenkins",
        regnumber: "TP93023078",
        grade: "1000 / 1000",
        examdate: "15.06.2021",
        image: "https://testprovider.com/ru/search-certificate/tp93023078",
      },
      {
        id: 17,
        title_en: "Thymeleaf 3.0 HTML Patterns Engine",
        title_ua: "Двигун HTML шаблонів Thymeleaf",
        title_es: "Motor de plantillas HTML Thymeleaf",
        regnumber: "TP16212701",
        grade: "1000 / 1000",
        examdate: "17.04.2021",
        image: "https://testprovider.com/ru/search-certificate/tp16212701",
      },
      {
        id: 18,
        title_en: "Scala Starter",
        title_ua: "Scala Стартовий",
        title_es: "Scala Inicio",
        regnumber: "TP28012733",
        grade: "1000 / 1000",
        examdate: "02.04.2021",
        image: "https://testprovider.com/ru/search-certificate/tp28012733",
      },
      {
        id: 19,
        title_en: "Scala Essential",
        title_ua: "Scala Базовий",
        title_es: "Scala Básico",
        regnumber: "TP41881134",
        grade: "1000 / 1000",
        examdate: "07.04.2021",
        image: "https://testprovider.com/ru/search-certificate/tp41881134",
      },
      {
        id: 20,
        title_en: "Scala Advanced",
        title_ua: "Scala Поглиблений",
        title_es: "Scala Avanzado",
        regnumber: "TP70826526",
        grade: "1000 / 1000",
        examdate: "05.05.2021",
        image: "https://testprovider.com/ru/search-certificate/tp70826526",
      },
      {
        id: 21,
        title_en: "Kotlin",
        title_ua: "Kotlin",
        title_es: "Kotlin",
        regnumber: "TP51225757",
        grade: "1000 / 1000",
        examdate: "25.04.2021",
        image: "https://testprovider.com/ru/search-certificate/tp51225757",
      },
      {
        id: 22,
        title_en: "Asynchronous programming in Java",
        title_ua: "Асинхронне програмування у Java",
        title_es: "Programación asincrónica en Java",
        regnumber: "TP33405544",
        grade: "1000 / 1000",
        examdate: "28.09.2021",
        image: "https://testprovider.com/ru/search-certificate/tp33405544",
      },
      {
        id: 23,
        title_en: "Design Patterns in Java",
        title_ua: "Паттерни проектування в Java",
        title_es: "Patrones de diseño en Java",
        regnumber: "TP37097304",
        grade: "1000 / 1000",
        examdate: "06.11.2021",
        image: "https://testprovider.com/ru/search-certificate/tp37097304",
      },
      {
        id: 24,
        title_en: "Basics of IntelliJ IDEA",
        title_ua: "Основи IntelliJ IDEA",
        title_es: "Fundamentos de IntelliJ IDEA",
        regnumber: "TP19222120",
        grade: "1000 / 1000",
        examdate: "16.04.2024",
        image: "https://testprovider.com/ru/search-certificate/tp19222120",
      },
    ]
};
const getters = {};
const mutations = {};
const actions = {};
export default {
  state,
  getters,
  mutations,
  actions
};