import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, vShow as _vShow, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "weather" }
const _hoisted_2 = { class: "inner" }
const _hoisted_3 = { class: "input-group" }
const _hoisted_4 = { for: "city" }
const _hoisted_5 = ["title"]
const _hoisted_6 = ["title"]
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "title" }
const _hoisted_9 = ["title"]
const _hoisted_10 = ["title"]
const _hoisted_11 = ["title"]
const _hoisted_12 = ["title"]
const _hoisted_13 = { class: "creep3d" }
const _hoisted_14 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CurrentDate = _resolveComponent("CurrentDate")!
  const _component_WeatherCreep3d = _resolveComponent("WeatherCreep3d")!
  const _component_WeatherCreep = _resolveComponent("WeatherCreep")!
  const _component_MyWeather = _resolveComponent("MyWeather")!
  const _component_OpenWeather = _resolveComponent("OpenWeather")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createVNode(_component_router_link, {
        class: "back",
        to: "/extra",
        title: "Back to Extra page"
      }, {
        default: _withCtx(() => _cache[17] || (_cache[17] = [
          _createElementVNode("i", { class: "fa fa-arrow-circle-left" }, null, -1)
        ])),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('extra.weather.title1')), 1)
    ]),
    _cache[20] || (_cache[20] = _createElementVNode("line", null, null, -1)),
    _createVNode(_component_CurrentDate),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('extra.weather.city')), 1),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          id: "city",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.cityName) = $event)),
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.callHandleCityInputChange(_ctx.cityName))),
          onKeydown: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.callGetWeather && _ctx.callGetWeather(...args)), ["enter"])),
          ref: "cityInput"
        }, null, 544), [
          [_vModelText, _ctx.cityName]
        ]),
        _cache[19] || (_cache[19] = _createTextVNode()),
        _createElementVNode("i", {
          title: "Clear",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.clearCity && _ctx.clearCity(...args))),
          class: "fas fa-trash-alt"
        }),
        _createElementVNode("button", {
          class: "get",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.callGetWeather && _ctx.callGetWeather(...args))),
          title: _ctx.$t('extra.weather.btn')
        }, _toDisplayString(_ctx.$t('extra.weather.get')), 9, _hoisted_5),
        _createElementVNode("button", {
          class: "getMobile",
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.callGetWeather && _ctx.callGetWeather(...args))),
          title: _ctx.$t('extra.weather.btn')
        }, _cache[18] || (_cache[18] = [
          _createElementVNode("i", { class: "fas fa-arrow-circle-down" }, null, -1)
        ]), 8, _hoisted_6),
        _withDirectives(_createElementVNode("select", {
          class: "city-list",
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.cityName) = $event)),
          onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.callUpdateCityName(_ctx.cityName)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cities, (city) => {
            return (_openBlock(), _createElementBlock("option", {
              key: city,
              value: city
            }, _toDisplayString(city), 9, _hoisted_7))
          }), 128))
        ], 544), [
          [_vModelSelect, _ctx.cityName]
        ])
      ]),
      _createElementVNode("h2", _hoisted_8, [
        _createElementVNode("i", {
          title: [_ctx.OpenWeatherView ? 'Close OpenWeather module' : 'Start OpenWeather module'],
          onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.changeOpenWeatherView && _ctx.changeOpenWeatherView(...args)))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(['fa-solid', _ctx.OpenWeatherView ? 'fa-sun' : 'fa-cloud'])
          }, null, 2)
        ], 8, _hoisted_9),
        _createElementVNode("i", {
          title: [_ctx.tableView ? 'Start viewing in List view' : 'Start viewing in Table view'],
          onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.changeView && _ctx.changeView(...args)))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(['fa', _ctx.tableView ? 'fa-list' : 'fa-th'])
          }, null, 2)
        ], 8, _hoisted_10),
        _createElementVNode("i", {
          title: [_ctx.weatherCreepView3d ? 'Close 3D Creeping line' : 'Start 3D Creeping line'],
          onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.changeWeatherCrip3d && _ctx.changeWeatherCrip3d(...args)))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(['fa','fa-solid', _ctx.weatherCreepView3d ? 'fa-yin-yang' : 'fa-cubes'])
          }, null, 2)
        ], 8, _hoisted_11),
        _createElementVNode("i", {
          title: [_ctx.cripView ? 'Close Creeping line' : 'Start Creeping line'],
          onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.changeCrip && _ctx.changeCrip(...args)))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(['fa-solid', _ctx.cripView ? 'fa-cloud-sun-rain' : 'fa-umbrella'])
          }, null, 2)
        ], 8, _hoisted_12),
        _withDirectives(_createElementVNode("input", {
          title: "Changing speed of Creeping line",
          type: "range",
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.speed) = $event)),
          min: "0",
          max: "6",
          step: "0.2"
        }, null, 512), [
          [_vShow, _ctx.cripView],
          [
            _vModelText,
            _ctx.speed,
            void 0,
            { number: true }
          ]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(_component_WeatherCreep3d, {
        "weather-creep-view3d": _ctx.weatherCreepView3d,
        cityName: _ctx.cityName,
        "onUpdate:cityName": _cache[13] || (_cache[13] = ($event: any) => (_ctx.cityName = $event)),
        "onUpdate:cities": _cache[14] || (_cache[14] = ($event: any) => (_ctx.cities = $event)),
        ref: "weatherCreep3d"
      }, null, 8, ["weather-creep-view3d", "cityName"])
    ]),
    _createVNode(_component_WeatherCreep, {
      ref: "weatherCreep",
      class: "creep",
      cityName: _ctx.cityName,
      "onUpdate:cities": _cache[15] || (_cache[15] = ($event: any) => (_ctx.cities = $event)),
      "crip-view": _ctx.cripView,
      speed: _ctx.speed
    }, null, 8, ["cityName", "crip-view", "speed"]),
    _createElementVNode("div", _hoisted_14, [
      _createVNode(_component_MyWeather, {
        ref: "myWeatherComponent",
        class: "myWidget",
        cityName: _ctx.cityName,
        "onUpdate:cities": _cache[16] || (_cache[16] = ($event: any) => (_ctx.cities = $event)),
        "table-view": _ctx.tableView
      }, null, 8, ["cityName", "table-view"]),
      (_ctx.OpenWeatherView)
        ? (_openBlock(), _createBlock(_component_OpenWeather, {
            key: 0,
            class: "widget",
            widgetId: 15,
            cityId: '703448'
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}