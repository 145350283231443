import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "skills" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "hard-skills" }
const _hoisted_4 = { class: "soft-skills" }
const _hoisted_5 = { class: "iq-test" }
const _hoisted_6 = { class: "iq" }
const _hoisted_7 = { class: "iq-photo" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "iq-desc" }
const _hoisted_10 = ["href"]
const _hoisted_11 = {
  href: "https://impulse.onelink.me/92Ym/testiq",
  title: "Test your IQ here",
  target: "_blank"
}
const _hoisted_12 = { class: "special-certificates" }
const _hoisted_13 = {
  key: 0,
  class: "table"
}
const _hoisted_14 = { class: "nomer" }
const _hoisted_15 = { class: "name" }
const _hoisted_16 = ["href"]
const _hoisted_17 = { class: "number" }
const _hoisted_18 = { class: "grade" }
const _hoisted_19 = { class: "date" }
const _hoisted_20 = {
  key: 1,
  class: "inner"
}
const _hoisted_21 = ["href"]
const _hoisted_22 = { class: "slider" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_codersrank_skills_chart = _resolveComponent("codersrank-skills-chart")!
  const _component_Slider = _resolveComponent("Slider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createTextVNode(_toDisplayString(_ctx.$t('skills.title')) + " ", 1),
      _createElementVNode("i", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeChart && _ctx.changeChart(...args))),
        class: "dandruff"
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(['fa', _ctx.isCodersrankSkillsChartVisible ? 'fa-check-circle' : 'fa-hat-wizard'])
        }, null, 2)
      ])
    ]),
    _cache[13] || (_cache[13] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("div", {
      class: "container",
      style: _normalizeStyle({gridTemplateColumns: _ctx.gridColumns, gridTemplateAreas: _ctx.gridAreas})
    }, [
      (_ctx.isCodersrankSkillsChartVisible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_codersrank_skills_chart, {
              username: "zorger27",
              labels: "true",
              legend: "true",
              skills: "JSON, JavaScript, Vue, CSS, SCSS, HTML, TypeScript",
              branding: "false"
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass({'type-skills': true, 'additional-styles': _ctx.shouldApplyAdditionalStyles})
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('skills.hard')), 1),
          _cache[2] || (_cache[2] = _createElementVNode("ul", null, [
            _createElementVNode("li", null, [
              _createElementVNode("span", null, "☑"),
              _createTextVNode("Html5, CSS3, JavaScript")
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("span", null, "☑"),
              _createTextVNode("Vue.js, Angular, Three.js")
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("span", null, "☑"),
              _createTextVNode("Git, GitHub")
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("span", null, "☑"),
              _createTextVNode("Figma, Photoshop")
            ])
          ], -1))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('skills.soft.sskills')), 1),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, [
              _cache[3] || (_cache[3] = _createElementVNode("span", null, "☑", -1)),
              _createTextVNode(_toDisplayString(_ctx.$t('skills.soft.management')), 1)
            ]),
            _createElementVNode("li", null, [
              _cache[4] || (_cache[4] = _createElementVNode("span", null, "☑", -1)),
              _createTextVNode(_toDisplayString(_ctx.$t('skills.soft.strategic')), 1)
            ]),
            _createElementVNode("li", null, [
              _cache[5] || (_cache[5] = _createElementVNode("span", null, "☑", -1)),
              _createTextVNode(_toDisplayString(_ctx.$t('skills.soft.critical')), 1)
            ]),
            _createElementVNode("li", null, [
              _cache[6] || (_cache[6] = _createElementVNode("span", null, "☑", -1)),
              _createTextVNode(_toDisplayString(_ctx.$t('skills.soft.negotiation')), 1)
            ])
          ])
        ])
      ], 2),
      _createElementVNode("div", _hoisted_5, [
        _cache[10] || (_cache[10] = _createElementVNode("line", null, null, -1)),
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('skills.iq.title')), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("img", {
              src: _ctx.getImageUrl,
              alt: "Localized IQ score Image"
            }, null, 8, _hoisted_8)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("ul", null, [
              _createElementVNode("li", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('skills.iq.organization')), 1),
                _cache[7] || (_cache[7] = _createElementVNode("span", { style: {"font-weight":"bold"} }, "IMPULSE", -1))
              ]),
              _createElementVNode("li", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('skills.iq.date')), 1),
                _cache[8] || (_cache[8] = _createElementVNode("span", { style: {"font-weight":"bold"} }, "17.07.2023", -1))
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  style: {"text-decoration":"none"},
                  href: _ctx.getPdfUrl,
                  title: "Download IQ report pdf file",
                  target: "_blank"
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('skills.iq.report')), 1),
                  _cache[9] || (_cache[9] = _createElementVNode("i", { class: "fas fa-cloud-download" }, null, -1))
                ], 8, _hoisted_10)
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("a", _hoisted_11, _toDisplayString(_ctx.$t('skills.iq.test')), 1)
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _cache[12] || (_cache[12] = _createElementVNode("line", null, null, -1)),
        _createElementVNode("h2", null, [
          _createTextVNode(_toDisplayString(_ctx.$t('skills.special')) + " ", 1),
          _createElementVNode("i", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeView && _ctx.changeView(...args)))
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(['fa', _ctx.tableView ? 'fa-list' : 'fa-th'])
            }, null, 2)
          ])
        ]),
        (_ctx.tableView)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createElementVNode("table", null, [
                _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _cache[11] || (_cache[11] = _createElementVNode("th", null, "№", -1)),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.title')), 1),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.number')), 1),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.grade')), 1),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.date')), 1)
                  ])
                ]),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedOther, (sert, index) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: sert.id
                    }, [
                      _createElementVNode("td", _hoisted_14, _toDisplayString(index + 1), 1),
                      _createElementVNode("td", _hoisted_15, [
                        _createElementVNode("a", {
                          href: sert.image,
                          title: "In more detail...",
                          target: "_blank"
                        }, _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 9, _hoisted_16)
                      ]),
                      _createElementVNode("td", _hoisted_17, _toDisplayString(sert.regnumber), 1),
                      _createElementVNode("td", _hoisted_18, _toDisplayString(this.$i18n.locale === "uk" ? sert.grade_ua : this.$i18n.locale === "es" ? sert.grade_es : sert.grade_en), 1),
                      _createElementVNode("td", _hoisted_19, _toDisplayString(sert.examdate), 1)
                    ]))
                  }), 128))
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_20, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedOther, (sert, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: sert.id,
                  class: "certificate"
                }, [
                  _createElementVNode("a", {
                    class: "block",
                    href: sert.image,
                    title: "Certificate...",
                    target: "_blank"
                  }, [
                    _createElementVNode("h3", null, _toDisplayString(index + 1) + ". " + _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 1),
                    _createElementVNode("div", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t('cert.number')) + ": ", 1),
                      _createElementVNode("strong", null, _toDisplayString(sert.regnumber), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                      _createElementVNode("strong", null, _toDisplayString(this.$i18n.locale === "uk" ? sert.grade_ua : this.$i18n.locale === "es" ? sert.grade_es : sert.grade_en), 1)
                    ]),
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t('cert.date')) + ": " + _toDisplayString(sert.examdate), 1)
                  ], 8, _hoisted_21)
                ]))
              }), 128))
            ]))
      ])
    ], 4),
    _createElementVNode("div", _hoisted_22, [
      _createVNode(_component_Slider, { images: _ctx.sliderImages }, null, 8, ["images"])
    ])
  ]))
}