import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "game" }
const _hoisted_2 = { class: "diploma" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createVNode(_component_router_link, {
        class: "back",
        to: "/extra",
        title: "Back to Extra page"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("i", { class: "fa fa-arrow-circle-left" }, null, -1)
        ])),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('extra.h1m.game')), 1)
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/extra/games/memory",
        class: "block",
        title: "Game..."
      }, {
        default: _withCtx(() => [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('extra.game.memory.name')), 1)
        ]),
        _: 1
      })
    ])
  ]))
}