import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, vShow as _vShow, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cryptocur" }
const _hoisted_2 = { class: "bank" }
const _hoisted_3 = { class: "creep3d" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CurrentDate = _resolveComponent("CurrentDate")!
  const _component_CryptosCreep3d = _resolveComponent("CryptosCreep3d")!
  const _component_CryptosCreep = _resolveComponent("CryptosCreep")!
  const _component_Cryptocurrencies = _resolveComponent("Cryptocurrencies")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createVNode(_component_router_link, {
        class: "back",
        to: "/extra",
        title: "Back to Extra page"
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createElementVNode("i", { class: "fa fa-arrow-circle-left" }, null, -1)
        ])),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('extra.cryptocur.title')), 1)
    ]),
    _cache[10] || (_cache[10] = _createElementVNode("line", null, null, -1)),
    _createVNode(_component_CurrentDate),
    _createElementVNode("h1", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.$t('extra.cryptocur.h1')) + " ", 1),
      _cache[5] || (_cache[5] = _createElementVNode("a", {
        href: "https://www.coingecko.com",
        title: "In more detail...",
        target: "_blank"
      }, " CoinGecko ", -1)),
      _cache[6] || (_cache[6] = _createTextVNode()),
      _createElementVNode("i", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeView && _ctx.changeView(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(['fa', _ctx.tableView ? 'fa-list' : 'fa-th'])
        }, null, 2)
      ]),
      _cache[7] || (_cache[7] = _createTextVNode()),
      _createElementVNode("i", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeCryptosCreepView3d && _ctx.changeCryptosCreepView3d(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(['fa-brands', _ctx.cryptosCreepView3d ? 'fa-ethereum' : 'fa-bitcoin'])
        }, null, 2)
      ]),
      _cache[8] || (_cache[8] = _createTextVNode()),
      _createElementVNode("i", {
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.changeCrip && _ctx.changeCrip(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(['fa', _ctx.cripView ? 'fa-face-grin-stars' : 'fa-wallet'])
        }, null, 2)
      ]),
      _cache[9] || (_cache[9] = _createTextVNode()),
      _withDirectives(_createElementVNode("input", {
        type: "range",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.speed) = $event)),
        min: "0",
        max: "6",
        step: "0.2"
      }, null, 512), [
        [_vShow, _ctx.cripView],
        [
          _vModelText,
          _ctx.speed,
          void 0,
          { number: true }
        ]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CryptosCreep3d, { "cryptos-creep-view3d": _ctx.cryptosCreepView3d }, null, 8, ["cryptos-creep-view3d"])
    ]),
    _createVNode(_component_CryptosCreep, {
      class: "creep",
      "crip-view": _ctx.cripView,
      speed: _ctx.speed
    }, null, 8, ["crip-view", "speed"]),
    _createVNode(_component_Cryptocurrencies, { "table-view": _ctx.tableView }, null, 8, ["table-view"])
  ]))
}