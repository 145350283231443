import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "table"
}
const _hoisted_2 = { class: "nomer" }
const _hoisted_3 = { class: "code" }
const _hoisted_4 = { class: "name" }
const _hoisted_5 = { class: "price" }
const _hoisted_6 = {
  key: 1,
  class: "inner"
}
const _hoisted_7 = { class: "name" }
const _hoisted_8 = { class: "price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.tableView)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("table", null, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _cache[0] || (_cache[0] = _createElementVNode("th", null, "№", -1)),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('extra.exchange.code')), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('extra.exchange.currency')), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('extra.exchange.rate')), 1)
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rates, (rate, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createElementVNode("td", _hoisted_2, _toDisplayString(index + 1), 1),
                _createElementVNode("td", _hoisted_3, _toDisplayString(rate.cc), 1),
                _createElementVNode("td", _hoisted_4, _toDisplayString(rate.txt), 1),
                _createElementVNode("td", _hoisted_5, _toDisplayString(rate.rate.toFixed(2)), 1)
              ]))
            }), 128))
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rates, (rate, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "rates"
          }, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(rate.txt), 1),
            _cache[1] || (_cache[1] = _createTextVNode("=")),
            _createElementVNode("span", _hoisted_8, _toDisplayString(rate.rate.toFixed(2)), 1),
            _createTextVNode(_toDisplayString(_ctx.$t('extra.exchange.uah')), 1)
          ]))
        }), 128))
      ]))
}