import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cube" }
const _hoisted_2 = { class: "inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ToggleFullScreen = _resolveComponent("ToggleFullScreen")!
  const _component_CubeCSS = _resolveComponent("CubeCSS")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createVNode(_component_router_link, {
        class: "back-to-menu",
        to: "/extra/graphics",
        title: "Back to 3D Graphics page"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("i", { class: "fa fa-arrow-circle-left" }, null, -1)
        ])),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('extra.graphics.cube')) + " ", 1),
      _createVNode(_component_ToggleFullScreen)
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CubeCSS)
    ])
  ]))
}