import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "memory" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { style: {"color":"deeppink","margin":"0.5rem"} }
const _hoisted_4 = { class: "memory-game" }
const _hoisted_5 = ["data-fruit", "onClick"]
const _hoisted_6 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createVNode(_component_router_link, {
        class: "back",
        to: "/extra/games",
        title: "Back to Games page"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("i", { class: "fa fa-arrow-circle-left" }, null, -1)
        ])),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('extra.game.memory.name')), 1)
    ]),
    _cache[5] || (_cache[5] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("h2", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showRules = !_ctx.showRules)),
      class: "rules"
    }, [
      _createTextVNode(_toDisplayString(_ctx.$t('extra.game.memory.rules-h2')), 1),
      _cache[3] || (_cache[3] = _createElementVNode("i", {
        style: {"color":"red","margin-left":"0.5rem"},
        class: "fas fa-hand-pointer"
      }, null, -1))
    ]),
    (_ctx.showRules)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('extra.game.memory.rules')), 1),
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('extra.game.memory.luck')), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.startNewGame && _ctx.startNewGame(...args)))
      }, _toDisplayString(_ctx.$t('extra.game.memory.newGame')), 1)
    ]),
    _cache[6] || (_cache[6] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shuffledCards, (card, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["memory-card", { 'flipped': card.flipped, 'matched': card.matched }]),
          key: index,
          "data-fruit": card.fruit,
          onClick: ($event: any) => (_ctx.flipCard(index))
        }, [
          _createElementVNode("img", {
            src: card.image,
            alt: card.fruit,
            class: "front-face"
          }, null, 8, _hoisted_6),
          _cache[4] || (_cache[4] = _createElementVNode("img", {
            src: "/assets/memory/Card.webp",
            alt: "Back face",
            class: "back-face"
          }, null, -1))
        ], 10, _hoisted_5))
      }), 128))
    ])
  ]))
}