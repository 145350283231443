import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("select", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.locale) = $event)),
    onChange: _cache[1] || (_cache[1] = ($event: any) => ($setup.savelocale($setup.locale)))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.availableLocales, (sLocale) => {
      return (_openBlock(), _createElementBlock("option", {
        key: `locale.${sLocale}`,
        value: sLocale,
        selected: $setup.locale === sLocale
      }, _toDisplayString($setup.t($setup.getLocaleKey(sLocale))), 9, _hoisted_1))
    }), 128))
  ], 544)), [
    [_vModelSelect, $setup.locale]
  ])
}