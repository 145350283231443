import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "education" }
const _hoisted_2 = {
  key: 0,
  class: "table"
}
const _hoisted_3 = {
  class: "title",
  colspan: "5"
}
const _hoisted_4 = { style: {"text-align":"left"} }
const _hoisted_5 = { class: "nomer" }
const _hoisted_6 = { class: "name" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "number" }
const _hoisted_9 = { class: "grade" }
const _hoisted_10 = { class: "date" }
const _hoisted_11 = {
  key: 1,
  class: "container"
}
const _hoisted_12 = ["href"]
const _hoisted_13 = {
  key: 2,
  class: "table"
}
const _hoisted_14 = {
  class: "title",
  colspan: "5"
}
const _hoisted_15 = { style: {"text-align":"left"} }
const _hoisted_16 = { class: "nomer" }
const _hoisted_17 = { class: "name" }
const _hoisted_18 = ["href"]
const _hoisted_19 = { class: "number" }
const _hoisted_20 = { class: "grade" }
const _hoisted_21 = { class: "date" }
const _hoisted_22 = {
  key: 3,
  class: "container"
}
const _hoisted_23 = ["href"]
const _hoisted_24 = {
  key: 4,
  class: "table"
}
const _hoisted_25 = { style: {"text-align":"left"} }
const _hoisted_26 = { class: "nomer" }
const _hoisted_27 = { class: "name" }
const _hoisted_28 = ["href"]
const _hoisted_29 = { class: "number" }
const _hoisted_30 = { class: "grade" }
const _hoisted_31 = { class: "date" }
const _hoisted_32 = {
  key: 5,
  class: "container"
}
const _hoisted_33 = ["href"]
const _hoisted_34 = {
  key: 6,
  class: "table"
}
const _hoisted_35 = {
  class: "title",
  colspan: "5"
}
const _hoisted_36 = {
  href: "https://knute.edu.ua",
  title: "Link to the University",
  target: "_blank"
}
const _hoisted_37 = { style: {"color":"#0303af"} }
const _hoisted_38 = { style: {"text-align":"left"} }
const _hoisted_39 = { class: "nomer" }
const _hoisted_40 = { class: "name" }
const _hoisted_41 = ["href"]
const _hoisted_42 = { class: "number" }
const _hoisted_43 = { class: "grade" }
const _hoisted_44 = { class: "date" }
const _hoisted_45 = {
  key: 7,
  class: "container"
}
const _hoisted_46 = {
  href: "https://knute.edu.ua",
  title: "Link to the University",
  target: "_blank"
}
const _hoisted_47 = ["href"]
const _hoisted_48 = { class: "languages" }
const _hoisted_49 = {
  key: 0,
  class: "table",
  style: {"margin-left":"0"}
}
const _hoisted_50 = { style: {"text-align":"left"} }
const _hoisted_51 = { class: "nomer" }
const _hoisted_52 = {
  class: "name",
  style: {"width":"max-content"}
}
const _hoisted_53 = ["href"]
const _hoisted_54 = {
  class: "number",
  style: {"width":"max-content"}
}
const _hoisted_55 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createTextVNode(_toDisplayString(_ctx.$t('education.title')) + " ", 1),
      _createElementVNode("i", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeView && _ctx.changeView(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(['fa', _ctx.tableView ? 'fa-list' : 'fa-th'])
        }, null, 2)
      ])
    ]),
    _cache[19] || (_cache[19] = _createElementVNode("line", null, null, -1)),
    (_ctx.tableView)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("table", null, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_3, [
                  _createElementVNode("i", null, "11.2020 - " + _toDisplayString(_ctx.$t('education.present')), 1)
                ])
              ]),
              _cache[2] || (_cache[2] = _createElementVNode("tr", null, [
                _createElementVNode("th", {
                  class: "title",
                  colspan: "5"
                }, [
                  _createElementVNode("a", {
                    href: "https://itvdn.com",
                    title: "Link to the ITVDN",
                    target: "_blank"
                  }, [
                    _createElementVNode("b", { style: {"color":"#0303af"} }, "ITVDN - IT Video Developers Network")
                  ])
                ])
              ], -1)),
              _createElementVNode("tr", null, [
                _cache[1] || (_cache[1] = _createElementVNode("th", null, "№", -1)),
                _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.$t('cert.title')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.number')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.grade')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.date')), 1)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itvdnStore.state.itvdnStore, (sert) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: sert.id
                }, [
                  _createElementVNode("td", _hoisted_5, _toDisplayString(sert.id), 1),
                  _createElementVNode("td", _hoisted_6, [
                    _createElementVNode("a", {
                      href: sert.image,
                      title: "Diploma...",
                      target: "_blank"
                    }, _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 9, _hoisted_7)
                  ]),
                  _createElementVNode("td", _hoisted_8, _toDisplayString(sert.regnumber), 1),
                  _createElementVNode("td", _hoisted_9, _toDisplayString(sert.grade), 1),
                  _createElementVNode("td", _hoisted_10, _toDisplayString(sert.examdate), 1)
                ]))
              }), 128))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("h2", null, [
            _createElementVNode("span", null, "11.2020 - " + _toDisplayString(_ctx.$t('education.present')), 1),
            _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
            _cache[4] || (_cache[4] = _createElementVNode("a", {
              href: "https://itvdn.com",
              title: "Link to the ITVDN",
              target: "_blank"
            }, [
              _createElementVNode("b", null, "ITVDN - IT Video Developers Network")
            ], -1))
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itvdnStore.state.itvdnStore, (sert) => {
            return (_openBlock(), _createElementBlock("div", {
              key: sert.id,
              class: "diploma"
            }, [
              _createElementVNode("a", {
                class: "block",
                href: sert.image,
                title: "Diploma...",
                target: "_blank"
              }, [
                _createElementVNode("h3", null, _toDisplayString(sert.id) + ". " + _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 1),
                _createElementVNode("div", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('cert.number')) + ": ", 1),
                  _createElementVNode("strong", null, _toDisplayString(sert.regnumber), 1)
                ]),
                _createElementVNode("div", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                  _createElementVNode("strong", null, _toDisplayString(sert.grade), 1)
                ]),
                _createElementVNode("div", null, _toDisplayString(_ctx.$t('cert.date')) + ": " + _toDisplayString(sert.examdate), 1)
              ], 8, _hoisted_12)
            ]))
          }), 128))
        ])),
    (_ctx.tableView)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("table", null, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_14, [
                  _createElementVNode("i", null, "11.2021 - " + _toDisplayString(_ctx.$t('education.present')), 1)
                ])
              ]),
              _cache[6] || (_cache[6] = _createElementVNode("tr", null, [
                _createElementVNode("th", {
                  class: "title",
                  colspan: "5"
                }, [
                  _createElementVNode("a", {
                    href: "https://www.englishdom.com",
                    title: "Link to the EnglishDom",
                    target: "_blank"
                  }, [
                    _createElementVNode("b", { style: {"color":"#0303af"} }, "EnglishDom")
                  ])
                ])
              ], -1)),
              _createElementVNode("tr", null, [
                _cache[5] || (_cache[5] = _createElementVNode("th", null, "№", -1)),
                _createElementVNode("th", _hoisted_15, _toDisplayString(_ctx.$t('cert.title')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.level')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.grade')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.date')), 1)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.englishdomStore.state.englishdomStore, (sert) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: sert.id
                }, [
                  _createElementVNode("td", _hoisted_16, _toDisplayString(sert.id), 1),
                  _createElementVNode("td", _hoisted_17, [
                    _createElementVNode("a", {
                      href: sert.image,
                      title: "Certificate...",
                      target: "_blank"
                    }, _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 9, _hoisted_18)
                  ]),
                  _createElementVNode("td", _hoisted_19, _toDisplayString(sert.regnumber), 1),
                  _createElementVNode("td", _hoisted_20, _toDisplayString(this.$i18n.locale === "uk" ? sert.grade_ua : this.$i18n.locale === "es" ? sert.grade_es : sert.grade_en), 1),
                  _createElementVNode("td", _hoisted_21, _toDisplayString(sert.examdate), 1)
                ]))
              }), 128))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_22, [
          _createElementVNode("h2", null, [
            _createElementVNode("span", null, "11.2021 - " + _toDisplayString(_ctx.$t('education.present')), 1),
            _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
            _cache[8] || (_cache[8] = _createElementVNode("a", {
              href: "https://www.englishdom.com",
              title: "Link to the EnglishDom",
              target: "_blank"
            }, [
              _createElementVNode("b", null, "EnglishDom")
            ], -1))
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.englishdomStore.state.englishdomStore, (sert) => {
            return (_openBlock(), _createElementBlock("div", {
              key: sert.id,
              class: "diploma"
            }, [
              _createElementVNode("a", {
                class: "block",
                href: sert.image,
                title: "Certificate...",
                target: "_blank"
              }, [
                _createElementVNode("h3", null, _toDisplayString(sert.id) + ". " + _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 1),
                _createElementVNode("div", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('cert.level')) + ": ", 1),
                  _createElementVNode("strong", null, _toDisplayString(sert.regnumber), 1)
                ]),
                _createElementVNode("div", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                  _createElementVNode("strong", null, _toDisplayString(this.$i18n.locale === "uk" ? sert.grade_ua : this.$i18n.locale === "es" ? sert.grade_es : sert.grade_en), 1)
                ]),
                _createElementVNode("div", null, _toDisplayString(_ctx.$t('cert.date')) + ": " + _toDisplayString(sert.examdate), 1)
              ], 8, _hoisted_23)
            ]))
          }), 128))
        ])),
    (_ctx.tableView)
      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
          _createElementVNode("table", null, [
            _createElementVNode("thead", null, [
              _cache[10] || (_cache[10] = _createElementVNode("tr", null, [
                _createElementVNode("th", {
                  class: "title",
                  colspan: "5"
                }, [
                  _createElementVNode("i", null, "04.2017 - 11.2017")
                ])
              ], -1)),
              _cache[11] || (_cache[11] = _createElementVNode("tr", null, [
                _createElementVNode("th", {
                  class: "title",
                  colspan: "5"
                }, [
                  _createElementVNode("a", {
                    href: "https://prog.academy",
                    title: "Link to the Prog.academy",
                    target: "_blank"
                  }, [
                    _createElementVNode("b", { style: {"color":"#0303af"} }, "PROG.kiev.ua")
                  ])
                ])
              ], -1)),
              _createElementVNode("tr", null, [
                _cache[9] || (_cache[9] = _createElementVNode("th", null, "№", -1)),
                _createElementVNode("th", _hoisted_25, _toDisplayString(_ctx.$t('cert.title')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.number')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.grade')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.date')), 1)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.progStore.state.progStore, (sert) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: sert.id
                }, [
                  _createElementVNode("td", _hoisted_26, _toDisplayString(sert.id), 1),
                  _createElementVNode("td", _hoisted_27, [
                    _createElementVNode("a", {
                      href: sert.image,
                      title: "Certificate...",
                      target: "_blank"
                    }, _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 9, _hoisted_28)
                  ]),
                  _createElementVNode("td", _hoisted_29, _toDisplayString(this.$i18n.locale === "uk" ? sert.regnumber_ua : this.$i18n.locale === "es" ? sert.regnumber_es : sert.regnumber_en), 1),
                  _createElementVNode("td", _hoisted_30, _toDisplayString(this.$i18n.locale === "uk" ? sert.grade_ua : this.$i18n.locale === "es" ? sert.grade_es : sert.grade_en), 1),
                  _createElementVNode("td", _hoisted_31, _toDisplayString(sert.examdate), 1)
                ]))
              }), 128))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_32, [
          _cache[12] || (_cache[12] = _createElementVNode("h2", null, [
            _createElementVNode("span", null, "04.2017 - 11.2017"),
            _createElementVNode("br"),
            _createElementVNode("a", {
              href: "https://prog.academy",
              title: "Link to the Prog.academy",
              target: "_blank"
            }, [
              _createElementVNode("b", null, "PROG.kiev.ua")
            ])
          ], -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.progStore.state.progStore, (sert) => {
            return (_openBlock(), _createElementBlock("div", {
              key: sert.id,
              class: "diploma"
            }, [
              _createElementVNode("a", {
                class: "block",
                href: sert.image,
                title: "Certificate...",
                target: "_blank"
              }, [
                _createElementVNode("h3", null, _toDisplayString(sert.id) + ". " + _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 1),
                _createElementVNode("div", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('cert.number')) + ": ", 1),
                  _createElementVNode("strong", null, _toDisplayString(this.$i18n.locale === "uk" ? sert.regnumber_ua : this.$i18n.locale === "es" ? sert.regnumber_es : sert.regnumber_en), 1)
                ]),
                _createElementVNode("div", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                  _createElementVNode("strong", null, _toDisplayString(this.$i18n.locale === "uk" ? sert.grade_ua : this.$i18n.locale === "es" ? sert.grade_es : sert.grade_en), 1)
                ]),
                _createElementVNode("div", null, _toDisplayString(_ctx.$t('cert.date')) + ": " + _toDisplayString(sert.examdate), 1)
              ], 8, _hoisted_33)
            ]))
          }), 128))
        ])),
    (_ctx.tableView)
      ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
          _createElementVNode("table", null, [
            _createElementVNode("thead", null, [
              _cache[14] || (_cache[14] = _createElementVNode("tr", null, [
                _createElementVNode("th", {
                  class: "title",
                  colspan: "5"
                }, [
                  _createElementVNode("i", null, "09.1994 - 01.1999")
                ])
              ], -1)),
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_35, [
                  _createElementVNode("a", _hoisted_36, [
                    _createElementVNode("b", _hoisted_37, _toDisplayString(_ctx.$t ('education.knute')), 1)
                  ])
                ])
              ]),
              _createElementVNode("tr", null, [
                _cache[13] || (_cache[13] = _createElementVNode("th", null, "№", -1)),
                _createElementVNode("th", _hoisted_38, _toDisplayString(_ctx.$t('cert.title')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.number')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.grade')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('education.graduation')), 1)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.knuteStore.state.knuteStore, (sert) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: sert.id
                }, [
                  _createElementVNode("td", _hoisted_39, _toDisplayString(sert.id), 1),
                  _createElementVNode("td", _hoisted_40, [
                    _createElementVNode("a", {
                      href: sert.image,
                      title: "Diploma...",
                      target: "_blank"
                    }, _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 9, _hoisted_41)
                  ]),
                  _createElementVNode("td", _hoisted_42, _toDisplayString(sert.regnumber), 1),
                  _createElementVNode("td", _hoisted_43, _toDisplayString(this.$i18n.locale === "uk" ? sert.grade_ua : this.$i18n.locale === "es" ? sert.grade_es : sert.grade_en), 1),
                  _createElementVNode("td", _hoisted_44, _toDisplayString(sert.examdate), 1)
                ]))
              }), 128))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_45, [
          _createElementVNode("h2", null, [
            _cache[15] || (_cache[15] = _createElementVNode("span", null, "09.1994 - 01.1999", -1)),
            _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("a", _hoisted_46, [
              _createElementVNode("b", null, _toDisplayString(_ctx.$t ('education.knute')), 1)
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.knuteStore.state.knuteStore, (sert) => {
            return (_openBlock(), _createElementBlock("div", {
              key: sert.id,
              class: "diploma"
            }, [
              _createElementVNode("a", {
                class: "block",
                href: sert.image,
                title: "Diploma...",
                target: "_blank"
              }, [
                _createElementVNode("h3", null, _toDisplayString(sert.id) + ". " + _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 1),
                _createElementVNode("div", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('cert.number')) + ": ", 1),
                  _createElementVNode("strong", null, _toDisplayString(sert.regnumber), 1)
                ]),
                _createElementVNode("div", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                  _createElementVNode("strong", null, _toDisplayString(this.$i18n.locale === "uk" ? sert.grade_ua : this.$i18n.locale === "es" ? sert.grade_es : sert.grade_en), 1)
                ]),
                _createElementVNode("div", null, _toDisplayString(_ctx.$t('education.graduation')) + ": " + _toDisplayString(sert.examdate), 1)
              ], 8, _hoisted_47)
            ]))
          }), 128))
        ])),
    _createElementVNode("div", _hoisted_48, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t ('education.languages')), 1),
      _cache[18] || (_cache[18] = _createElementVNode("line", null, null, -1)),
      (_ctx.tableView)
        ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
            _createElementVNode("table", null, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _cache[17] || (_cache[17] = _createElementVNode("th", null, "№", -1)),
                  _createElementVNode("th", _hoisted_50, _toDisplayString(_ctx.$t('cert.title')), 1),
                  _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.level')), 1)
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languagesStore.state.languagesStore, (sert) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: sert.id
                  }, [
                    _createElementVNode("td", _hoisted_51, _toDisplayString(sert.id), 1),
                    _createElementVNode("td", _hoisted_52, [
                      _createElementVNode("a", {
                        href: sert.image,
                        title: "Certificate...",
                        target: "_blank"
                      }, _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 9, _hoisted_53)
                    ]),
                    _createElementVNode("td", _hoisted_54, _toDisplayString(sert.regnumber), 1)
                  ]))
                }), 128))
              ])
            ])
          ]))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.languagesStore.state.languagesStore, (sert) => {
            return (_openBlock(), _createElementBlock("div", {
              key: sert.id,
              class: "certificate"
            }, [
              _createElementVNode("a", {
                class: "block",
                href: sert.image,
                title: "Certificate...",
                target: "_blank"
              }, [
                _createElementVNode("h3", null, _toDisplayString(sert.id) + ". " + _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 1),
                _createElementVNode("div", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('cert.level')) + ": ", 1),
                  _createElementVNode("strong", null, _toDisplayString(sert.regnumber), 1)
                ])
              ], 8, _hoisted_55)
            ]))
          }), 128))
    ])
  ]))
}