import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "graphics" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "diploma" }
const _hoisted_4 = { class: "diploma" }
const _hoisted_5 = { class: "diploma" }
const _hoisted_6 = { class: "diploma" }
const _hoisted_7 = { class: "diploma" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createVNode(_component_router_link, {
        class: "back",
        to: "/extra",
        title: "Back to Extra page"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("i", { class: "fa fa-arrow-circle-left" }, null, -1)
        ])),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('extra.graphics.title')), 1)
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: "/extra/graphics/cube",
          class: "block",
          title: "Cube (CSS)"
        }, {
          default: _withCtx(() => [
            _createElementVNode("h3", null, _toDisplayString(_ctx.$t('extra.graphics.cube')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_link, {
          to: "/extra/graphics/cubejs",
          class: "block",
          title: "Cube (JavaScript)"
        }, {
          default: _withCtx(() => [
            _createElementVNode("h3", null, _toDisplayString(_ctx.$t('extra.graphics.cubejs')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_link, {
          to: "/extra/graphics/cube3d",
          class: "block",
          title: "CuboVerse"
        }, {
          default: _withCtx(() => [
            _createElementVNode("h3", null, _toDisplayString(_ctx.$t('extra.graphics.cube3d')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_router_link, {
          to: "/extra/graphics/creep3d",
          class: "block",
          title: "Creeping Lines (Three.js)"
        }, {
          default: _withCtx(() => [
            _createElementVNode("h3", null, _toDisplayString(_ctx.$t('extra.graphics.creep3d')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_router_link, {
          to: "/extra/graphics/configurator3d",
          class: "block",
          title: "3D Configurator (Three.js)"
        }, {
          default: _withCtx(() => [
            _createElementVNode("h3", null, _toDisplayString(_ctx.$t('extra.graphics.configurator3d.title')), 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}