import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "about" }
const _hoisted_2 = { class: "main" }
const _hoisted_3 = {
  key: 0,
  style: {"margin":"0"}
}
const _hoisted_4 = {
  key: 1,
  style: {"margin":"0"}
}
const _hoisted_5 = {
  key: 2,
  style: {"margin":"0"}
}
const _hoisted_6 = {
  key: 3,
  style: {"color":"deeppink","margin":"0.5rem"}
}
const _hoisted_7 = {
  key: 4,
  class: "table"
}
const _hoisted_8 = {
  class: "title",
  colspan: "3"
}
const _hoisted_9 = { class: "nomer" }
const _hoisted_10 = { class: "name" }
const _hoisted_11 = ["href"]
const _hoisted_12 = { class: "version" }
const _hoisted_13 = {
  key: 5,
  class: "div"
}
const _hoisted_14 = {
  class: "title",
  style: {"text-decoration":"underline"}
}
const _hoisted_15 = ["href"]
const _hoisted_16 = { style: {"color":"black"} }
const _hoisted_17 = { style: {"color":"red"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.$t ('about.title')) + " ", 1),
      _createElementVNode("i", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeView && _ctx.changeView(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(['fa', _ctx.tableView ? 'fa-list' : 'fa-th'])
        }, null, 2)
      ])
    ]),
    _cache[6] || (_cache[6] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("h2", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showMore = !_ctx.showMore)),
      class: "more"
    }, [
      _createTextVNode(_toDisplayString(_ctx.$t ('about.more01')), 1),
      _cache[2] || (_cache[2] = _createElementVNode("i", {
        style: {"color":"red","margin-left":"0.5rem"},
        class: "fas fa-hand-pointer"
      }, null, -1))
    ]),
    (_ctx.showMore)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t ('about.more02')), 1))
      : _createCommentVNode("", true),
    (_ctx.showMore)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t ('about.more03')), 1))
      : _createCommentVNode("", true),
    (_ctx.showMore)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t ('about.more04')), 1))
      : _createCommentVNode("", true),
    (_ctx.showMore)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_6, _toDisplayString(_ctx.$t ('about.more05')), 1))
      : _createCommentVNode("", true),
    _cache[7] || (_cache[7] = _createElementVNode("line", null, null, -1)),
    (_ctx.tableView)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("table", null, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t('about.technologies')), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[3] || (_cache[3] = _createElementVNode("th", null, "№", -1)),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('about.name')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('about.version')), 1)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoStore.state.infoStore, (info) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: info.id
                }, [
                  _createElementVNode("td", _hoisted_9, _toDisplayString(info.id), 1),
                  _createElementVNode("td", _hoisted_10, [
                    _createElementVNode("a", {
                      href: info.url,
                      title: "In more detail...",
                      target: "_blank"
                    }, _toDisplayString(info.title), 9, _hoisted_11)
                  ]),
                  _createElementVNode("td", _hoisted_12, _toDisplayString(info.version), 1)
                ]))
              }), 128))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("h1", _hoisted_14, _toDisplayString(_ctx.$t('about.technologies')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoStore.state.infoStore, (info) => {
            return (_openBlock(), _createElementBlock("div", {
              key: info.id,
              class: "prj"
            }, [
              _createElementVNode("a", {
                class: "block",
                href: info.url,
                title: "In more detail...",
                target: "_blank"
              }, [
                _createElementVNode("h3", null, [
                  _createElementVNode("span", _hoisted_16, _toDisplayString(info.id) + ".", 1),
                  _cache[4] || (_cache[4] = _createTextVNode()),
                  _createElementVNode("span", null, _toDisplayString(info.title), 1),
                  _cache[5] || (_cache[5] = _createTextVNode()),
                  _createElementVNode("span", _hoisted_17, _toDisplayString(info.version ? 'v.' + info.version : info.version), 1)
                ])
              ], 8, _hoisted_15)
            ]))
          }), 128))
        ]))
  ]))
}