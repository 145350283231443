import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "inner" }
const _hoisted_2 = { class: "other" }
const _hoisted_3 = {
  key: 0,
  class: "table"
}
const _hoisted_4 = { class: "nomer" }
const _hoisted_5 = { class: "name" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "number" }
const _hoisted_8 = { class: "grade" }
const _hoisted_9 = { class: "date" }
const _hoisted_10 = {
  key: 1,
  class: "container"
}
const _hoisted_11 = ["href"]
const _hoisted_12 = { class: "slider" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Slider = _resolveComponent("Slider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, [
        _createVNode(_component_router_link, {
          class: "back",
          to: "/certificates",
          title: "Back to Certificates"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("i", { class: "fa fa-arrow-circle-left" }, null, -1)
          ])),
          _: 1
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('cert.other')) + " ", 1),
        _createElementVNode("i", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeView && _ctx.changeView(...args)))
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(['fa', _ctx.tableView ? 'fa-list' : 'fa-th'])
          }, null, 2)
        ])
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("line", null, null, -1)),
      (_ctx.tableView)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("table", null, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _cache[2] || (_cache[2] = _createElementVNode("th", null, "№", -1)),
                  _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.title')), 1),
                  _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.number')), 1),
                  _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.grade')), 1),
                  _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.date')), 1)
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherStore.state.otherStore, (sert) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: sert.id
                  }, [
                    _createElementVNode("td", _hoisted_4, _toDisplayString(sert.id), 1),
                    _createElementVNode("td", _hoisted_5, [
                      _createElementVNode("a", {
                        href: sert.image,
                        title: "In more detail...",
                        target: "_blank"
                      }, _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 9, _hoisted_6)
                    ]),
                    _createElementVNode("td", _hoisted_7, _toDisplayString(sert.regnumber), 1),
                    _createElementVNode("td", _hoisted_8, _toDisplayString(this.$i18n.locale === "uk" ? sert.grade_ua : this.$i18n.locale === "es" ? sert.grade_es : sert.grade_en), 1),
                    _createElementVNode("td", _hoisted_9, _toDisplayString(sert.examdate), 1)
                  ]))
                }), 128))
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherStore.state.otherStore, (sert) => {
              return (_openBlock(), _createElementBlock("div", {
                key: sert.id,
                class: "certificate"
              }, [
                _createElementVNode("a", {
                  class: "block",
                  href: sert.image,
                  title: "Certificate...",
                  target: "_blank"
                }, [
                  _createElementVNode("h3", null, _toDisplayString(sert.id) + ". " + _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 1),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cert.number')) + ": ", 1),
                    _createElementVNode("strong", null, _toDisplayString(sert.regnumber), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                    _createElementVNode("strong", null, _toDisplayString(this.$i18n.locale === "uk" ? sert.grade_ua : this.$i18n.locale === "es" ? sert.grade_es : sert.grade_en), 1)
                  ]),
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('cert.date')) + ": " + _toDisplayString(sert.examdate), 1)
                ], 8, _hoisted_11)
              ]))
            }), 128))
          ]))
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_Slider, { images: _ctx.sliderImages }, null, 8, ["images"])
    ])
  ]))
}