const state = {
  otherStore:
    [
      {
        id: 1,
        title_en: "Linux administration basics",
        title_ua: "Основи адміністрування Linux",
        title_es: "Fundamentos de la administración de Linux",
        regnumber: "TP03316433",
        grade_en: "950 / 1000",
        grade_ua: "950 / 1000",
        grade_es: "950 / 1000",
        examdate: "12.10.2021",
        image: "https://testprovider.com/ru/search-certificate/tp03316433",
      },
      {
        id: 2,
        title_en: "Basics of Windows administration",
        title_ua: "Основи адміністрування Windows",
        title_es: "Fundamentos de la administración de Windows",
        regnumber: "TP18727720",
        grade_en: "1000 / 1000",
        grade_ua: "1000 / 1000",
        grade_es: "1000 / 1000",
        examdate: "31.05.2023",
        image: "https://testprovider.com/ru/search-certificate/tp18727720",
      },
      {
        id: 3,
        title_en: "Golang",
        title_ua: "Golang",
        title_es: "Golang",
        regnumber: "TP09903227",
        grade_en: "974 / 1000",
        grade_ua: "974 / 1000",
        grade_es: "974 / 1000",
        examdate: "22.10.2021",
        image: "https://testprovider.com/ru/search-certificate/tp09903227",
      },
      {
        id: 4,
        title_en: "Node.js Essential",
        title_ua: "Node.js Базовий",
        title_es: "Node.js Básico",
        regnumber: "TP97104275",
        grade_en: "1000 / 1000",
        grade_ua: "1000 / 1000",
        grade_es: "1000 / 1000",
        examdate: "12.03.2024",
        image: "https://testprovider.com/ru/search-certificate/tp97104275",
      },
      {
        id: 5,
        title_en: "Git basics 2016",
        title_ua: "Основи Git 2016",
        title_es: "Fundamentos de Git de 2016",
        regnumber: "TP29316270",
        grade_en: "1000 / 1000",
        grade_ua: "1000 / 1000",
        grade_es: "1000 / 1000",
        examdate: "03.07.2021",
        image: "https://testprovider.com/ru/search-certificate/tp29316270",
      },
      {
        id: 6,
        title_en: "Git basics 2020",
        title_ua: "Основи Git 2020",
        title_es: "Fundamentos de Git de 2020",
        regnumber: "TP37589708",
        grade_en: "1000 / 1000",
        grade_ua: "1000 / 1000",
        grade_es: "1000 / 1000",
        examdate: "18.03.2021",
        image: "https://testprovider.com/ru/search-certificate/tp37589708",
      },
      {
        id: 7,
        title_en: "The basics of using Git",
        title_ua: "Основи використання Git",
        title_es: "Los fundamentos del uso de Git",
        regnumber: "TP24443333",
        grade_en: "1000 / 1000",
        grade_ua: "1000 / 1000",
        grade_es: "1000 / 1000",
        examdate: "21.06.2024",
        image: "https://testprovider.com/ru/search-certificate/tp24443333",
      },
      {
        id: 8,
        title_en: "Docker",
        title_ua: "Docker",
        title_es: "Docker",
        regnumber: "TP56750315",
        grade_en: "1000 / 1000",
        grade_ua: "1000 / 1000",
        grade_es: "1000 / 1000",
        examdate: "04.05.2023",
        image: "https://testprovider.com/ru/search-certificate/tp56750315",
      },
      {
        id: 9,
        title_en: "Digigram 2.0 for citizens",
        title_ua: "Цифрограм 2.0 для громадян",
        title_es: "Digigram 2.0 para los ciudadanos",
        regnumber: "D0000952361",
        grade_en: "63 / 90",
        grade_ua: "63 / 90",
        grade_es: "63 / 90",
        examdate: "16.10.2023",
        image: "https://osvita.diia.gov.ua/certificate-check/D0000952361",
      },
      {
        id: 10,
        title_en: "Basic software developer training",
        title_ua: "Базова підготовка розробника ПЗ",
        title_es: "Capacitación básica para desarrolladores de software",
        regnumber: "TP17348317",
        grade_en: "985 / 1000",
        grade_ua: "985 / 1000",
        grade_es: "985 / 1000",
        examdate: "26.07.2021",
        image: "https://testprovider.com/ru/search-certificate/tp17348317",
      },
      {
        id: 11,
        title_en: "Google Display Network",
        title_ua: "Медійна мережа Google",
        title_es: "Red de Display de Google",
        regnumber: "45386343",
        grade_en: "Successfully passed the exam",
        grade_ua: "Успішно складено іспит",
        grade_es: "Aprobó con éxito el examen",
        examdate: "18.02.2020",
        image: "/assets/certificates/other/11_Google_Display_Network_Ads.webp",
      },
      {
        id: 12,
        title_en: "Google Shopping Campaign",
        title_ua: "Торгові компанії Google",
        title_es: "Campaña de Google Shopping",
        regnumber: "45382771",
        grade_en: "Successfully passed the exam",
        grade_ua: "Успішно складено іспит",
        grade_es: "Aprobó con éxito el examen",
        examdate: "20.02.2020",
        image: "/assets/certificates/other/12_Google_Shopping_Campaign.webp",
      },
      {
        id: 13,
        title_en: "Google Search Campaign",
        title_ua: "Пошукові компанії Google",
        title_es: "Campaña de búsqueda de Google",
        regnumber: "45385551",
        grade_en: "Successfully passed the exam",
        grade_ua: "Успішно складено іспит",
        grade_es: "Aprobó con éxito el examen",
        examdate: "16.03.2020",
        image: "/assets/certificates/other/13_Google_Search_Campaign.webp",
      },
      {
        id: 14,
        title_en: "Google Video Campaign",
        title_ua: "Відеокомпанії Google",
        title_es: "Campaña de Google Video",
        regnumber: "45785209",
        grade_en: "Successfully passed the exam",
        grade_ua: "Успішно складено іспит",
        grade_es: "Aprobó con éxito el examen",
        examdate: "17.03.2020",
        image: "/assets/certificates/other/14_Google_Video_Campaign.webp",
      }
    ]
};
const getters = {};
const mutations = {};
const actions = {};
export default {
  state,
  getters,
  mutations,
  actions
};