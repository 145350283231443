import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "palindrome" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = { class: "result" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  style: {"color":"lightseagreen"}
}
const _hoisted_7 = {
  key: 1,
  style: {"color":"red"}
}
const _hoisted_8 = { class: "inner" }
const _hoisted_9 = { class: "certificate" }
const _hoisted_10 = {
  class: "block",
  href: "/assets/tasks/Palindrome-JS.pdf",
  title: "Download solution in pdf format...",
  target: "_blank"
}
const _hoisted_11 = { class: "certificate" }
const _hoisted_12 = {
  class: "block",
  href: "/assets/tasks/Palindrome-Vue.pdf",
  title: "Download solution in pdf format...",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createVNode(_component_router_link, {
        class: "back",
        to: "/extra/tasks",
        title: "Back to Tasks page"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("i", { class: "fa fa-arrow-circle-left" }, null, -1)
        ])),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('extra.h1m.tasks.palindrome')), 1)
    ]),
    _cache[5] || (_cache[5] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("p", null, [
      _createTextVNode(_toDisplayString(_ctx.$t('extra.tasks.palindrome.pal-des01')), 1),
      _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
      _createTextVNode(_toDisplayString(_ctx.$t('extra.tasks.palindrome.pal-des02')), 1)
    ]),
    _cache[6] || (_cache[6] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('extra.tasks.palindrome.pal-des03')), 1),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputText) = $event)),
      onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.checkPalindrome && _ctx.checkPalindrome(...args)), ["prevent"]), ["enter"])),
      placeholder: _ctx.$t('extra.tasks.palindrome.pal-des07')
    }, null, 40, _hoisted_3), [
      [_vModelText, _ctx.inputText]
    ]),
    _createElementVNode("h3", _hoisted_4, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('extra.tasks.palindrome.pal-des04')), 1),
      _cache[4] || (_cache[4] = _createTextVNode()),
      (_ctx.inputText.length > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
            (_ctx.isPalindrome)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('extra.tasks.palindrome.pal-des05')), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('extra.tasks.palindrome.pal-des06')), 1))
          ]))
        : _createCommentVNode("", true)
    ]),
    _cache[7] || (_cache[7] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("a", _hoisted_10, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('extra.tasks.link-js')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("a", _hoisted_12, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('extra.tasks.link-vue')), 1)
        ])
      ])
    ])
  ]))
}