import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cube" }
const _hoisted_2 = { class: "inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ToggleFullScreen = _resolveComponent("ToggleFullScreen")!
  const _component_CubeJS = _resolveComponent("CubeJS")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createVNode(_component_router_link, {
        class: "back-to-menu",
        to: "/extra/graphics",
        title: "Back to 3D Graphics page"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("i", { class: "fa fa-arrow-circle-left" }, null, -1)
        ])),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('extra.graphics.cubejs')) + " ", 1),
      _createVNode(_component_ToggleFullScreen),
      _cache[2] || (_cache[2] = _createTextVNode()),
      _withDirectives(_createElementVNode("input", {
        type: "range",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.scale) = $event)),
        min: "0.5",
        max: "2",
        step: "0.1"
      }, null, 512), [
        [_vModelText, _ctx.scale]
      ])
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CubeJS, { scale: _ctx.scale }, null, 8, ["scale"])
    ])
  ]))
}