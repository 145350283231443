const state = {
  projectStore:
    [
      {
        id: 1,
        title_en: "My Curriculum VITAE",
        title_ua: "Моє Резюме",
        title_es: "Mi Currículum VITAE",
        start_date_en: "March 2022",
        start_date_ua: "Березень 2022",
        start_date_es: "Marzo de 2022",
        end_date_en: "April 2022",
        end_date_ua: "Квітень 2022",
        end_date_es: "Abril de 2022",
        description_en: "This modern and adaptive Landing Page succinctly and visually presents my professional information. The site is fully responsive and works seamlessly on devices of any size.",
        description_ua: "Це сучасний і адаптивний Landing Page, який стисло й наочно представляє мою професійну інформацію. Сайт повністю адаптивний і підходить для перегляду на пристроях будь-якого розміру.",
        description_es: "Esta moderna y adaptativa Landing Page presenta de forma concisa y visual mi información profesional. El sitio es completamente adaptable y funciona perfectamente en dispositivos de cualquier tamaño.",
        type_en: "Landing page",
        type_ua: "Цільова сторінка",
        type_es: "Página de destino",
        techno: "HTML5, CSS3, Grid CSS, FlexBox CSS, JavaScript, Markdown, Git, GitHub, GitHub Pages, Google Search Console, Google Analytics, Open Graph protocol, FavIcon, Sitemap.xml, Robots.txt",
        sourcelink: "https://github.com/Zorger27/zorger27.github.io",
        demolink: "https://zorger27.github.io",
        youtubelink: ""
      },
      {
        id: 2,
        title_en: "Calculator",
        title_ua: "Калькулятор",
        title_es: "Calculadora",
        start_date_en: "April 2022",
        start_date_ua: "Квітень 2022",
        start_date_es: "Abril de 2022",
        end_date_en: "April 2022",
        end_date_ua: "Квітень 2022",
        end_date_es: "Abril de 2022",
        description_en: "✨ A functional and user-friendly application, implemented with vanilla JavaScript and web development standards, allows you to perform basic arithmetic operations. 🛠️ The calculator can be used as a standalone application or integrated into existing financial systems. 💼💡",
        description_ua: "✨ Функціональний та зручний додаток, реалізований на ванільному JavaScript і стандартах веброзробки, дозволяє виконувати основні арифметичні операції. 🛠️ Калькулятор можна використовувати як окремий додаток або інтегрувати в існуючі фінансові системи. 💼💡",
        description_es: "✨ Una aplicación funcional y fácil de usar, implementada con JavaScript puro y estándares de desarrollo web, permite realizar operaciones aritméticas básicas. 🛠️ La calculadora se puede utilizar como una aplicación independiente o integrarse en sistemas financieros existentes. 💼💡",
        type_en: "WEB-application",
        type_ua: "WEB-додаток",
        type_es: "Aplicación Web",
        techno: "HTML5, CSS3, JavaScript, Markdown, Git, GitHub",
        sourcelink: "https://github.com/Zorger27/calculator",
        demolink: "https://zorin.expert/extra/calculator",
        youtubelink: ""
      },
      {
        id: 3,
        title_en: "Spinning cube",
        title_ua: "Куб, що обертається",
        title_es: "El cubo giratorio",
        start_date_en: "May 2022",
        start_date_ua: "Травень 2022",
        start_date_es: "Mayo de 2022",
        end_date_en: "May 2022",
        end_date_ua: "Травень 2022",
        end_date_es: "Mayo de 2022",
        description_en: "🌀 A cube with semi-transparent colorful faces spins in unpredictable directions, creating a dynamic movement effect right in the browser. 🌐 The code is easy to modify, extend, and adapt to other platforms, opening up possibilities for experiments and new ideas. 💡💻",
        description_ua: "🌀 Куб з напівпрозорими різнокольоровими гранями обертається в непередбачуваних напрямках, створюючи ефект динамічного руху прямо в браузері. 🌐 Код легко модифікується, розширюється та адаптується для інших платформ, відкриваючи можливості для експериментів та нових ідей. 💡💻",
        description_es: "🌀 Un cubo con caras semitransparentes y de colores gira en direcciones impredecibles, creando un efecto de movimiento dinámico directamente en el navegador. 🌐 El código es fácil de modificar, ampliar y adaptar a otras plataformas, abriendo posibilidades para experimentos y nuevas ideas. 💡💻",
        type_en: "WEB-application",
        type_ua: "WEB-додаток",
        type_es: "Aplicación Web",
        techno: "HTML5, CSS3, FlexBox CSS, Markdown, Git, GitHub",
        sourcelink: "https://github.com/Zorger27/Spinning-cube",
        demolink: "https://zorin.expert/extra/graphics/cube",
        youtubelink: ""
      },
      {
        id: 4,
        title_en: "Contact Form",
        title_ua: "Контактна форма",
        title_es: "Formulario de contacto",
        start_date_en: "May 2022",
        start_date_ua: "Травень 2022",
        start_date_es: "Mayo de 2022",
        end_date_en: "May 2022",
        end_date_ua: "Травень 2022",
        end_date_es: "Mayo de 2022",
        description_en: "📬 A user-friendly web application for collecting user information and sending messages to the administrator. ✍️ The form features smart data validation, ensuring the accuracy of submitted information.",
        description_ua: "📬 Зручний вебдодаток для збору інформації про користувачів та передачі повідомлень адміністратору. ✍️ Форма з інтелектуальною валідацією даних гарантує коректність відправленої інформації.",
        description_es: "📬 Una aplicación web fácil de usar para recopilar información de los usuarios y enviar mensajes al administrador. ✍️ El formulario incluye validación inteligente de datos, garantizando la precisión de la información enviada.",
        type_en: "WEB-application",
        type_ua: "WEB-додаток",
        type_es: "Aplicación Web",
        techno: "HTML5, CSS3, JavaScript, Markdown, Git, GitHub",
        sourcelink: "https://github.com/Zorger27/Contact-Form",
        demolink: "",
        youtubelink: ""
      },
      {
        id: 5,
        title_en: "To-Do list",
        title_ua: "Список справ",
        title_es: "Lista de tareas",
        start_date_en: "May 2022",
        start_date_ua: "Травень 2022",
        start_date_es: "Mayo de 2022",
        end_date_en: "May 2022",
        end_date_ua: "Травень 2022",
        end_date_es: "Mayo de 2022",
        description_en: "📝 A web application for convenient task management, implemented using modern web technologies. ✔️ Allows you to add, edit, delete tasks, mark them as completed (with a visual strikethrough effect), and clear the list entirely.",
        description_ua: "📝 Вебдодаток для зручного управління завданнями, реалізований із використанням сучасних вебтехнологій. ✔️ Дозволяє додавати, редагувати, видаляти завдання, відзначати виконання (з візуальним ефектом перекреслення) та очищати список повністю.",
        description_es: "📝 Una aplicación web para una gestión de tareas conveniente, implementada con tecnologías web modernas. ✔️ Permite agregar, editar, eliminar tareas, marcarlas como completadas (con un efecto visual de tachado) y limpiar la lista por completo.",
        type_en: "WEB-application",
        type_ua: "WEB-додаток",
        type_es: "Aplicación Web",
        techno: "HTML5, CSS3, FlexBox CSS, JavaScript, Markdown, Git, GitHub",
        sourcelink: "https://github.com/Zorger27/To-Do-list",
        demolink: "https://zorin.expert/extra/todo",
        youtubelink: ""
      },
      {
        id: 6,
        title_en: "Hamburger Menu",
        title_ua: "Гамбургер меню",
        title_es: "Menú Hamburguesa",
        start_date_en: "May 2022",
        start_date_ua: "Травень 2022",
        start_date_es: "Mayo de 2022",
        end_date_en: "May 2022",
        end_date_ua: "Травень 2022",
        end_date_es: "Mayo de 2022",
        description_en: "🍔 An elegant web application with an animated menu! ✨ When you click on the \"hamburger\", the menu opens with a smooth transition, creating a stylish visual effect and enhancing the user experience.",
        description_ua: "🍔 Елегантний вебдодаток із анімованим меню! ✨ При натисканні на \"гамбургер\", меню відкривається з плавним переходом, створюючи стильний візуальний ефект і покращуючи користувацький досвід.",
        description_es: "🍔 Una aplicación web elegante con un menú animado. ✨ Al hacer clic en el \"hamburguesa\", el menú se abre con una transición suave, creando un efecto visual elegante y mejorando la experiencia del usuario.",
        type_en: "WEB-application",
        type_ua: "WEB-додаток",
        type_es: "Aplicación Web",
        techno: "HTML5, CSS3, JavaScript, Markdown, Git, GitHub",
        sourcelink: "https://github.com/Zorger27/Hamburger-menu",
        demolink: "",
        youtubelink: ""
      },
      {
        id: 7,
        title_en: "Scroll Snap features",
        title_ua: "Особливості Scroll Snap",
        title_es: "Características de Scroll Snap",
        start_date_en: "May 2022",
        start_date_ua: "Травень 2022",
        start_date_es: "Mayo de 2022",
        end_date_en: "May 2022",
        end_date_ua: "Травень 2022",
        end_date_es: "Mayo de 2022",
        description_en: "Implemented with pure CSS3, horizontal scrolling provides smooth animations and high performance without using JavaScript. ✨ Easily customizable and adaptable to any design, making it the perfect choice for creating modern web applications and websites. 💡",
        description_ua: "Реалізований на чистому CSS3, горизонтальний скролінг забезпечує плавні анімації та високу продуктивність без використання JavaScript. ✨ Легко кастомізується та адаптується під будь-який дизайн, що робить його ідеальним вибором для створення сучасних веб-додатків та сайтів. 💡",
        description_es: "Implementado con CSS3 puro, el desplazamiento horizontal proporciona animaciones suaves y un alto rendimiento sin usar JavaScript. ✨ Fácilmente personalizable y adaptable a cualquier diseño, lo que lo convierte en la elección perfecta para crear aplicaciones web y sitios web modernos. 💡",
        type_en: "WEB-application",
        type_ua: "WEB-додаток",
        type_es: "Aplicación Web",
        techno: "HTML5, CSS3, FlexBox CSS, Markdown, Git, GitHub",
        sourcelink: "https://github.com/Zorger27/Scroll-Snap",
        demolink: "",
        youtubelink: ""
      },
      {
        id: 8,
        title_en: "Memory game",
        title_ua: "Гра \"Пам'ять\"",
        title_es: "Juego de Memoria",
        start_date_en: "May 2022",
        start_date_ua: "Травень 2022",
        start_date_es: "Mayo de 2022",
        end_date_en: "May 2022",
        end_date_ua: "Травень 2022",
        end_date_es: "Mayo de 2022",
        description_en: "🔥 An exciting web application that tests your attention and memory. 🎮 The user flips cards to find pairs with matching images. 💡 An interactive interface and cross-platform adaptability make this project perfect for enhancing cognitive skills and entertainment! 🚀",
        description_ua: "🔥 Захопливий веб-додаток, що перевіряє вашу уважність і пам’ять. 🎮 Користувач відкриває карти, щоб знайти пари з однаковими зображеннями. 💡 Інтерактивний інтерфейс і кроссплатформенна адаптація роблять цей проєкт ідеальним для покращення когнітивних навичок і розваг! 🚀",
        description_es: "🔥 Una emocionante aplicación web que pone a prueba tu atención y memoria. 🎮 El usuario voltea cartas para encontrar pares con imágenes iguales. 💡 Una interfaz interactiva y adaptabilidad multiplataforma hacen de este proyecto una herramienta ideal para mejorar habilidades cognitivas y divertirse al máximo. 🚀",
        type_en: "WEB-application",
        type_ua: "WEB-додаток",
        type_es: "Aplicación Web",
        techno: "HTML5, CSS3, FlexBox CSS, JavaScript, Markdown, Git, GitHub",
        sourcelink: "https://github.com/Zorger27/Memory-game",
        demolink: "https://zorin.expert/extra/games/memory",
        youtubelink: ""
      },
      {
        id: 9,
        title_en: "Typical JavaScript interview tasks",
        title_ua: "Типові завдання на співбесіді з JavaScript",
        title_es: "Tareas típicas de entrevistas en JavaScript",
        start_date_en: "June 2022",
        start_date_ua: "Червень 2022",
        start_date_es: "Junio de 2022",
        end_date_en: "June 2022",
        end_date_ua: "Червень 2022",
        end_date_es: "Junio de 2022",
        description_en: "🌟 An interactive web application that allows users to explore and test solutions to popular JavaScript interview questions. 🧩 Users can see and verify how tasks like Palindrome, FizzBuzz, Anagram, Find Vowels, and Fibonacci are solved through intuitive forms with instant feedback.",
        description_ua: "🌟 Інтерактивний веб-додаток, що дозволяє вивчати та тестувати рішення популярних задач зі співбесід по JavaScript. 🧩 Користувач може побачити та перевірити, як вирішуються задачі Palindrome, FizzBuzz, Anagram, Find Vowels і Fibonacci, через зручні форми з миттєвим зворотним зв’язком.",
        description_es: "🌟 Una aplicación web interactiva que permite explorar y probar soluciones a problemas populares de entrevistas en JavaScript. 🧩 Los usuarios pueden ver y comprobar cómo se resuelven desafíos como Palindrome, FizzBuzz, Anagram, Find Vowels y Fibonacci a través de formularios intuitivos con retroalimentación instantánea.",
        type_en: "WEB-application",
        type_ua: "WEB-додаток",
        type_es: "Aplicación Web",
        techno: "HTML5, CSS3, FlexBox CSS, JavaScript, Markdown, Git, GitHub",
        sourcelink: "https://github.com/Zorger27/Typical-JS-tasks",
        demolink: "https://zorin.expert/extra/tasks",
        youtubelink: ""
      },
      {
        id: 10,
        title_en: "Website template for a blogger",
        title_ua: "Шаблон сайту для блогера",
        title_es: "Plantilla de sitio web para un blogger",
        start_date_en: "June 2022",
        start_date_ua: "Червень 2022",
        start_date_es: "Junio de 2022",
        end_date_en: "July 2022",
        end_date_ua: "Липень 2022",
        end_date_es: "Julio de 2022",
        description_en: "Website template for a blogger.",
        description_ua: "Шаблон сайту для блогера.",
        description_es: "Plantilla de sitio web para un blogger.",
        type_en: "Single page application",
        type_ua: "Односторінковий додаток",
        type_es: "Aplicación de una sola página",
        techno: "Vue.js, VueX, Webpack, HTML5, SCSS, FlexBox CSS, TypeScript, JavaScript, Markdown, Git, GitHub",
        sourcelink: "https://github.com/Zorger27/Blog-site",
        demolink: "",
        youtubelink: ""
      },
      {
        id: 11,
        title_en: "Dropdown Menu",
        title_ua: "Випадаюче меню",
        title_es: "Menú desplegable",
        start_date_en: "August 2022",
        start_date_ua: "Серпень 2022",
        start_date_es: "Agosto de 2022",
        end_date_en: "August 2022",
        end_date_ua: "Серпень 2022",
        end_date_es: "Agosto de 2022",
        description_en: "Very simple (only CSS) but wonderful working Dropdown Menu.",
        description_ua: "Дуже просте (тільки CSS), але чудово працююче Dropdown меню.",
        description_es: "Menú desplegable muy simple (solo CSS) pero que funciona de maravilla.",
        type_en: "WEB-application",
        type_ua: "WEB-додаток",
        type_es: "Aplicación Web",
        techno: "HTML5, CSS3, Markdown, Git, GitHub",
        sourcelink: "https://github.com/Zorger27/Dropdown-Menu",
        demolink: "",
        youtubelink: ""
      },
      {
        id: 12,
        title_en: "Sidebar Menu",
        title_ua: "Меню на бічній панелі",
        title_es: "Menú de la barra lateral",
        start_date_en: "August 2022",
        start_date_ua: "Серпень 2022",
        start_date_es: "Agosto de 2022",
        end_date_en: "August 2022",
        end_date_ua: "Серпень 2022",
        end_date_es: "Agosto de 2022",
        description_en: "Very convenient Responsive Sidebar Menu.",
        description_ua: "Дуже зручне адаптивне бічне меню.",
        description_es: "Menú de barra lateral responsivo muy conveniente.",
        type_en: "WEB-application",
        type_ua: "WEB-додаток",
        type_es: "Aplicación Web",
        techno: "HTML5, CSS3, Boxicons CSS, JavaScript, Markdown, Git, GitHub",
        sourcelink: "https://github.com/Zorger27/Sidebar-Menu",
        demolink: "",
        youtubelink: ""
      },
      {
        id: 13,
        title_en: "My favorite buttons",
        title_ua: "Мої улюблені кнопки",
        title_es: "Mis botones favoritos",
        start_date_en: "August 2022",
        start_date_ua: "Серпень 2022",
        start_date_es: "Agosto de 2022",
        end_date_en: "August 2022",
        end_date_ua: "Серпень 2022",
        end_date_es: "Agosto de 2022",
        description_en: "Nicely designed buttons (my favorites).",
        description_ua: "Красиво оформлені кнопки (мої улюблені).",
        description_es: "Botones muy bien diseñados (mi favorito).",
        type_en: "WEB-application",
        type_ua: "WEB-додаток",
        type_es: "Aplicación Web",
        techno: "HTML5, CSS3, FlexBox CSS, Markdown, Git, GitHub",
        sourcelink: "https://github.com/Zorger27/Fav-buttons",
        demolink: "",
        youtubelink: ""
      },
      {
        id: 14,
        title_en: "My Portfolio",
        title_ua: "Моє Портфоліо",
        title_es: "Mi Portafolio",
        start_date_en: "February 2023",
        start_date_ua: "Лютий 2023",
        start_date_es: "Febrero de 2023",
        end_date_en: "June 2023",
        end_date_ua: "Червень 2023",
        end_date_es: "Junio de 2023",
        description_en: "This is a modern single-page application (SPA) that presents my professional information in a detailed and visual way. The site covers everything that matters: Detailed resume (projects with descriptions, diplomas, certificates, work experience, skills, and education) and Extra section (examples of completed works: To-do list, Weather, Cryptocurrencies, 3D graphics and much more).",
        description_ua: "Це сучасний односторінковий додаток (SPA), який детально та наочно представляє мою професійну інформацію. Сайт охоплює все, що важливо: Детальне резюме (проекти з описами, дипломи, сертифікати, досвід роботи, навички та освіта) і Екстра-розділ (приклади готових робіт: список справ, погода, криптовалюти, 3D графіка та багато іншого). 🌟",
        description_es: "Esta es una aplicación moderna de una sola página (SPA) que presenta mi información profesional de manera detallada y visual. El sitio cubre todo lo que es importante: Currículum detallado (proyectos con descripciones, diplomas, certificados, experiencia laboral, habilidades y educación) y Sección extra (ejemplos de trabajos realizados: Lista de tareas, El Tiempo, Criptomonedas, Gráficos 3D y mucho más). 🌟",
        type_en: "Single page application",
        type_ua: "Односторінковий додаток",
        type_es: "Aplicación de una sola página",
        techno: "Three.js, Vue.js, VueX, Webpack, HTML5, SCSS, Grid CSS, FlexBox CSS, TypeScript, JavaScript, Markdown, Git, GitHub, Google Search Console, Google Analytics, Open Graph protocol, FavIcon, Sitemap.xml, Robots.txt",
        sourcelink: "https://github.com/Zorger27/CV-Vue",
        demolink: "https://Zorin.Expert",
        youtubelink: "https://youtu.be/fwBFfrhPJyM"
      },
      {
        id: 15,
        title_en: "Vue.js template with TypeScript",
        title_ua: "Шаблон Vue.js + TypeScript",
        title_es: "Plantilla Vue.js con TypeScript",
        start_date_en: "December 2023",
        start_date_ua: "Грудень 2023",
        start_date_es: "Diciembre de 2023",
        end_date_en: "January 2024",
        end_date_ua: "Січень 2024",
        end_date_es: "Enero de 2024",
        description_en: "This is the Starter Development Template on Vue.js + TypeScript with internationalization, routers and store. Prepared for connection to Google Analytics and Google Search Console. The template is customized and optimized for PWA (Progressive Web App) technology.",
        description_ua: "Це початковий шаблон для розробки на Vue.js + TypeScript з інтернаціоналізацією, маршрутизаторами та сховищем. Підготовлений для підключення до Google Analytics і Google Search Console. Шаблон налаштований і оптимізований для технології PWA (Progressive Web App).",
        description_es: "Esta es la plantilla de desarrollo inicial en Vue.js + TypeScript con internacionalización, enrutadores y almacenamiento. Preparado para conexión a Google Analytics y Google Search Console. La plantilla está personalizada y optimizada para la tecnología PWA (Progressive Web App).",
        type_en: "Single page application",
        type_ua: "Односторінковий додаток",
        type_es: "Aplicación de una sola página",
        techno: "Vue.js, VueX, Webpack, HTML5, SCSS, FlexBox CSS, TypeScript, JavaScript, Markdown, Git, GitHub, Google Search Console, Google Analytics, Open Graph protocol, FavIcon, Sitemap.xml, Robots.txt",
        sourcelink: "https://github.com/Zorger27/Vue-TS-Template",
        demolink: "https://vue-template-ts.vercel.app",
        youtubelink: ""
      },
      {
        id: 16,
        title_en: "Rotating 3D cube",
        title_ua: "3D куб, що обертається",
        title_es: "Cubo 3D giratorio",
        start_date_en: "December 2023",
        start_date_ua: "Грудень 2023",
        start_date_es: "Diciembre de 2023",
        end_date_en: "January 2024",
        end_date_ua: "Січень 2024",
        end_date_es: "Enero de 2024",
        description_en: "This project provides the user with the opportunity to interact interactively with a 3D model. In this case with a cube. The model can be zoomed in/out, stopped rotating, scrolled in your direction, etc.",
        description_ua: "Цей проект надає користувачеві можливість інтерактивно взаємодіяти з 3D-моделлю. В даному випадку з кубиком. Модель можна збільшувати/зменшувати, зупиняти обертання, прокручувати у своєму напрямку тощо.",
        description_es: "Este proyecto brinda al usuario la oportunidad de interactuar interactivamente con un modelo 3D. En este caso con un cubo. El modelo se puede acercar o alejar, dejar de girar, desplazar en su dirección, etc.",
        type_en: "Single page application",
        type_ua: "Односторінковий додаток",
        type_es: "Aplicación de una sola página",
        techno: "Three.js, Orbit controls, Vue.js, VueX, Webpack, HTML5, SCSS, FlexBox CSS, TypeScript, JavaScript, Markdown, Git, GitHub, Open Graph protocol, FavIcon, Sitemap.xml, Robots.txt",
        sourcelink: "https://github.com/Zorger27/Vue-Threejs-1",
        demolink: "https://vue-threejs-1.vercel.app",
        youtubelink: ""
      },
      {
        id: 17,
        title_en: "Vue.js template with JavaScript",
        title_ua: "Шаблон Vue.js + JavaScript",
        title_es: "Plantilla Vue.js con JavaScript",
        start_date_en: "December 2023",
        start_date_ua: "Грудень 2023",
        start_date_es: "Diciembre de 2023",
        end_date_en: "January 2024",
        end_date_ua: "Січень 2024",
        end_date_es: "Enero de 2024",
        description_en: "This is the Starter Development Template on Vue.js + JavaScript with internationalization, routers and store. Prepared for connection to Google Analytics and Google Search Console. The template is customized and optimized for PWA (Progressive Web App) technology.",
        description_ua: "Це початковий шаблон для розробки на Vue.js + JavaScript з інтернаціоналізацією, маршрутизаторами та сховищем. Підготовлений для підключення до Google Analytics і Google Search Console. Шаблон налаштований і оптимізований для технології PWA (Progressive Web App).",
        description_es: "Esta es la plantilla de desarrollo inicial en Vue.js + JavaScript con internacionalización, enrutadores y almacenamiento. Preparado para conexión a Google Analytics y Google Search Console. La plantilla está personalizada y optimizada para la tecnología PWA (Progressive Web App).",
        type_en: "Single page application",
        type_ua: "Односторінковий додаток",
        type_es: "Aplicación de una sola página",
        techno: "Vue.js, VueX, Webpack, HTML5, SCSS, FlexBox CSS, JavaScript, Markdown, Git, GitHub, Google Search Console, Google Analytics, Open Graph protocol, FavIcon, Sitemap.xml, Robots.txt",
        sourcelink: "https://github.com/Zorger27/Vue-JS-Template",
        demolink: "https://vue-template-js.vercel.app",
        youtubelink: ""
      },
      {
        id: 18,
        title_en: "Vue.js & Three.js template",
        title_ua: "Шаблон Vue.js + Three.js",
        title_es: "Plantilla Vue.js y Three.js",
        start_date_en: "January 2024",
        start_date_ua: "Січень 2024",
        start_date_es: "Enero de 2024",
        end_date_en: "January 2024",
        end_date_ua: "Січень 2024",
        end_date_es: "Enero de 2024",
        description_en: "This is the Starter Development Template on Vue.js + Three.js with internationalization, routers and store. Prepared for connection to Google Analytics and Google Search Console. The template is customized and optimized for PWA (Progressive Web App) technology.",
        description_ua: "Це початковий шаблон для розробки на Vue.js + Three.js з інтернаціоналізацією, маршрутизаторами та сховищем. Підготовлений для підключення до Google Analytics і Google Search Console. Шаблон налаштований і оптимізований для технології PWA (Progressive Web App).",
        description_es: "Esta es la plantilla de desarrollo inicial en Vue.js + Three.js con internacionalización, enrutadores y almacenamiento. Preparado para conexión a Google Analytics y Google Search Console. La plantilla está personalizada y optimizada para la tecnología PWA (Progressive Web App).",
        type_en: "Single page application",
        type_ua: "Односторінковий додаток",
        type_es: "Aplicación de una sola página",
        techno: "Three.js, Orbit controls, Vue.js, VueX, Webpack, HTML5, SCSS, FlexBox CSS, JavaScript, Markdown, Git, GitHub, Google Search Console, Google Analytics, Open Graph protocol, FavIcon, Sitemap.xml, Robots.txt",
        sourcelink: "https://github.com/Zorger27/Vue-Threejs-Template",
        demolink: "https://vue-threejs-template.vercel.app",
        youtubelink: ""
      },
      {
        id: 19,
        title_en: "CuboVerse",
        title_ua: "CuboVerse",
        title_es: "CuboVerse",
        start_date_en: "January 2024",
        start_date_ua: "Січень 2024",
        start_date_es: "Enero de 2024",
        end_date_en: "January 2024",
        end_date_ua: "Січень 2024",
        end_date_es: "Enero de 2024",
        description_en: "CuboVerse (a combination of the Spanish word \"cubo\" (meaning \"cube\") and the English word \"universe\" (meaning \"universe\"), i.e., \"the universe of cubes\") is an interactive 3D platform that allows users to observe and control various cube models in a virtual space.",
        description_ua: "CuboVerse (поєднання слів \"cubo\" (іспанською — куб) і \"universe\" (англійською — всесвіт), тобто \"всесвіт кубів\") — це інтерактивна 3D-платформа, що дозволяє користувачам спостерігати та керувати різноманітними моделями кубів у віртуальному просторі.",
        description_es: "CuboVerse (una combinación de la palabra \"cubo\" (en español, \"cubo\") y \"universe\" (en inglés, \"universo\"), es decir, \"el universo de cubos\") es una plataforma interactiva en 3D que permite a los usuarios observar y controlar diversos modelos de cubos en un espacio virtual.",
        type_en: "Single page application",
        type_ua: "Односторінковий додаток",
        type_es: "Aplicación de una sola página",
        techno: "Three.js, Orbit controls, Vue.js, VueX, Webpack, HTML5, SCSS, FlexBox CSS, JavaScript, Markdown, Git, GitHub, Google Search Console, Google Analytics, Open Graph protocol, FavIcon, Sitemap.xml, Robots.txt",
        sourcelink: "https://github.com/Zorger27/Vue-Threejs-2",
        demolink: "https://vue-threejs-2.vercel.app",
        youtubelink: "https://youtu.be/LJH-S5KKerw"
      },
      {
        id: 20,
        title_en: "Exchange rates",
        title_ua: "Курси валют",
        title_es: "Tipos de cambio",
        start_date_en: "March 2024",
        start_date_ua: "Березень 2024",
        start_date_es: "Marzo de 2024",
        end_date_en: "March 2024",
        end_date_ua: "Березень 2024",
        end_date_es: "Marzo de 2024",
        description_en: "This modern web application is designed to provide a convenient and visual display of current foreign exchange rates. The app retrieves real-time data from the National Bank of Ukraine API, displaying exchange rates relative to the Ukrainian hryvnia (UAH) in innovative and interactive formats.",
        description_ua: "Це сучасний вебдодаток, створений для зручного та наочного відображення актуальних курсів іноземних валют. Додаток отримує дані в режимі реального часу через API Національного банку України, відображаючи курси валют відносно української гривні (UAH) в інноваційних та інтерактивних форматах.",
        description_es: "Esta moderna aplicación web está diseñada para proporcionar una visualización cómoda y clara de los tipos de cambio de divisas actuales. La aplicación obtiene datos en tiempo real del API del Banco Nacional de Ucrania, mostrando los tipos de cambio en relación con la grivna ucraniana (UAH) en formatos innovadores e interactivos.",
        type_en: "Single page application",
        type_ua: "Односторінковий додаток",
        type_es: "Aplicación de una sola página",
        techno: "Three.js, Vue.js, VueX, Webpack, HTML5, SCSS, FlexBox CSS, TypeScript, JavaScript, Markdown, Git, GitHub, Google Search Console, Google Analytics, Open Graph protocol, FavIcon, Sitemap.xml, Robots.txt",
        sourcelink: "https://github.com/Zorger27/Currencies",
        demolink: "https://currencies-zeta.vercel.app",
        youtubelink: "https://youtu.be/5ofx4IjOSuw"
      },
      {
        id: 21,
        title_en: "Cryptocurrencies",
        title_ua: "Криптовалюти",
        title_es: "Criptomonedas",
        start_date_en: "March 2024",
        start_date_ua: "Березень 2024",
        start_date_es: "Marzo de 2024",
        end_date_en: "March 2024",
        end_date_ua: "Березень 2024",
        end_date_es: "Marzo de 2024",
        description_en: "This web application transforms cryptocurrency rate monitoring into an engaging experience. Using the CoinGecko API, it displays up-to-date cryptocurrency rates in USD, offering users flexibility and interactivity. Technologies and data come together, enabling you to easily track the world of cryptocurrencies. 🌟",
        description_ua: "Цей вебдодаток перетворює моніторинг курсів криптовалют на захопливий досвід. Завдяки API CoinGecko він відображає актуальні курси криптовалют у доларах США (USD), пропонуючи користувачам гнучкість та інтерактивність. Технології та дані поєднуються в єдине ціле, щоб ви могли легко відстежувати світ криптовалют. 🌟",
        description_es: "Esta aplicación web transforma el monitoreo de tasas de criptomonedas en una experiencia fascinante. Con la API de CoinGecko, muestra las tasas actualizadas de criptomonedas en dólares estadounidenses (USD), ofreciendo a los usuarios flexibilidad e interactividad. Las tecnologías y los datos se unen para que puedas seguir fácilmente el mundo de las criptomonedas. 🌟",
        type_en: "Single page application",
        type_ua: "Односторінковий додаток",
        type_es: "Aplicación de una sola página",
        techno: "Three.js, Vue.js, VueX, Webpack, HTML5, SCSS, FlexBox CSS, TypeScript, JavaScript, Markdown, Git, GitHub, Google Search Console, Google Analytics, Open Graph protocol, FavIcon, Sitemap.xml, Robots.txt",
        sourcelink: "https://github.com/Zorger27/Cryptocurrencies",
        demolink: "https://cryptocurrencies-gold.vercel.app",
        youtubelink: "https://youtu.be/-kGUesQdUs8"
      },
      {
        id: 22,
        title_en: "Weather forecast",
        title_ua: "Прогноз погоди",
        title_es: "El Tiempo",
        start_date_en: "March 2024",
        start_date_ua: "Березень 2024",
        start_date_es: "Marzo de 2024",
        end_date_en: "April 2024",
        end_date_ua: "Квітень 2024",
        end_date_es: "Abril de 2024",
        description_en: "Custom-built weather forecasting application that delivers real-time data from OpenWeather for any city worldwide. Whether you're a tech enthusiast or just curious about the weather, this app has something for everyone! Key features of application: flexible city selection and multiple display formats.",
        description_ua: "Унікальний веб-додаток для прогнозу погоди, який використовує актуальні дані в реальному часі від OpenWeather для будь-якого міста світу. Незалежно від того, чи ви захоплюєтеся технологіями, чи просто цікавитеся погодою, цей додаток має щось цікаве для кожного! Ключові особливості програми: гнучкий вибір міста та різні формати відображення.",
        description_es: "Una aplicación web personalizada para pronósticos meteorológicos, que proporciona datos en tiempo real de OpenWeather para cualquier ciudad del mundo. Ya sea que seas un entusiasta de la tecnología o simplemente tengas curiosidad por el clima, ¡esta app tiene algo para todos! Características clave de la aplicación: selección flexible de ciudades y múltiples formatos de visualización.",
        type_en: "Single page application",
        type_ua: "Односторінковий додаток",
        type_es: "Aplicación de una sola página",
        techno: "Three.js, Vue.js, VueX, Webpack, HTML5, SCSS, FlexBox CSS, TypeScript, JavaScript, Markdown, Git, GitHub, Google Search Console, Google Analytics, Open Graph protocol, FavIcon, Sitemap.xml, Robots.txt",
        sourcelink: "https://github.com/Zorger27/Weather",
        demolink: "https://weather-zorger.vercel.app",
        youtubelink: "https://youtu.be/cg_zJ77DHhI"
      },
      {
        id: 23,
        title_en: "3D Configurators",
        title_ua: "3D-конфігуратори",
        title_es: "Configuradores 3D",
        start_date_en: "September 2024",
        start_date_ua: "Вересень 2024",
        start_date_es: "Septiembre de 2024",
        end_date_en: "September 2024",
        end_date_ua: "Вересень 2024",
        end_date_es: "Septiembre de 2024",
        description_en: "This is an innovative online technology that enables users to interact with 3D product models in real-time. It’s a powerful tool for businesses that allows your customers to customize products to their preferences — changing colors, textures, and other parameters and seeing the results instantly.",
        description_ua: "Це інноваційна онлайн-технологія, яка дозволяє користувачам взаємодіяти з 3D-моделями продуктів у реальному часі. Це потужний інструмент для бізнесу, який дає вашим клієнтам змогу налаштовувати продукт відповідно до своїх вподобань — змінювати колір, текстуру та інші параметри й одразу бачити результат.",
        description_es: "Esto es una tecnología en línea innovadora que permite a los usuarios interactuar con modelos de productos en 3D en tiempo real. Es una herramienta poderosa para empresas que permite a los clientes personalizar productos según sus preferencias: cambiar colores, texturas y otros parámetros, y ver los resultados al instante.",
        type_en: "Single page application",
        type_ua: "Односторінковий додаток",
        type_es: "Aplicación de una sola página",
        techno: "Three.js, Orbit controls, Vue.js, VueX, Webpack, HTML5, SCSS, FlexBox CSS, JavaScript, Markdown, Git, GitHub, Google Search Console, Google Analytics, Open Graph protocol, FavIcon, Sitemap.xml, Robots.txt",
        sourcelink: "https://github.com/Zorger27/3dConfigurator",
        demolink: "https://3d-configurator-max.vercel.app",
        youtubelink: "https://youtu.be/uUofz_KBYuU"
      },
    ]
};
const getters = {};
const mutations = {};
const actions = {};
export default {
  state,
  getters,
  mutations,
  actions
};