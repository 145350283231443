import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["onDblclick"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "slider-controls" }
const _hoisted_6 = { class: "slider-dots" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "slider-container",
    onTouchstart: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleTouchStart && _ctx.handleTouchStart(...args))),
    onTouchmove: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleTouchMove && _ctx.handleTouchMove(...args))),
    onTouchend: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleTouchEnd && _ctx.handleTouchEnd(...args)))
  }, [
    _createElementVNode("div", {
      class: "slider-wrapper",
      style: _normalizeStyle({
        transform: 'translateX(' + _ctx.transformValue + '%)',
        transition: _ctx.transitioning ? 'transform ' + _ctx.transitionDuration + 'ms' : 'none'
      })
    }, [
      _createElementVNode("div", {
        class: "slider-item",
        onDblclick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openInNewWindow(_ctx.images.length - 1)))
      }, [
        _createElementVNode("img", {
          src: _ctx.images[_ctx.images.length - 1],
          alt: "slider image"
        }, null, 8, _hoisted_1)
      ], 32),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "slider-item",
          key: index,
          onDblclick: ($event: any) => (_ctx.openInNewWindow(index))
        }, [
          _createElementVNode("img", {
            src: image,
            alt: "slider image"
          }, null, 8, _hoisted_3)
        ], 40, _hoisted_2))
      }), 128)),
      _createElementVNode("div", {
        class: "slider-item",
        onDblclick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openInNewWindow(0)))
      }, [
        _createElementVNode("img", {
          src: _ctx.images[0],
          alt: "slider image"
        }, null, 8, _hoisted_4)
      ], 32)
    ], 4),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        class: "left-control",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.previousSlide && _ctx.previousSlide(...args)))
      }, _cache[7] || (_cache[7] = [
        _createElementVNode("i", { class: "fa fa-arrow-alt-circle-left" }, null, -1)
      ])),
      _createElementVNode("button", {
        class: "right-control",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.nextSlide && _ctx.nextSlide(...args)))
      }, _cache[8] || (_cache[8] = [
        _createElementVNode("i", { class: "fa fa-arrow-alt-circle-right" }, null, -1)
      ]))
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index) => {
        return (_openBlock(), _createElementBlock("span", {
          class: _normalizeClass(["slider-dot", { active: index + 1 === _ctx.currentSlide }]),
          key: index,
          onClick: ($event: any) => (_ctx.goToSlide(index))
        }, null, 10, _hoisted_7))
      }), 128))
    ])
  ], 32))
}