import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "frontend" }
const _hoisted_2 = {
  key: 0,
  class: "table"
}
const _hoisted_3 = {
  class: "title",
  colspan: "5"
}
const _hoisted_4 = {
  class: "attention",
  href: "https://testprovider.com/ru/search-certificate/tp24303907d",
  title: "Diploma...",
  target: "_blank"
}
const _hoisted_5 = { class: "nomer" }
const _hoisted_6 = { class: "name" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "number" }
const _hoisted_9 = { class: "grade" }
const _hoisted_10 = { class: "date" }
const _hoisted_11 = {
  key: 1,
  class: "container"
}
const _hoisted_12 = { class: "diplom" }
const _hoisted_13 = {
  class: "attention",
  href: "https://testprovider.com/ru/search-certificate/tp24303907d",
  title: "Diploma...",
  target: "_blank"
}
const _hoisted_14 = { class: "inner" }
const _hoisted_15 = ["href"]
const _hoisted_16 = {
  key: 2,
  class: "table"
}
const _hoisted_17 = {
  class: "title",
  colspan: "5"
}
const _hoisted_18 = {
  class: "attention",
  href: "https://testprovider.com/ru/search-certificate/tp84806711d",
  title: "Diploma...",
  target: "_blank"
}
const _hoisted_19 = { class: "nomer" }
const _hoisted_20 = { class: "name" }
const _hoisted_21 = ["href"]
const _hoisted_22 = { class: "number" }
const _hoisted_23 = { class: "grade" }
const _hoisted_24 = { class: "date" }
const _hoisted_25 = {
  key: 3,
  class: "container"
}
const _hoisted_26 = { class: "diplom" }
const _hoisted_27 = {
  class: "attention",
  href: "https://testprovider.com/ru/search-certificate/tp84806711d",
  title: "Diploma...",
  target: "_blank"
}
const _hoisted_28 = { class: "inner" }
const _hoisted_29 = ["href"]
const _hoisted_30 = {
  key: 4,
  class: "table"
}
const _hoisted_31 = {
  class: "title",
  colspan: "5"
}
const _hoisted_32 = {
  class: "attention",
  href: "https://testprovider.com/ru/search-certificate/tp31003654d",
  title: "Diploma...",
  target: "_blank"
}
const _hoisted_33 = { class: "nomer" }
const _hoisted_34 = { class: "name" }
const _hoisted_35 = ["href"]
const _hoisted_36 = { class: "number" }
const _hoisted_37 = { class: "grade" }
const _hoisted_38 = { class: "date" }
const _hoisted_39 = {
  key: 5,
  class: "container"
}
const _hoisted_40 = { class: "diplom" }
const _hoisted_41 = {
  class: "attention",
  href: "https://testprovider.com/ru/search-certificate/tp31003654d",
  title: "Diploma...",
  target: "_blank"
}
const _hoisted_42 = { class: "inner" }
const _hoisted_43 = ["href"]
const _hoisted_44 = {
  key: 6,
  class: "table"
}
const _hoisted_45 = {
  class: "title",
  colspan: "5"
}
const _hoisted_46 = {
  class: "attention",
  href: "https://testprovider.com/ru/search-certificate/tp37723782d",
  title: "Diploma...",
  target: "_blank"
}
const _hoisted_47 = { class: "nomer" }
const _hoisted_48 = { class: "name" }
const _hoisted_49 = ["href"]
const _hoisted_50 = { class: "number" }
const _hoisted_51 = { class: "grade" }
const _hoisted_52 = { class: "date" }
const _hoisted_53 = {
  key: 7,
  class: "container"
}
const _hoisted_54 = { class: "diplom" }
const _hoisted_55 = {
  class: "attention",
  href: "https://testprovider.com/ru/search-certificate/tp37723782d",
  title: "Diploma...",
  target: "_blank"
}
const _hoisted_56 = { class: "inner" }
const _hoisted_57 = ["href"]
const _hoisted_58 = {
  key: 8,
  class: "table",
  style: {"margin-bottom":"1rem"}
}
const _hoisted_59 = {
  class: "title",
  colspan: "5"
}
const _hoisted_60 = {
  class: "attention",
  href: "https://testprovider.com/ru/search-certificate/tp77692345d",
  title: "Diploma...",
  target: "_blank"
}
const _hoisted_61 = { class: "nomer" }
const _hoisted_62 = { class: "name" }
const _hoisted_63 = ["href"]
const _hoisted_64 = { class: "number" }
const _hoisted_65 = { class: "grade" }
const _hoisted_66 = { class: "date" }
const _hoisted_67 = {
  key: 9,
  class: "container",
  style: {"margin-bottom":"0.5rem"}
}
const _hoisted_68 = { class: "diplom" }
const _hoisted_69 = {
  class: "attention",
  href: "https://testprovider.com/ru/search-certificate/tp77692345d",
  title: "Diploma...",
  target: "_blank"
}
const _hoisted_70 = { class: "inner" }
const _hoisted_71 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createVNode(_component_router_link, {
        class: "back",
        to: "/certificates",
        title: "Back to Certificates"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("i", { class: "fa fa-arrow-circle-left" }, null, -1)
        ])),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('cert.front.h1')) + " ", 1),
      _createElementVNode("i", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeView && _ctx.changeView(...args)))
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(['fa', _ctx.tableView ? 'fa-list' : 'fa-th'])
        }, null, 2)
      ])
    ]),
    _cache[17] || (_cache[17] = _createElementVNode("line", null, null, -1)),
    (_ctx.tableView)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("table", null, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_3, [
                  _cache[2] || (_cache[2] = _createElementVNode("span", null, "✅ ", -1)),
                  _createElementVNode("b", null, [
                    _createElementVNode("a", _hoisted_4, _toDisplayString(_ctx.$t('cert.front.html1')), 1)
                  ])
                ])
              ]),
              _createElementVNode("tr", null, [
                _cache[3] || (_cache[3] = _createElementVNode("th", null, "№", -1)),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.title')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.number')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.grade')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.date')), 1)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.htmlStore.state.htmlStore, (sert) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: sert.id
                }, [
                  _createElementVNode("td", _hoisted_5, _toDisplayString(sert.id), 1),
                  _createElementVNode("td", _hoisted_6, [
                    _createElementVNode("a", {
                      href: sert.image,
                      title: "In more detail...",
                      target: "_blank"
                    }, _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 9, _hoisted_7)
                  ]),
                  _createElementVNode("td", _hoisted_8, _toDisplayString(sert.regnumber), 1),
                  _createElementVNode("td", _hoisted_9, _toDisplayString(sert.grade), 1),
                  _createElementVNode("td", _hoisted_10, _toDisplayString(sert.examdate), 1)
                ]))
              }), 128))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("h2", _hoisted_12, [
            _cache[4] || (_cache[4] = _createElementVNode("span", null, "✅ ", -1)),
            _createElementVNode("b", null, [
              _createElementVNode("a", _hoisted_13, _toDisplayString(_ctx.$t('cert.front.html1')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.htmlStore.state.htmlStore, (sert) => {
              return (_openBlock(), _createElementBlock("div", {
                key: sert.id,
                class: "certificate"
              }, [
                _createElementVNode("a", {
                  class: "block",
                  href: sert.image,
                  title: "Certificate...",
                  target: "_blank"
                }, [
                  _createElementVNode("h3", null, _toDisplayString(sert.id) + ". " + _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 1),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cert.number')) + ": ", 1),
                    _createElementVNode("strong", null, _toDisplayString(sert.regnumber), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                    _createElementVNode("strong", null, _toDisplayString(sert.grade), 1)
                  ]),
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('cert.date')) + ": " + _toDisplayString(sert.examdate), 1)
                ], 8, _hoisted_15)
              ]))
            }), 128))
          ])
        ])),
    (_ctx.tableView)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("table", null, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_17, [
                  _cache[5] || (_cache[5] = _createElementVNode("span", null, "✅ ", -1)),
                  _createElementVNode("b", null, [
                    _createElementVNode("a", _hoisted_18, _toDisplayString(_ctx.$t('cert.front.javascript1')), 1)
                  ])
                ])
              ]),
              _createElementVNode("tr", null, [
                _cache[6] || (_cache[6] = _createElementVNode("th", null, "№", -1)),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.title')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.number')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.grade')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.date')), 1)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.javascriptStore.state.javascriptStore, (sert) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: sert.id
                }, [
                  _createElementVNode("td", _hoisted_19, _toDisplayString(sert.id), 1),
                  _createElementVNode("td", _hoisted_20, [
                    _createElementVNode("a", {
                      href: sert.image,
                      title: "In more detail...",
                      target: "_blank"
                    }, _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 9, _hoisted_21)
                  ]),
                  _createElementVNode("td", _hoisted_22, _toDisplayString(sert.regnumber), 1),
                  _createElementVNode("td", _hoisted_23, _toDisplayString(sert.grade), 1),
                  _createElementVNode("td", _hoisted_24, _toDisplayString(sert.examdate), 1)
                ]))
              }), 128))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_25, [
          _createElementVNode("h2", _hoisted_26, [
            _cache[7] || (_cache[7] = _createElementVNode("span", null, "✅ ", -1)),
            _createElementVNode("b", null, [
              _createElementVNode("a", _hoisted_27, _toDisplayString(_ctx.$t('cert.front.javascript1')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_28, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.javascriptStore.state.javascriptStore, (sert) => {
              return (_openBlock(), _createElementBlock("div", {
                key: sert.id,
                class: "certificate"
              }, [
                _createElementVNode("a", {
                  class: "block",
                  href: sert.image,
                  title: "Certificate...",
                  target: "_blank"
                }, [
                  _createElementVNode("h3", null, _toDisplayString(sert.id) + ". " + _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 1),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cert.number')) + ": ", 1),
                    _createElementVNode("strong", null, _toDisplayString(sert.regnumber), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                    _createElementVNode("strong", null, _toDisplayString(sert.grade), 1)
                  ]),
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('cert.date')) + ": " + _toDisplayString(sert.examdate), 1)
                ], 8, _hoisted_29)
              ]))
            }), 128))
          ])
        ])),
    (_ctx.tableView)
      ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
          _createElementVNode("table", null, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_31, [
                  _cache[8] || (_cache[8] = _createElementVNode("span", null, "✅ ", -1)),
                  _createElementVNode("b", null, [
                    _createElementVNode("a", _hoisted_32, _toDisplayString(_ctx.$t('cert.front.angular1')), 1)
                  ])
                ])
              ]),
              _createElementVNode("tr", null, [
                _cache[9] || (_cache[9] = _createElementVNode("th", null, "№", -1)),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.title')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.number')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.grade')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.date')), 1)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.angularStore.state.angularStore, (sert) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: sert.id
                }, [
                  _createElementVNode("td", _hoisted_33, _toDisplayString(sert.id), 1),
                  _createElementVNode("td", _hoisted_34, [
                    _createElementVNode("a", {
                      href: sert.image,
                      title: "In more detail...",
                      target: "_blank"
                    }, _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 9, _hoisted_35)
                  ]),
                  _createElementVNode("td", _hoisted_36, _toDisplayString(sert.regnumber), 1),
                  _createElementVNode("td", _hoisted_37, _toDisplayString(sert.grade), 1),
                  _createElementVNode("td", _hoisted_38, _toDisplayString(sert.examdate), 1)
                ]))
              }), 128))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_39, [
          _createElementVNode("h2", _hoisted_40, [
            _cache[10] || (_cache[10] = _createElementVNode("span", null, "✅ ", -1)),
            _createElementVNode("b", null, [
              _createElementVNode("a", _hoisted_41, _toDisplayString(_ctx.$t('cert.front.angular1')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_42, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.angularStore.state.angularStore, (sert) => {
              return (_openBlock(), _createElementBlock("div", {
                key: sert.id,
                class: "certificate"
              }, [
                _createElementVNode("a", {
                  class: "block",
                  href: sert.image,
                  title: "Certificate...",
                  target: "_blank"
                }, [
                  _createElementVNode("h3", null, _toDisplayString(sert.id) + ". " + _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 1),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cert.number')) + ": ", 1),
                    _createElementVNode("strong", null, _toDisplayString(sert.regnumber), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                    _createElementVNode("strong", null, _toDisplayString(sert.grade), 1)
                  ]),
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('cert.date')) + ": " + _toDisplayString(sert.examdate), 1)
                ], 8, _hoisted_43)
              ]))
            }), 128))
          ])
        ])),
    (_ctx.tableView)
      ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
          _createElementVNode("table", null, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_45, [
                  _cache[11] || (_cache[11] = _createElementVNode("span", null, "✅ ", -1)),
                  _createElementVNode("b", null, [
                    _createElementVNode("a", _hoisted_46, _toDisplayString(_ctx.$t('cert.front.react1')), 1)
                  ])
                ])
              ]),
              _createElementVNode("tr", null, [
                _cache[12] || (_cache[12] = _createElementVNode("th", null, "№", -1)),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.title')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.number')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.grade')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.date')), 1)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reactStore.state.reactStore, (sert) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: sert.id
                }, [
                  _createElementVNode("td", _hoisted_47, _toDisplayString(sert.id), 1),
                  _createElementVNode("td", _hoisted_48, [
                    _createElementVNode("a", {
                      href: sert.image,
                      title: "In more detail...",
                      target: "_blank"
                    }, _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 9, _hoisted_49)
                  ]),
                  _createElementVNode("td", _hoisted_50, _toDisplayString(sert.regnumber), 1),
                  _createElementVNode("td", _hoisted_51, _toDisplayString(sert.grade), 1),
                  _createElementVNode("td", _hoisted_52, _toDisplayString(sert.examdate), 1)
                ]))
              }), 128))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_53, [
          _createElementVNode("h2", _hoisted_54, [
            _cache[13] || (_cache[13] = _createElementVNode("span", null, "✅ ", -1)),
            _createElementVNode("b", null, [
              _createElementVNode("a", _hoisted_55, _toDisplayString(_ctx.$t('cert.front.react1')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_56, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reactStore.state.reactStore, (sert) => {
              return (_openBlock(), _createElementBlock("div", {
                key: sert.id,
                class: "certificate"
              }, [
                _createElementVNode("a", {
                  class: "block",
                  href: sert.image,
                  title: "Certificate...",
                  target: "_blank"
                }, [
                  _createElementVNode("h3", null, _toDisplayString(sert.id) + ". " + _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 1),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cert.number')) + ": ", 1),
                    _createElementVNode("strong", null, _toDisplayString(sert.regnumber), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                    _createElementVNode("strong", null, _toDisplayString(sert.grade), 1)
                  ]),
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('cert.date')) + ": " + _toDisplayString(sert.examdate), 1)
                ], 8, _hoisted_57)
              ]))
            }), 128))
          ])
        ])),
    (_ctx.tableView)
      ? (_openBlock(), _createElementBlock("div", _hoisted_58, [
          _createElementVNode("table", null, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_59, [
                  _cache[14] || (_cache[14] = _createElementVNode("span", null, "✅ ", -1)),
                  _createElementVNode("b", null, [
                    _createElementVNode("a", _hoisted_60, _toDisplayString(_ctx.$t('cert.front.frontend1')), 1)
                  ])
                ])
              ]),
              _createElementVNode("tr", null, [
                _cache[15] || (_cache[15] = _createElementVNode("th", null, "№", -1)),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.title')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.number')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.grade')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('cert.date')), 1)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.frontStore.state.frontStore, (sert) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: sert.id
                }, [
                  _createElementVNode("td", _hoisted_61, _toDisplayString(sert.id), 1),
                  _createElementVNode("td", _hoisted_62, [
                    _createElementVNode("a", {
                      href: sert.image,
                      title: "In more detail...",
                      target: "_blank"
                    }, _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 9, _hoisted_63)
                  ]),
                  _createElementVNode("td", _hoisted_64, _toDisplayString(sert.regnumber), 1),
                  _createElementVNode("td", _hoisted_65, _toDisplayString(sert.grade), 1),
                  _createElementVNode("td", _hoisted_66, _toDisplayString(sert.examdate), 1)
                ]))
              }), 128))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_67, [
          _createElementVNode("h2", _hoisted_68, [
            _cache[16] || (_cache[16] = _createElementVNode("span", null, "✅ ", -1)),
            _createElementVNode("b", null, [
              _createElementVNode("a", _hoisted_69, _toDisplayString(_ctx.$t('cert.front.frontend1')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_70, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.frontStore.state.frontStore, (sert) => {
              return (_openBlock(), _createElementBlock("div", {
                key: sert.id,
                class: "certificate"
              }, [
                _createElementVNode("a", {
                  class: "block",
                  href: sert.image,
                  title: "Certificate...",
                  target: "_blank"
                }, [
                  _createElementVNode("h3", null, _toDisplayString(sert.id) + ". " + _toDisplayString(this.$i18n.locale === "uk" ? sert.title_ua : this.$i18n.locale === "es" ? sert.title_es : sert.title_en), 1),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cert.number')) + ": ", 1),
                    _createElementVNode("strong", null, _toDisplayString(sert.regnumber), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                    _createElementVNode("strong", null, _toDisplayString(sert.grade), 1)
                  ]),
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('cert.date')) + ": " + _toDisplayString(sert.examdate), 1)
                ], 8, _hoisted_71)
              ]))
            }), 128))
          ])
        ]))
  ]))
}