import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "calc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CalculatorStandart = _resolveComponent("CalculatorStandart")!
  const _component_CalculatorFinance = _resolveComponent("CalculatorFinance")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createVNode(_component_router_link, {
        class: "back",
        to: "/extra",
        title: "Back to Extra page"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("i", { class: "fa fa-arrow-circle-left" }, null, -1)
        ])),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('extra.h1m.calculator')) + " ", 1),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeView && _ctx.changeView(...args)))
      }, _toDisplayString(_ctx.calcView ? _ctx.$t('extra.calculator.accounting') : _ctx.$t('extra.calculator.standart')), 1)
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("line", null, null, -1)),
    (_ctx.calcView)
      ? (_openBlock(), _createBlock(_component_CalculatorStandart, { key: 0 }))
      : (_openBlock(), _createBlock(_component_CalculatorFinance, { key: 1 }))
  ]))
}