import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fibonacci" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "result" }
const _hoisted_4 = { style: {"color":"lightseagreen"} }
const _hoisted_5 = { style: {"color":"red"} }
const _hoisted_6 = { class: "inner" }
const _hoisted_7 = { class: "certificate" }
const _hoisted_8 = {
  class: "block",
  href: "/assets/tasks/Fibonacci-JS.pdf",
  title: "Download solution in pdf format...",
  target: "_blank"
}
const _hoisted_9 = { class: "certificate" }
const _hoisted_10 = {
  class: "block",
  href: "/assets/tasks/Fibonacci-Vue.pdf",
  title: "Download solution in pdf format...",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createVNode(_component_router_link, {
        class: "back",
        to: "/extra/tasks",
        title: "Back to Tasks page"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("i", { class: "fa fa-arrow-circle-left" }, null, -1)
        ])),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('extra.h1m.tasks.fibonacci')), 1)
    ]),
    _cache[4] || (_cache[4] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("p", null, [
      _createTextVNode(_toDisplayString(_ctx.$t('extra.tasks.fibonacci.fib-des01')), 1),
      _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
      _createTextVNode(_toDisplayString(_ctx.$t('extra.tasks.fibonacci.fib-des02')), 1)
    ]),
    _cache[5] || (_cache[5] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("div", null, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('extra.tasks.fibonacci.fib-des03')), 1),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputNumber) = $event)),
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
      }, null, 544), [
        [_vModelText, _ctx.inputNumber]
      ]),
      _createElementVNode("h3", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.$t('extra.tasks.fibonacci.fib-des04')) + " ", 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.inputNumber), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('extra.tasks.fibonacci.fib-des05')) + " ", 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.fibonacci), 1)
      ])
    ]),
    _cache[6] || (_cache[6] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("a", _hoisted_8, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('extra.tasks.link-js')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("a", _hoisted_10, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('extra.tasks.link-vue')), 1)
        ])
      ])
    ])
  ]))
}