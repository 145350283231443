import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withKeys as _withKeys, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "table" }
const _hoisted_2 = { colspan: "4" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = { class: "text" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["onUpdate:modelValue", "onKeyup", "onBlur"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "updatedAt" }
const _hoisted_9 = { class: "action" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { colspan: "4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", null, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", _hoisted_2, [
            _createElementVNode("form", {
              onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.addNote && _ctx.addNote(...args)), ["prevent"]))
            }, [
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newNote) = $event)),
                placeholder: _ctx.$t('extra.notes.add-note')
              }, null, 8, _hoisted_3), [
                [_vModelText, _ctx.newNote]
              ]),
              _cache[3] || (_cache[3] = _createElementVNode("button", { class: "add-note" }, [
                _createElementVNode("i", { class: "fas fa-plus-circle" })
              ], -1))
            ], 32)
          ])
        ]),
        _createElementVNode("tr", null, [
          _cache[4] || (_cache[4] = _createElementVNode("th", null, "№", -1)),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t('extra.notes.note')), 1),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t('extra.notes.modified')), 1),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t('extra.notes.actions')), 1)
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notes, (note, index) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: note.id
          }, [
            _createElementVNode("td", null, _toDisplayString(index + 1), 1),
            _createElementVNode("td", _hoisted_4, [
              (note.editing)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                    _withDirectives(_createElementVNode("textarea", {
                      "onUpdate:modelValue": ($event: any) => ((note.text) = $event),
                      onKeyup: _withKeys(($event: any) => (_ctx.editNote(note)), ["enter"]),
                      onBlur: ($event: any) => (_ctx.editNote(note))
                    }, null, 40, _hoisted_6), [
                      [_vModelText, note.text]
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(note.text), 1))
            ]),
            _createElementVNode("td", _hoisted_8, _toDisplayString(note.updatedAt), 1),
            _createElementVNode("td", _hoisted_9, [
              (!note.editing)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "btn norm txt",
                    onClick: ($event: any) => (note.editing = true)
                  }, _toDisplayString(_ctx.$t('extra.notes.edit')), 9, _hoisted_10))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                class: "btn danger txt",
                onClick: ($event: any) => (_ctx.deleteNote(index))
              }, _toDisplayString(_ctx.$t('extra.notes.delete')), 9, _hoisted_11),
              (!note.editing)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    class: "btn norm icon",
                    onClick: ($event: any) => (note.editing = true),
                    title: "Edit..."
                  }, _cache[5] || (_cache[5] = [
                    _createElementVNode("i", { class: "fas fa-edit" }, null, -1)
                  ]), 8, _hoisted_12))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                class: "btn danger icon",
                onClick: ($event: any) => (_ctx.deleteNote(index)),
                title: "Delete..."
              }, _cache[6] || (_cache[6] = [
                _createElementVNode("i", { class: "fas fa-trash" }, null, -1)
              ]), 8, _hoisted_13)
            ])
          ]))
        }), 128))
      ]),
      _createElementVNode("tfoot", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", _hoisted_14, [
            _createElementVNode("button", {
              class: "btn danger",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.deleteAllNotes && _ctx.deleteAllNotes(...args)))
            }, _toDisplayString(_ctx.$t('extra.notes.delete-all')), 1)
          ])
        ])
      ])
    ])
  ]))
}