import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "body" }
const _hoisted_2 = { class: "calculator" }
const _hoisted_3 = { class: "display" }
const _hoisted_4 = { class: "current" }
const _hoisted_5 = { class: "history" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.current && _ctx.current.length > 10 ? _ctx.current.slice(0, 10) + '...' : _ctx.current || "0"), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.history), 1)
      ]),
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args))),
        class: "btn clear"
      }, "AC"),
      _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.sign && _ctx.sign(...args))),
        class: "btn sign"
      }, "+/-"),
      _createElementVNode("div", {
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.percent && _ctx.percent(...args))),
        class: "btn percent"
      }, "%"),
      _createElementVNode("div", {
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.sqrt && _ctx.sqrt(...args))),
        class: "btn operator sqrt"
      }, "√"),
      _createElementVNode("div", {
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.append('7'))),
        class: "btn numbers"
      }, "7"),
      _createElementVNode("div", {
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.append('8'))),
        class: "btn numbers"
      }, "8"),
      _createElementVNode("div", {
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.append('9'))),
        class: "btn numbers"
      }, "9"),
      _createElementVNode("div", {
        onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.divide && _ctx.divide(...args))),
        class: "btn operator"
      }, "÷"),
      _createElementVNode("div", {
        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.append('4'))),
        class: "btn numbers"
      }, "4"),
      _createElementVNode("div", {
        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.append('5'))),
        class: "btn numbers"
      }, "5"),
      _createElementVNode("div", {
        onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.append('6'))),
        class: "btn numbers"
      }, "6"),
      _createElementVNode("div", {
        onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.times && _ctx.times(...args))),
        class: "btn operator"
      }, "x"),
      _createElementVNode("div", {
        onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.append('1'))),
        class: "btn numbers"
      }, "1"),
      _createElementVNode("div", {
        onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.append('2'))),
        class: "btn numbers"
      }, "2"),
      _createElementVNode("div", {
        onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.append('3'))),
        class: "btn numbers"
      }, "3"),
      _createElementVNode("div", {
        onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.minus && _ctx.minus(...args))),
        class: "btn operator"
      }, "-"),
      _createElementVNode("div", {
        onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.append('0'))),
        class: "btn zero numbers"
      }, "0"),
      _createElementVNode("div", {
        onClick: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.dot && _ctx.dot(...args))),
        class: "btn dot"
      }, "."),
      _createElementVNode("div", {
        onClick: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_ctx.equal && _ctx.equal(...args))),
        class: "btn operator equal"
      }, "="),
      _createElementVNode("div", {
        onClick: _cache[19] || (_cache[19] = 
//@ts-ignore
(...args) => (_ctx.add && _ctx.add(...args))),
        class: "btn operator"
      }, "+")
    ])
  ]))
}