import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "fizzbuzz" }
const _hoisted_2 = { class: "form" }
const _hoisted_3 = {
  key: 0,
  class: "numbers"
}
const _hoisted_4 = { class: "inner" }
const _hoisted_5 = { class: "certificate" }
const _hoisted_6 = {
  class: "block",
  href: "/assets/tasks/FizzBuzz-JS.pdf",
  title: "Download solution in pdf format...",
  target: "_blank"
}
const _hoisted_7 = { class: "certificate" }
const _hoisted_8 = {
  class: "block",
  href: "/assets/tasks/FizzBuzz-Vue.pdf",
  title: "Download solution in pdf format...",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createVNode(_component_router_link, {
        class: "back",
        to: "/extra/tasks",
        title: "Back to Tasks page"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createElementVNode("i", { class: "fa fa-arrow-circle-left" }, null, -1)
        ])),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('extra.h1m.tasks.fizzbuzz')), 1)
    ]),
    _cache[9] || (_cache[9] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("p", null, [
      _createTextVNode(_toDisplayString(_ctx.$t('extra.tasks.fizzbuzz.fizz-des01')), 1),
      _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
      _createTextVNode(_toDisplayString(_ctx.$t('extra.tasks.fizzbuzz.fizz-des02')), 1),
      _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
      _createTextVNode(_toDisplayString(_ctx.$t('extra.tasks.fizzbuzz.fizz-des03')), 1),
      _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
      _createTextVNode(_toDisplayString(_ctx.$t('extra.tasks.fizzbuzz.fizz-des04')), 1)
    ]),
    _cache[10] || (_cache[10] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("form", {
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.generateNumbers && _ctx.generateNumbers(...args)), ["prevent"]))
        }, [
          _createElementVNode("label", null, [
            _createTextVNode(_toDisplayString(_ctx.$t('extra.tasks.fizzbuzz.fizz-des05')) + " ", 1),
            _withDirectives(_createElementVNode("input", {
              type: "number",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.number) = $event)),
              min: "1",
              required: ""
            }, null, 512), [
              [
                _vModelText,
                _ctx.number,
                void 0,
                { number: true }
              ]
            ])
          ]),
          _cache[8] || (_cache[8] = _createElementVNode("button", {
            type: "submit",
            class: "btn",
            title: "Generate"
          }, [
            _createElementVNode("i", { class: "fas fa-magic" })
          ], -1)),
          (_ctx.numbers)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: "btn",
                title: "Clear",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clearNumbers && _ctx.clearNumbers(...args)))
              }, _cache[7] || (_cache[7] = [
                _createElementVNode("i", { class: "fas fa-trash-alt" }, null, -1)
              ])))
            : _createCommentVNode("", true)
        ], 32)
      ]),
      (_ctx.numbers)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numbers, (num) => {
              return (_openBlock(), _createElementBlock("span", {
                key: num,
                class: _normalizeClass({ 'fizzbuzz': num === 'fizzbuzz', 'fizz': num === 'fizz', 'buzz': num === 'buzz' })
              }, _toDisplayString(num), 3))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _cache[11] || (_cache[11] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("a", _hoisted_6, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('extra.tasks.link-js')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("a", _hoisted_8, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('extra.tasks.link-vue')), 1)
        ])
      ])
    ])
  ]))
}