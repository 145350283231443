import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "inner" }
const _hoisted_2 = {
  ref: "marquee",
  class: "marquee"
}
const _hoisted_3 = {
  key: 0,
  class: "error"
}
const _hoisted_4 = {
  key: 1,
  class: "error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "content" }, null, -1)
      ]), 512)
    ], 512), [
      [_vShow, _ctx.cripView && _ctx.weather]
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('extra.weather.loading')), 1))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.error), 1))
  ], 64))
}