import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "totalSert" }
const _hoisted_2 = { class: "sert" }
const _hoisted_3 = { class: "itogo" }
const _hoisted_4 = { class: "colvo" }
const _hoisted_5 = { class: "itogo" }
const _hoisted_6 = { class: "colvo" }
const _hoisted_7 = { class: "inner" }
const _hoisted_8 = {
  key: 0,
  class: "showDiplom"
}
const _hoisted_9 = ["href"]
const _hoisted_10 = ["href"]
const _hoisted_11 = { class: "diploma" }
const _hoisted_12 = ["href"]
const _hoisted_13 = {
  key: 1,
  class: "showCertificate"
}
const _hoisted_14 = ["href"]
const _hoisted_15 = ["href"]
const _hoisted_16 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('cert.itogo')), 1),
        _createElementVNode("span", {
          class: "sertActive",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.diplomView()))
        }, [
          _createTextVNode(_toDisplayString(_ctx.$t('cert.diplomView')) + " ", 1),
          _createElementVNode("span", {
            class: _normalizeClass(['fas', _ctx.showDiplom ? 'fa-chevron-circle-down' : 'fa-chevron-circle-up' ])
          }, null, 2)
        ]),
        _cache[2] || (_cache[2] = _createTextVNode(" — ")),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.itvdnStore.state.itvdnStore.length + (_ctx.knuteStore.state.knuteStore.length-1) + _ctx.progStore.state.progStore.length), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('cert.itogo')), 1),
        _createElementVNode("span", {
          class: "sertActive",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.certificateView()))
        }, [
          _createTextVNode(_toDisplayString(_ctx.$t('cert.certificateView')) + " ", 1),
          _createElementVNode("span", {
            class: _normalizeClass(['fas', _ctx.showCertificate ? 'fa-chevron-circle-down' : 'fa-chevron-circle-up' ])
          }, null, 2)
        ]),
        _cache[3] || (_cache[3] = _createTextVNode(" — ")),
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.combinedCertificates.length + _ctx.gradeCombinedCertificates.length + _ctx.englishStore.state.englishStore.length), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.showDiplom)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itvdnStore.state.itvdnStore, (sert) => {
              return (_openBlock(), _createElementBlock("div", {
                key: sert.id,
                class: "diploma"
              }, [
                _createElementVNode("a", {
                  class: "block",
                  href: sert.image,
                  title: "Diploma...",
                  target: "_blank"
                }, [
                  _createElementVNode("h3", null, _toDisplayString(sert.id) + ". " + _toDisplayString(_ctx.getTitle(sert)), 1),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cert.number')) + ": ", 1),
                    _createElementVNode("strong", null, _toDisplayString(sert.regnumber), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                    _createElementVNode("strong", null, _toDisplayString(sert.grade), 1)
                  ]),
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('cert.date')) + ": " + _toDisplayString(sert.examdate), 1)
                ], 8, _hoisted_9)
              ]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.progStore.state.progStore, (sert) => {
              return (_openBlock(), _createElementBlock("div", {
                key: sert.id,
                class: "diploma"
              }, [
                _createElementVNode("a", {
                  class: "block",
                  href: sert.image,
                  title: "Certificate...",
                  target: "_blank"
                }, [
                  _createElementVNode("h3", null, _toDisplayString(sert.id) + ". " + _toDisplayString(_ctx.getTitle(sert)), 1),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cert.number')) + ": ", 1),
                    _createElementVNode("strong", null, _toDisplayString(_ctx.getRegNumber(sert)), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                    _createElementVNode("strong", null, _toDisplayString(_ctx.getGrade(sert)), 1)
                  ]),
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('cert.date')) + ": " + _toDisplayString(sert.examdate), 1)
                ], 8, _hoisted_10)
              ]))
            }), 128)),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("a", {
                class: "block",
                href: _ctx.knuteStore.state.knuteStore[1].image,
                title: "Diploma...",
                target: "_blank"
              }, [
                _createElementVNode("h3", null, _toDisplayString(_ctx.knuteStore.state.knuteStore[1].id) + ". " + _toDisplayString(_ctx.getTitle(_ctx.knuteStore.state.knuteStore[1])), 1),
                _createElementVNode("div", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('cert.number')) + ": ", 1),
                  _createElementVNode("strong", null, _toDisplayString(_ctx.knuteStore.state.knuteStore[1].regnumber), 1)
                ]),
                _createElementVNode("div", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                  _createElementVNode("strong", null, _toDisplayString(_ctx.getGrade(_ctx.knuteStore.state.knuteStore[1])), 1)
                ]),
                _createElementVNode("div", null, _toDisplayString(_ctx.$t('education.graduation')) + ": " + _toDisplayString(_ctx.knuteStore.state.knuteStore[1].examdate), 1)
              ], 8, _hoisted_12)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.showCertificate)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.combinedCertificates, (sert) => {
              return (_openBlock(), _createElementBlock("div", {
                key: sert.id,
                class: "certificate"
              }, [
                _createElementVNode("a", {
                  class: "block",
                  href: sert.image,
                  title: "Certificate...",
                  target: "_blank"
                }, [
                  _createElementVNode("h3", null, _toDisplayString(sert.id) + ". " + _toDisplayString(_ctx.getTitle(sert)), 1),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cert.number')) + ": ", 1),
                    _createElementVNode("strong", null, _toDisplayString(sert.regnumber), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                    _createElementVNode("strong", null, _toDisplayString(sert.grade), 1)
                  ]),
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('cert.date')) + ": " + _toDisplayString(sert.examdate), 1)
                ], 8, _hoisted_14)
              ]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gradeCombinedCertificates, (sert) => {
              return (_openBlock(), _createElementBlock("div", {
                key: sert.id,
                class: "certificate"
              }, [
                _createElementVNode("a", {
                  class: "block",
                  href: sert.image,
                  title: "Certificate...",
                  target: "_blank"
                }, [
                  _createElementVNode("h3", null, _toDisplayString(sert.id) + ". " + _toDisplayString(_ctx.getTitle(sert)), 1),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cert.number')) + ": ", 1),
                    _createElementVNode("strong", null, _toDisplayString(sert.regnumber), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                    _createElementVNode("strong", null, _toDisplayString(_ctx.getGrade(sert)), 1)
                  ]),
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('cert.date')) + ": " + _toDisplayString(sert.examdate), 1)
                ], 8, _hoisted_15)
              ]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.englishStore.state.englishStore, (sert) => {
              return (_openBlock(), _createElementBlock("div", {
                key: sert.id,
                class: "certificate"
              }, [
                _createElementVNode("a", {
                  class: "block",
                  href: sert.image,
                  title: "Certificate...",
                  target: "_blank"
                }, [
                  _createElementVNode("h3", null, _toDisplayString(sert.id) + ". " + _toDisplayString(_ctx.getTitle(sert)), 1),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cert.level')) + ": ", 1),
                    _createElementVNode("strong", null, _toDisplayString(sert.regnumber), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('cert.grade')) + ": ", 1),
                    _createElementVNode("strong", null, _toDisplayString(_ctx.getGrade(sert)), 1)
                  ]),
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('cert.date')) + ": " + _toDisplayString(sert.examdate), 1)
                ], 8, _hoisted_16)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}