import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "footer-text" }
const _hoisted_3 = {
  href: "https://zorger27.github.io",
  title: "CV (Landing Page)",
  target: "_blank"
}
const _hoisted_4 = { class: "footer-text-mob" }
const _hoisted_5 = {
  href: "https://zorger27.github.io",
  title: "CV (Landing Page)",
  target: "_blank"
}
const _hoisted_6 = { class: "copyright" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SocialSharing = _resolveComponent("SocialSharing")!

  return (_openBlock(), _createElementBlock("footer", null, [
    _createElementVNode("div", {
      class: "footer-logo",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cubejs && _ctx.cubejs(...args)))
    }, [
      _createElementVNode("img", {
        src: _ctx.getFooterLogoImage,
        alt: "Footer Logo Image"
      }, null, 8, _hoisted_1)
    ]),
    _createElementVNode("p", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.$t('footer.text1')) + " ", 1),
      _createElementVNode("b", null, [
        _createElementVNode("a", _hoisted_3, _toDisplayString(_ctx.$t('footer.text2')), 1)
      ]),
      _createTextVNode(_toDisplayString(_ctx.$t('footer.text3')) + " ", 1),
      _createElementVNode("b", null, "© 2023―" + _toDisplayString(_ctx.currentYear), 1),
      _createTextVNode(" - " + _toDisplayString(_ctx.$t('footer.text4')), 1)
    ]),
    _createElementVNode("p", _hoisted_4, [
      _createTextVNode(_toDisplayString(_ctx.$t('footer.text1')) + " ", 1),
      _createElementVNode("b", null, [
        _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.$t('footer.text2')), 1)
      ]),
      _createTextVNode(_toDisplayString(_ctx.$t('footer.text3')), 1),
      _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
      _createElementVNode("span", _hoisted_6, [
        _createElementVNode("b", null, "© 2023―" + _toDisplayString(_ctx.currentYear), 1),
        _createTextVNode(" - " + _toDisplayString(_ctx.$t('footer.text4')), 1)
      ])
    ]),
    _createVNode(_component_SocialSharing)
  ]))
}