import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header-logo" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "dropbtn" }
const _hoisted_6 = { class: "dropdown-content" }
const _hoisted_7 = { class: "dropbtn" }
const _hoisted_8 = { class: "dropdown-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_language_switcher = _resolveComponent("language-switcher")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "burger-menu",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMenu = !_ctx.showMenu))
      }, [
        _createElementVNode("i", {
          class: _normalizeClass(['fa', _ctx.showMenu ? 'fa-times' : 'fa-bars', 'burger-menu-icon'])
        }, null, 2)
      ]),
      _createElementVNode("div", {
        class: "logo",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.cube3d && _ctx.cube3d(...args)))
      }, [
        _createElementVNode("img", {
          src: _ctx.getHeaderImage,
          alt: "Header Image"
        }, null, 8, _hoisted_2)
      ]),
      _createElementVNode("div", {
        class: "login",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.extra && _ctx.extra(...args)))
      }, [
        _createElementVNode("img", {
          src: _ctx.getExtraImage,
          alt: "Enter to Extra Page",
          title: "Enter to Extra Page"
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", {
        class: "search",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)))
      }, [
        _createElementVNode("img", {
          src: _ctx.getCertificatesImage,
          alt: "Search certificates...",
          title: "Search certificates..."
        }, null, 8, _hoisted_4)
      ]),
      _createVNode(_component_language_switcher, { class: "language" })
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["menu", { 'is-active': _ctx.showMenu }])
    }, [
      _createVNode(_component_router_link, {
        to: "/",
        onClick: _ctx.hideMenu
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('header.main')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_router_link, {
        to: "/projects",
        onClick: _ctx.hideMenu
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('header.projects')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createElementVNode("div", {
        class: "dropdown",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showExtraContent = !_ctx.showExtraContent))
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createTextVNode(_toDisplayString(_ctx.$t('header.extra')) + " ", 1),
          _createElementVNode("span", {
            class: _normalizeClass(['fa', _ctx.showExtraContent ? 'fa-caret-up' : 'fa-caret-down'])
          }, null, 2)
        ]),
        _createVNode(_component_router_link, {
          to: "/extra",
          class: "dropbtnMob"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('header.extra')), 1)
          ]),
          _: 1
        }),
        _withDirectives(_createElementVNode("div", _hoisted_6, [
          _createVNode(_component_router_link, {
            to: "/extra/calculator",
            onClick: _ctx.hideMenu
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('extra.calculator.title')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_router_link, {
            to: "/extra/todo",
            onClick: _ctx.hideMenu
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('extra.todo.title')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_router_link, {
            to: "/extra/notes",
            onClick: _ctx.hideMenu
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('extra.notes.title')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_router_link, {
            to: "/extra/weather",
            onClick: _ctx.hideMenu
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('extra.weather.title1')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_router_link, {
            to: "/extra/rates",
            onClick: _ctx.hideMenu
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('extra.exchange.title')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_router_link, {
            to: "/extra/cryptocur",
            onClick: _ctx.hideMenu
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('extra.cryptocur.title')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_router_link, {
            to: "/extra/tasks",
            onClick: _ctx.hideMenu
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('extra.tasks.title')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_router_link, {
            to: "/extra/graphics",
            onClick: _ctx.hideMenu
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('extra.graphics.title')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_router_link, {
            to: "/extra/games",
            onClick: _ctx.hideMenu
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('extra.game.title')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_router_link, {
            to: "/extra/ai",
            onClick: _ctx.hideMenu
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('extra.ai.title')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ], 512), [
          [_vShow, _ctx.showExtraContent]
        ])
      ]),
      _createVNode(_component_router_link, {
        to: "/education",
        onClick: _ctx.hideMenu
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('header.education')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_router_link, {
        to: "/experience",
        onClick: _ctx.hideMenu
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('header.experience')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_router_link, {
        to: "/skills",
        onClick: _ctx.hideMenu
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('header.skills')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createElementVNode("div", {
        class: "dropdown",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showDropdownContent = !_ctx.showDropdownContent))
      }, [
        _createElementVNode("div", _hoisted_7, [
          _createTextVNode(_toDisplayString(_ctx.$t('header.certificates.cert')) + " ", 1),
          _createElementVNode("span", {
            class: _normalizeClass(['fa', _ctx.showDropdownContent ? 'fa-caret-up' : 'fa-caret-down'])
          }, null, 2)
        ]),
        _createVNode(_component_router_link, {
          to: "/certificates",
          class: "dropbtnMob"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('header.certificates.cert')), 1)
          ]),
          _: 1
        }),
        _withDirectives(_createElementVNode("div", _hoisted_8, [
          _createVNode(_component_router_link, {
            to: "/certificates/backend",
            onClick: _ctx.hideMenu
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('header.certificates.backend')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_router_link, {
            to: "/certificates/frontend",
            onClick: _ctx.hideMenu
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('header.certificates.frontend')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_router_link, {
            to: "/certificates/database",
            onClick: _ctx.hideMenu
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('header.certificates.database')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_router_link, {
            to: "/certificates/designer",
            onClick: _ctx.hideMenu
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('header.certificates.designer')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_router_link, {
            to: "/certificates/pm",
            onClick: _ctx.hideMenu
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('header.certificates.pm')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_router_link, {
            to: "/certificates/english",
            onClick: _ctx.hideMenu
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('header.certificates.english')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_router_link, {
            to: "/certificates/other",
            onClick: _ctx.hideMenu
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('header.certificates.other')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ], 512), [
          [_vShow, _ctx.showDropdownContent]
        ])
      ]),
      _createVNode(_component_router_link, {
        to: "/about",
        onClick: _ctx.hideMenu
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('header.about')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ], 2)
  ]))
}