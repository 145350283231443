import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "inner" }
const _hoisted_2 = {
  ref: "marquee",
  class: "marquee"
}
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "name" }
const _hoisted_5 = { class: "price" }
const _hoisted_6 = { class: "uah" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rates, (rate, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `rate-${index}`,
            class: "rates"
          }, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(rate.txt), 1),
            _cache[0] || (_cache[0] = _createElementVNode("span", { class: "eql" }, "=", -1)),
            _createElementVNode("span", _hoisted_5, _toDisplayString(rate.rate.toFixed(2)), 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('extra.exchange.uah')), 1)
          ]))
        }), 128))
      ])
    ], 512)
  ], 512)), [
    [_vShow, _ctx.cripView]
  ])
}