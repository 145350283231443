import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "table"
}
const _hoisted_2 = { class: "nomer" }
const _hoisted_3 = { class: "name" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "price" }
const _hoisted_6 = { style: {"color":"black"} }
const _hoisted_7 = {
  key: 1,
  class: "container"
}
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "name" }
const _hoisted_10 = { class: "price" }
const _hoisted_11 = { class: "usd" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.tableView)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("table", null, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _cache[0] || (_cache[0] = _createElementVNode("th", null, "№", -1)),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('extra.cryptocur.name')), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('extra.cryptocur.price')), 1)
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cryptos, (crypto, index) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: crypto.id
              }, [
                _createElementVNode("td", _hoisted_2, _toDisplayString(index + 1), 1),
                _createElementVNode("td", _hoisted_3, [
                  _createElementVNode("a", {
                    href: crypto.url,
                    title: "In more detail...",
                    target: "_blank"
                  }, _toDisplayString(crypto.name), 9, _hoisted_4)
                ]),
                _createElementVNode("td", _hoisted_5, [
                  _createTextVNode(_toDisplayString(crypto.price) + " ", 1),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('extra.exchange.usd')), 1)
                ])
              ]))
            }), 128))
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cryptos, (crypto) => {
          return (_openBlock(), _createElementBlock("div", {
            key: crypto.id,
            class: "crypto"
          }, [
            _createElementVNode("a", {
              href: crypto.url,
              title: "In more detail...",
              target: "_blank"
            }, [
              _createElementVNode("span", _hoisted_9, _toDisplayString(crypto.name), 1),
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "eql" }, "=", -1)),
              _createElementVNode("span", _hoisted_10, _toDisplayString(crypto.price), 1),
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('extra.exchange.usd')), 1)
            ], 8, _hoisted_8)
          ]))
        }), 128))
      ]))
}