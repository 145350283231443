const state = {
  experienceStore:
    [
      {
        id: 1,
        data_start: "13.01.2023",
        data_finish_en: "Until now",
        data_finish_ua: "Дотепер",
        data_finish_es: "Hasta ahora",
        company_en: "Self Employed",
        company_ua: "Самозайнятий",
        company_es: "Trabajador por cuenta propia",
        job_en: "Web Developer (Vue.js | Three.js)",
        job_ua: "Веб-розробник (Vue.js | Three.js)",
        job_es: "Desarrollador web (Vue.js | Three.js)",
        main_duties_en: "Creation and maintenance of sites and web applications.",
        main_duties_ua: "Створення та підтримка сайтів та веб-додатків.",
        main_duties_es: "Creación y mantenimiento de sitios y aplicaciones web.",
        related_duties_en: "Design (Web & UI/UX Design) and Digital marketing.",
        related_duties_ua: "Дизайн (Веб і UI/UX дизайн) і Цифровий маркетинг.",
        related_duties_es: "Diseño (Web & UI/UX diseño) y Marketing digital.",
        tasks_en: "Creation of a site prototype. Website design development. Building a website (SPA) on Vue.js with using TypeScript. Domain name registration. Connecting a domain name to a site. Connecting Google Analytics 4.0. Registration of the site in Google Search Console and its subsequent indexing. Creation of Sitemap.xml and Robots.txt files. Animation of text, blocks. Creating 3D models and 3D configurators using Three.js.",
        tasks_ua: "Створення прототипу сайту. Розробка дизайну веб-сайту. Створення веб-сайту на Vue.js з використанням TypeScript. Реєстрація доменного імені. Підключення доменного імені до сайту. Підключення Google Analytics 4.0. Реєстрація сайту в Google Search Console та подальше проведення його індексації. Створення файлів Sitemap.xml та Robots.txt. Анімація тексту, блоків. Створення 3D моделей і 3D конфігураторів за допомогою Three.js.",
        tasks_es: "Creación de un prototipo de sitio. Desarrollo de diseño de sitios web. Creación de un sitio web (SPA) en Vue.js con el uso de TypeScript. Registro del nombre de dominio. Conexión de un nombre de dominio a un sitio. Conectando Google Analytics 4.0. Registro del sitio en Google Search Console y su posterior indexación. Creación de archivos Sitemap.xml y Robots.txt. Creación de modelos 3D y configuradores 3D utilizando Three.js.",
        skills: "Adobe Audition, Cybersecurity, Contract Negotiation, Angular, CSS3, Git, Agile, Vue.js, Front-end Development, Object-oriented programming, Business Strategy, Adobe Premiere, Marketing, Strategic Planning, Node.js, Negotiation, Business Planning, Search Engine Marketing (SEM), Strategic Thinking, Digital marketing, Adobe Acrobat, IT operations, Microsoft Office, HTML, Business Analysis, Business Development, Adobe Photoshop, Marketing Strategy, XML, JavaScript, Windows Server, Google Analytics, Web Designer, Critical Thinking, Personal Development, TypeScript, Adobe Illustrator, Adobe Lightroom, Sales Management, Small Business, Search Engine Optimization, PostgreSQL, UI/UX Designer, Entrepreneurship, Management, Social Media Marketing."
      },
      {
        id: 2,
        data_start: "27.12.2016",
        data_finish_en: "13.01.2023",
        data_finish_ua: "13.01.2023",
        data_finish_es: "13.01.2023",
        company_en: "Yugov & D Ltd",
        company_ua: "ТОВ \"Югов & Д\"",
        company_es: "\"Yugov & D\" SRL",
        job_en: "CEO",
        job_ua: "Генеральний директор",
        job_es: "CEO",
        main_duties_en: "Full administrative management of the enterprise.",
        main_duties_ua: "Повне адміністративне управління підприємством.",
        main_duties_es: "Gestión administrativa completa de la empresa.",
        related_duties_en: "Digital marketing, design (Web & UI/UX Design) and front-end development.",
        related_duties_ua: "Цифровий маркетинг, дизайн (веб і UI/UX дизайн) і фронтенд розробка.",
        related_duties_es: "Marketing digital, diseño (Web & UI/UX diseño) y desarrollo front-end.",
        tasks_en: "Creation of a company logo. Website layout creation. Work with CMS Joomla. Setting up and launching advertising campaigns in Google, YouTube, Facebook, Instagram. Creation of informative video clips for YouTube. Connecting the site with the Google Merchant Center. Registration in Google Map. Together with the engineers of the manufacturer, the creation of an exclusive coffee roaster. Programming the coffee roaster. Creation of coffee blends. Setting up employees' computers. Setting up a local network.",
        tasks_ua: "Створення логотипу компанії. Створення макету сайту. Робота з CMS Joomla. Налаштування та запуск рекламних кампаній в Google, YouTube, Facebook, Instagram. Створення інформаційних відеокліпів для YouTube. Підключення сайту до Google Merchant Center. Реєстрація в Google Map. Спільно з інженерами заводу-виробника, створення ексклюзивного роустера для обсмажування кави. Програмування роустера для обсмажування кави. Створення купажів кави. Налаштування комп'ютерів співробітників. Налаштування локальної мережі.",
        tasks_es: "Creación de un logo de empresa. Creación de diseño de sitio web. Trabajar con CMS Joomla. Configuración y lanzamiento de campañas publicitarias en Google, YouTube, Facebook, Instagram. Creación de videoclips informativos para YouTube. Conexión del sitio con Google Merchant Center. Registro en Google Map. Junto a los ingenieros del fabricante, la creación de un tostador de café exclusivo. Programación del tostador de café. Creación de mezclas de café. Configuración de las computadoras de los empleados. Configuración de una red local.",
        skills: "Project Management, Adobe Audition, Contract Negotiation, Accounting, CSS3, PHP, Git, Vue.js, Front-end Development, Object-oriented programming, Business Strategy, Adobe Premiere, Marketing, Strategic Planning, Negotiation, Business Planning, Search Engine Marketing (SEM), Strategic Thinking, Digital marketing, Adobe Acrobat, IT operations, Microsoft Office, HTML, Business Analysis, Business Development, Adobe Photoshop, Marketing Strategy, JavaScript, Windows Server, Google Analytics, Google Adwords, Web Designer, Eclipse, Critical Thinking, Personal Development, Adobe Illustrator, Adobe Lightroom, Sales Management, Small Business, Online Advertising, Search Engine Optimization, UI/UX Designer, Entrepreneurship, Management, Social Media Marketing."
      },
      {
        id: 3,
        data_start: "15.01.2001",
        data_finish_en: "13.01.2023",
        data_finish_ua: "13.01.2023",
        data_finish_es: "13.01.2023",
        company_en: "LIS Ltd",
        company_ua: "ТОВ \"ЛИС\"",
        company_es: "\"LYS\" SRL",
        job_en: "CEO",
        job_ua: "Генеральний директор",
        job_es: "CEO",
        main_duties_en: "Full administrative management of the enterprise.",
        main_duties_ua: "Повне адміністративне управління підприємством.",
        main_duties_es: "Gestión administrativa completa de la empresa.",
        related_duties_en: "Digital marketing, web design and front-end development.",
        related_duties_ua: "Цифровий маркетинг, веб-дизайн і фронтенд розробка.",
        related_duties_es: "Marketing digital, diseño web y desarrollo front-end.",
        tasks_en: "Creation of a company logo. Website layout creation. Work with CMS Wordpress. Translation of vending machine messages from German into Ukrainian (translation was done using Assembler). Programming vending machines, coin acceptors and banknote acceptors. Setting up and launching advertising campaigns in Google, YouTube. Registration in Google Map. Development of accounting for vending machines. Setting up employees' computers. Setting up a local network.",
        tasks_ua: "Створення логотипу компанії. Створення макету сайту. Робота з CMS Wordpress. Переклад повідомлень торговельного автомата з німецької мови на українську мову (переклад робився за допомогою Assembler). Програмування торгових автоматів, монетоприймачів та банкнотоприймачів. Налаштування та запуск рекламних кампаній в Google, YouTube. Реєстрація в Google Map. Розробка бухгалтерського обліку для торгових автоматів. Налаштування комп'ютерів співробітників. Налаштування локальної мережі.",
        tasks_es: "Creación de un logo de empresa. Creación de diseño de sitio web. Trabajar con CMS Wordpress. Traducción de mensajes de máquinas expendedoras del alemán al ucraniano (traducción realizada con Assembler). Programación de máquinas expendedoras, aceptadores de monedas y aceptadores de billetes. Configuración y lanzamiento de campañas publicitarias en Google, YouTube. Registro en Google Map. Desarrollo de contabilidad para máquinas expendedoras. Configuración de las computadoras de los empleados. Configuración de una red local.",
        skills: "Project Management, Contract Negotiation, Accounting, CSS3, PHP, Git, Front-end Development, Object-oriented programming, Business Strategy, Marketing, Strategic Planning, Negotiation, Business Planning, Search Engine Marketing (SEM), Strategic Thinking, Digital marketing, Adobe Acrobat, IT operations, Microsoft Office, HTML, Business Analysis, Business Development, Adobe Photoshop, Marketing Strategy, Windows Server, Google Analytics, Google Adwords, Web Designer, Critical Thinking, Personal Development, WordPress, Adobe Illustrator, Adobe Lightroom, Sales Management, Small Business, Online Advertising, Search Engine Optimization, UI/UX Designer, Entrepreneurship, Management, Social Media Marketing."
      },
      {
        id: 4,
        data_start: "12.09.2000",
        data_finish_en: "30.06.2004",
        data_finish_ua: "30.06.2004",
        data_finish_es: "30.06.2004",
        company_en: "PAWOL Ltd",
        company_ua: "ТОВ \"Павол\"",
        company_es: "\"PAWOL\" SRL",
        job_en: "Deputy CEO",
        job_ua: "Заступник генерального директора",
        job_es: "Deputy CEO",
        main_duties_en: "Foreign economic activity of the company, search and development of new innovative activities.",
        main_duties_ua: "Зовнішньоекономічна діяльність компанії, пошук та розвиток нових інноваційних видів діяльності.",
        main_duties_es: "Actividad económica exterior de la empresa, búsqueda y desarrollo de nuevas actividades innovadoras.",
        related_duties_en: "Marketing, web design and information analytics.",
        related_duties_ua: "Маркетинг, веб-дизайн та аналітика інформації.",
        related_duties_es: "Marketing, diseño web y analítica de información.",
        tasks_en: "Creation of a company logo. Creating a landing page with a minimal administrative part. Setting up employees' computers. Setting up a local network. Conducting business negotiations and conclusion of foreign economic contracts (Germany, Italy, Czech Republic, Spain, Turkey, China). Representation at exhibitions, conferences and before state authorities.",
        tasks_ua: "Створення логотипу компанії. Створення лендінгу із мінімальною адміністративною частиною. Налаштування комп'ютерів співробітників. Налаштування локальної мережі. Ведення ділових переговорів та укладання зовнішньоекономічних контрактів (Німеччина, Італія, Чехія, Іспанія, Туреччина, Китай). Представницькі функції на виставках, конференціях та перед державними органами.",
        tasks_es: "Creación de un logo de empresa. Creación de una landing page con una mínima parte administrativa. Configuración de las computadoras de los empleados. Configuración de una red local. Realización de negociaciones comerciales y сonclusión de contratos económicos extranjeros (Alemania, Italia, República Checa, España, Turquía, China). Representación en exposiciones, congresos y ante autoridades estatales.",
        skills: "Project Management, Contract Negotiation, Accounting, Business Strategy, Marketing, Strategic Planning, Negotiation, Business Planning, Search Engine Marketing (SEM), Strategic Thinking, Digital marketing, Adobe Acrobat, IT operations, Microsoft Office, Business Analysis, Business Development, Adobe Photoshop, Marketing Strategy, Windows Server, Google Analytics, Google Adwords, Web Designer, Critical Thinking, Personal Development, WordPress, Adobe Illustrator, Sales Management, Small Business, Online Advertising, Search Engine Optimization, UI/UX Designer, Management, Social Media Marketing."
      },
    ]
};
const getters = {};
const mutations = {};
const actions = {};
export default {
  state,
  getters,
  mutations,
  actions
};