import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "anagram" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = {
  key: 0,
  class: "result"
}
const _hoisted_6 = { class: "word1" }
const _hoisted_7 = { class: "word2" }
const _hoisted_8 = { style: {"color":"lightseagreen"} }
const _hoisted_9 = {
  key: 1,
  class: "result"
}
const _hoisted_10 = { class: "word1" }
const _hoisted_11 = { class: "word2" }
const _hoisted_12 = { style: {"color":"red"} }
const _hoisted_13 = { class: "inner" }
const _hoisted_14 = { class: "certificate" }
const _hoisted_15 = {
  class: "block",
  href: "/assets/tasks/Anagram-JS.pdf",
  title: "Download solution in pdf format...",
  target: "_blank"
}
const _hoisted_16 = { class: "certificate" }
const _hoisted_17 = {
  class: "block",
  href: "/assets/tasks/Anagram-Vue.pdf",
  title: "Download solution in pdf format...",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createVNode(_component_router_link, {
        class: "back",
        to: "/extra/tasks",
        title: "Back to Tasks page"
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createElementVNode("i", { class: "fa fa-arrow-circle-left" }, null, -1)
        ])),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('extra.h1m.tasks.anagram')), 1)
    ]),
    _cache[9] || (_cache[9] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("p", null, [
      _createTextVNode(_toDisplayString(_ctx.$t('extra.tasks.anagram.an-des01')), 1),
      _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
      _createTextVNode(_toDisplayString(_ctx.$t('extra.tasks.anagram.an-des02')), 1),
      _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
      _createTextVNode(_toDisplayString(_ctx.$t('extra.tasks.anagram.an-des03')), 1)
    ]),
    _cache[10] || (_cache[10] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('extra.tasks.anagram.an-des04')), 1),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.word1) = $event)),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.checkAnagram && _ctx.checkAnagram(...args))),
      placeholder: _ctx.$t('extra.tasks.anagram.an-des05')
    }, null, 40, _hoisted_3), [
      [_vModelText, _ctx.word1]
    ]),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.word2) = $event)),
      onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.checkAnagram && _ctx.checkAnagram(...args))),
      placeholder: _ctx.$t('extra.tasks.anagram.an-des06')
    }, null, 40, _hoisted_4), [
      [_vModelText, _ctx.word2]
    ]),
    (_ctx.isAnagram)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_5, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('extra.tasks.anagram.an-des07')), 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.word1), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('extra.tasks.anagram.an-des09')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('extra.tasks.anagram.an-des08')), 1),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.word2), 1),
          _cache[7] || (_cache[7] = _createTextVNode(" - ")),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('extra.tasks.anagram.an-des10')), 1)
        ]))
      : (_openBlock(), _createElementBlock("h3", _hoisted_9, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('extra.tasks.anagram.an-des07')), 1),
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.word1), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('extra.tasks.anagram.an-des09')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('extra.tasks.anagram.an-des08')), 1),
          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.word2), 1),
          _cache[8] || (_cache[8] = _createTextVNode(" - ")),
          _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('extra.tasks.anagram.an-des11')), 1)
        ])),
    _cache[11] || (_cache[11] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("a", _hoisted_15, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('extra.tasks.link-js')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("a", _hoisted_17, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('extra.tasks.link-vue')), 1)
        ])
      ])
    ])
  ]))
}