import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "picsum" }
const _hoisted_2 = {
  key: 0,
  style: {"margin":"0"}
}
const _hoisted_3 = {
  key: 1,
  style: {"margin":"0"}
}
const _hoisted_4 = {
  key: 2,
  style: {"color":"darkred","margin":"0.5rem"}
}
const _hoisted_5 = { class: "container" }
const _hoisted_6 = { class: "input" }
const _hoisted_7 = { class: "psize" }
const _hoisted_8 = { for: "size" }
const _hoisted_9 = { class: "qty" }
const _hoisted_10 = { for: "count" }
const _hoisted_11 = { class: "btns" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = {
  key: 1,
  class: "image-container"
}
const _hoisted_14 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createVNode(_component_router_link, {
        class: "back",
        to: "/extra/ai",
        title: "Back to AI page"
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [
          _createElementVNode("i", { class: "fa fa-arrow-circle-left" }, null, -1)
        ])),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('extra.ai.picsum.title')), 1)
    ]),
    _cache[10] || (_cache[10] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("h2", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMore = !_ctx.showMore)),
      class: "more"
    }, [
      _createTextVNode(_toDisplayString(_ctx.$t ('extra.ai.picsum.more01')), 1),
      _cache[8] || (_cache[8] = _createElementVNode("i", {
        style: {"color":"rebeccapurple","margin-left":"0.5rem"},
        class: "fas fa-hand-pointer"
      }, null, -1))
    ]),
    (_ctx.showMore)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t ('extra.ai.picsum.more02')), 1))
      : _createCommentVNode("", true),
    (_ctx.showMore)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t ('extra.ai.picsum.more03')), 1))
      : _createCommentVNode("", true),
    (_ctx.showMore)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_4, _toDisplayString(_ctx.$t ('extra.ai.picsum.more04')), 1))
      : _createCommentVNode("", true),
    _cache[11] || (_cache[11] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('extra.ai.picsum.size')), 1),
          _withDirectives(_createElementVNode("select", {
            id: "size",
            class: "select",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedSize) = $event))
          }, _cache[9] || (_cache[9] = [
            _createStaticVNode("<option value=\"128\" data-v-031e7700>128x128</option><option value=\"256\" data-v-031e7700>256x256</option><option value=\"512\" data-v-031e7700>512x512</option><option value=\"1024\" data-v-031e7700>1024x1024</option><option value=\"2048\" data-v-031e7700>2048x2048</option><option value=\"4096\" data-v-031e7700>4096x4096</option>", 6)
          ]), 512), [
            [_vModelSelect, _ctx.selectedSize]
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('extra.ai.picsum.count')), 1),
          _withDirectives(_createElementVNode("input", {
            id: "count",
            type: "number",
            min: "1",
            max: "30",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.imageCount) = $event)),
            onKeyup: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.generateImages && _ctx.generateImages(...args)), ["enter"]))
          }, null, 544), [
            [
              _vModelText,
              _ctx.imageCount,
              void 0,
              { number: true }
            ]
          ]),
          _createElementVNode("button", {
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.setMaxImageCount && _ctx.setMaxImageCount(...args)))
          }, _toDisplayString(_ctx.$t('extra.ai.picsum.max')), 1)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("button", {
            class: "gen",
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.generateImages && _ctx.generateImages(...args)))
          }, _toDisplayString(_ctx.$t('extra.ai.picsum.create')), 1),
          _createElementVNode("button", {
            class: "clear",
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.clearImages && _ctx.clearImages(...args)))
          }, _toDisplayString(_ctx.$t('extra.ai.picsum.clear')), 1)
        ])
      ]),
      (_ctx.images.length > 0)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_12, _toDisplayString(_ctx.$t('extra.ai.picsum.gen')), 1))
        : _createCommentVNode("", true),
      (_ctx.images.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image) => {
              return (_openBlock(), _createElementBlock("div", {
                key: image.id,
                class: "image-wrapper"
              }, [
                _createElementVNode("img", {
                  src: image.url,
                  alt: image.alt,
                  class: "image"
                }, null, 8, _hoisted_14)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}