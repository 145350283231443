import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "table"
}
const _hoisted_2 = {
  class: "title",
  colspan: "3"
}
const _hoisted_3 = { class: "name" }
const _hoisted_4 = { class: "price" }
const _hoisted_5 = { class: "name" }
const _hoisted_6 = { class: "price" }
const _hoisted_7 = { class: "name" }
const _hoisted_8 = { class: "price" }
const _hoisted_9 = { class: "name" }
const _hoisted_10 = { class: "price" }
const _hoisted_11 = { class: "name" }
const _hoisted_12 = { class: "price" }
const _hoisted_13 = { class: "name" }
const _hoisted_14 = { class: "price" }
const _hoisted_15 = { class: "name" }
const _hoisted_16 = { class: "price" }
const _hoisted_17 = { class: "name" }
const _hoisted_18 = { class: "price" }
const _hoisted_19 = { class: "name" }
const _hoisted_20 = { class: "price" }
const _hoisted_21 = { class: "name" }
const _hoisted_22 = { class: "price" }
const _hoisted_23 = { class: "name" }
const _hoisted_24 = { class: "price" }
const _hoisted_25 = { class: "name" }
const _hoisted_26 = { class: "price" }
const _hoisted_27 = {
  key: 1,
  class: "inner"
}
const _hoisted_28 = { class: "city" }
const _hoisted_29 = {
  key: 0,
  class: "indicators"
}
const _hoisted_30 = { class: "country" }
const _hoisted_31 = {
  key: 2,
  class: "error"
}
const _hoisted_32 = {
  key: 3,
  class: "error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.tableView && _ctx.weather && _ctx.weather.sys)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("table", null, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_2, _toDisplayString(_ctx.$t('extra.weather.h1')) + " " + _toDisplayString(_ctx.cityName), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[0] || (_cache[0] = _createElementVNode("th", null, "№", -1)),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('extra.weather.indicator')), 1),
                _createElementVNode("th", null, _toDisplayString(_ctx.$t('extra.weather.value')), 1)
              ])
            ]),
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", null, [
                _cache[1] || (_cache[1] = _createElementVNode("td", { class: "nomer" }, "1", -1)),
                _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.$t('extra.weather.country')), 1),
                _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.countryName), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[2] || (_cache[2] = _createElementVNode("td", { class: "nomer" }, "2", -1)),
                _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.$t('extra.weather.sunrise')), 1),
                _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.formatTime(_ctx.weather.sys.sunrise)), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[3] || (_cache[3] = _createElementVNode("td", { class: "nomer" }, "3", -1)),
                _createElementVNode("td", _hoisted_7, _toDisplayString(_ctx.$t('extra.weather.sunset')), 1),
                _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.formatTime(_ctx.weather.sys.sunset)), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[4] || (_cache[4] = _createElementVNode("td", { class: "nomer" }, "4", -1)),
                _createElementVNode("td", _hoisted_9, _toDisplayString(_ctx.$t('extra.weather.description')), 1),
                _createElementVNode("td", _hoisted_10, _toDisplayString(_ctx.weather.weather[0].description), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[5] || (_cache[5] = _createElementVNode("td", { class: "nomer" }, "5", -1)),
                _createElementVNode("td", _hoisted_11, _toDisplayString(_ctx.$t('extra.weather.temp')), 1),
                _createElementVNode("td", _hoisted_12, _toDisplayString(_ctx.weather.main.temp) + "°C", 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[6] || (_cache[6] = _createElementVNode("td", { class: "nomer" }, "6", -1)),
                _createElementVNode("td", _hoisted_13, _toDisplayString(_ctx.$t('extra.weather.feels')), 1),
                _createElementVNode("td", _hoisted_14, _toDisplayString(_ctx.weather.main.feels_like) + "°", 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[7] || (_cache[7] = _createElementVNode("td", { class: "nomer" }, "7", -1)),
                _createElementVNode("td", _hoisted_15, _toDisplayString(_ctx.$t('extra.weather.temp-min')), 1),
                _createElementVNode("td", _hoisted_16, _toDisplayString(_ctx.weather.main.temp_min) + "°C", 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[8] || (_cache[8] = _createElementVNode("td", { class: "nomer" }, "8", -1)),
                _createElementVNode("td", _hoisted_17, _toDisplayString(_ctx.$t('extra.weather.temp-max')), 1),
                _createElementVNode("td", _hoisted_18, _toDisplayString(_ctx.weather.main.temp_max) + "°C", 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[9] || (_cache[9] = _createElementVNode("td", { class: "nomer" }, "9", -1)),
                _createElementVNode("td", _hoisted_19, _toDisplayString(_ctx.$t('extra.weather.speed')), 1),
                _createElementVNode("td", _hoisted_20, _toDisplayString(_ctx.weather.wind.speed) + " m/s", 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[10] || (_cache[10] = _createElementVNode("td", { class: "nomer" }, "10", -1)),
                _createElementVNode("td", _hoisted_21, _toDisplayString(_ctx.$t('extra.weather.direction')), 1),
                _createElementVNode("td", _hoisted_22, _toDisplayString(_ctx.weather.wind.deg) + "°", 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[11] || (_cache[11] = _createElementVNode("td", { class: "nomer" }, "11", -1)),
                _createElementVNode("td", _hoisted_23, _toDisplayString(_ctx.$t('extra.weather.humidity')), 1),
                _createElementVNode("td", _hoisted_24, _toDisplayString(_ctx.weather.main.humidity) + "%", 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[12] || (_cache[12] = _createElementVNode("td", { class: "nomer" }, "12", -1)),
                _createElementVNode("td", _hoisted_25, _toDisplayString(_ctx.$t('extra.weather.pressure')), 1),
                _createElementVNode("td", _hoisted_26, _toDisplayString(_ctx.weather.main.pressure) + " hPa", 1)
              ])
            ])
          ])
        ]))
      : (!_ctx.tableView && _ctx.weather)
        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("h1", null, _toDisplayString(_ctx.$t('extra.weather.h1')) + _toDisplayString(this.cityName), 1),
              (_ctx.weather)
                ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                    _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.$t('extra.weather.country')) + ": " + _toDisplayString(_ctx.countryName), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('extra.weather.sunrise')) + ": " + _toDisplayString(_ctx.formatTime(_ctx.weather.sys.sunrise)), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('extra.weather.sunset')) + ": " + _toDisplayString(_ctx.formatTime(_ctx.weather.sys.sunset)), 1),
                    _cache[13] || (_cache[13] = _createElementVNode("line", null, null, -1)),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('extra.weather.description')) + ": " + _toDisplayString(_ctx.weather.weather[0].description), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('extra.weather.temp')) + ": " + _toDisplayString(_ctx.weather.main.temp) + "°C", 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('extra.weather.feels')) + ": " + _toDisplayString(_ctx.weather.main.feels_like) + "°C", 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('extra.weather.temp-min')) + ": " + _toDisplayString(_ctx.weather.main.temp_min) + "°C", 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('extra.weather.temp-max')) + ": " + _toDisplayString(_ctx.weather.main.temp_max) + "°C", 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('extra.weather.speed')) + ": " + _toDisplayString(_ctx.weather.wind.speed) + " m/s", 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('extra.weather.direction')) + ": " + _toDisplayString(_ctx.weather.wind.deg) + "°", 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('extra.weather.humidity')) + ": " + _toDisplayString(_ctx.weather.main.humidity) + "%", 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('extra.weather.pressure')) + ": " + _toDisplayString(_ctx.weather.main.pressure) + " hPa", 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_31, _toDisplayString(_ctx.$t('extra.weather.loading')), 1))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_32, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ], 64))
}