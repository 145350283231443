import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "certificates" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "prj back" }
const _hoisted_4 = { class: "prj front" }
const _hoisted_5 = { class: "prj database" }
const _hoisted_6 = { class: "prj designer" }
const _hoisted_7 = { class: "prj pm" }
const _hoisted_8 = { class: "prj other" }
const _hoisted_9 = { class: "prj eng" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TotalSert = _resolveComponent("TotalSert")!
  const _component_Search = _resolveComponent("Search")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createTextVNode(_toDisplayString(_ctx.$t('cert.title-main')) + " ", 1),
      _createElementVNode("i", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeView && _ctx.changeView(...args))),
        class: "dandruff"
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(['fas', _ctx.searchView ? 'fa-binoculars' : 'fa-search'])
        }, null, 2)
      ])
    ]),
    _cache[10] || (_cache[10] = _createElementVNode("line", null, null, -1)),
    _createVNode(_component_TotalSert),
    (_ctx.searchView)
      ? (_openBlock(), _createBlock(_component_Search, { key: 0 }))
      : _createCommentVNode("", true),
    _cache[11] || (_cache[11] = _createElementVNode("line", null, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          class: "block",
          to: "/certificates/backend"
        }, {
          default: _withCtx(() => [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('cert.back.backend')), 1),
            _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("i", null, [
              _createTextVNode(_toDisplayString(_ctx.$t('cert.back.java')), 1),
              _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('cert.back.php')), 1),
              _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('cert.back.python')), 1),
              _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('cert.back.ruby')), 1)
            ])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_link, {
          class: "block",
          to: "/certificates/frontend"
        }, {
          default: _withCtx(() => [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('cert.front.frontend')), 1),
            _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("i", null, [
              _createTextVNode(_toDisplayString(_ctx.$t('cert.front.html')), 1),
              _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('cert.front.javascript')), 1),
              _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('cert.front.angular')), 1),
              _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('cert.front.react')), 1),
              _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('cert.front.frontend2')), 1)
            ])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_link, {
          class: "block",
          to: "/certificates/database"
        }, {
          default: _withCtx(() => [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('cert.database')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_router_link, {
          class: "block",
          to: "/certificates/designer"
        }, {
          default: _withCtx(() => [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('cert.designer')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_router_link, {
          class: "block",
          to: "/certificates/pm"
        }, {
          default: _withCtx(() => [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('cert.project')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_router_link, {
          class: "block",
          to: "/certificates/other"
        }, {
          default: _withCtx(() => [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('cert.other')), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_router_link, {
          class: "block",
          to: "/certificates/english"
        }, {
          default: _withCtx(() => [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('cert.english')), 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}